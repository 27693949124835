import { connect } from 'react-redux'
import Group from '../components/groups/group/Group'
import { withRouter } from "react-router";
import { notificationShow } from '../common/actions/notification';
import { permissionsLogic } from '../common/logic/permissionsLogic';
import { loadAllReferenceData } from '../common/actions/referenceData';
import * as checklistTemplatesActions from '../common/actions/checklistTemplates';
import * as checklistTemplateActions from '../common/actions/checklistTemplate';
import * as groupActions from '../common/actions/groups';

import {
    incidentActionTemplateSet,
    incidentActionTemplateSetObsolete,
    incidentActionTemplateUpsertRequest,
    incidentActionTemplateRequest
} from '../common/actions/incidentActionTemplates';

const mapStateToProps = (state, props) => {
    return {
        isAdmin: true,
        groupId: props.match.params.groupId,
        group: state.groups.group.group,
        timeZones: state.groups.group.timeZones,
        tabIndex: state.groups.group.tabIndex,
        isLoading: state.groups.group.isLoading,
        isSaving: state.groups.group.isSaving || state.groups.group.isSavingUserRequest,
        venueAutoApproval: state.groups.venues.autoApproval,
        venues: state.groups.venues.venues,
        isLoadingVenues: state.groups.venues.isLoading,
        isSavingVenues: state.groups.venues.isSaving,
        userAutoApproval: state.groups.users.autoApproval,
        isTemporary: state.groups.users.isTemporary,
        expiryDate: state.groups.users.expiryDate,
        userRoleTypeId: state.groups.users.userRoleTypeId,
        userRoleTypes: state.groups.users.userRoleTypes,
        userRoleStatuses: state.groups.group.userRoleStatuses,
        users: state.groups.users.users,
        isLoadingUsers: state.groups.users.isLoading,
        isSavingUsers: state.groups.users.isSaving,
        incidentActionTemplate: state.incidentActionTemplates.incidentActionTemplate.incidentActionTemplate,
        isLoadingItem: state.incidentActionTemplates.incidentActionTemplate.isLoading,
        isSavingIncidentActionTemplate: state.incidentActionTemplates.incidentActionTemplate.isSaving, 
        isSavingChecklistTemplate: state.checklistTemplate.checklistTemplateSaving,
        isCloningChecklistTemplate: state.groups.checklistTemplates.isCloning,
        checklistTemplateId: state.groups.checklistTemplates.checklistTemplateId,
        isLoadingChecklistTemplate: state.checklistTemplate.checklistTemplateLoading,
        checklistTemplate: state.checklistTemplate.checklistTemplate,     
        canEditFeatures: permissionsLogic.hasPermissionForState(state, 'MaintainGroupFeatures'),
        canAddVenues: permissionsLogic.hasGroupPermissionForState(state, props.match.params.groupId, 'MaintainGroupVenues'),
        canAddUsers: permissionsLogic.hasGroupPermissionForState(state, props.match.params.groupId, 'MaintainGroupManagers'),
        canAddChecklistTemplates: permissionsLogic.hasGroupPermissionForState(state, props.match.params.groupId, 'MaintainGroupChecklistTemplates'),
        canAddIncidentActions: permissionsLogic.hasGroupPermissionForState(state, props.match.params.groupId, 'MaintainGroupIncidentActions'),
        canEdit: permissionsLogic.hasGroupPermissionForState(state, props.match.params.groupId, 'MaintainGroup'),
        canViewDashboard: permissionsLogic.hasGroupPermissionForState(state, props.match.params.groupId, 'ViewGroupDashboard'),
        canAddIndustries: permissionsLogic.hasGroupPermissionForState(state, props.match.params.groupId, 'MaintainGroupIndustries'),
        canAddWatchlists: permissionsLogic.hasGroupPermissionForState(state, props.match.params.groupId, 'MaintainGroupWatchlists'),
        canAddEvents: permissionsLogic.hasGroupPermissionForState(state, props.match.params.groupId, 'MaintainGroupEvents'),
        canAddDirectory: permissionsLogic.hasGroupPermissionForState(state, props.match.params.groupId, 'MaintainGroup')
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: (groupId) => {
            dispatch(groupActions.groupRequest(groupId))
        },
        onCreate: () => {
            dispatch(groupActions.groupCreateRequest())
        },
        onUpdate: (group) => {
            dispatch(groupActions.groupSet(group));
        },
        onSave: () => {
            dispatch(groupActions.groupUpsertRequest());
        },
        onReset: () => {
            dispatch(groupActions.groupsReset());
        },
        onLoadVenues: (groupId) => {
            dispatch(groupActions.groupVenuesAvailableRequest(groupId));
        },
        onSetVenues: (venues) => {
            dispatch(groupActions.groupSetVenues(venues));
        },
        onSetVenueAutoApproval: (autoApproval) => {
            dispatch(groupActions.groupSetVenueAutoApprove(autoApproval));
        },
        onSaveVenues: (groupId, venues, autoApproval) => {
            dispatch(groupActions.groupVenuesInsertRequest(groupId, venues, autoApproval));
        },
        onLoadUsers: (groupId) => {
            dispatch(groupActions.groupUsersAvailableRequest(groupId));
        },
        onSetUsers: (users) => {
            dispatch(groupActions.groupSetUsers(users));
        },
        onSetUserAutoApproval: (autoApproval) => {
            dispatch(groupActions.groupSetUserAutoApprove(autoApproval));
        },
        onSetUserRoleType: (userRoleTypeId) => {
            dispatch(groupActions.groupSetUserRoleType(userRoleTypeId));
        },
        onSetTemporary: (isTemporary, expiryDate) => {
            dispatch(groupActions.groupSetTemporary(isTemporary, expiryDate));
        },
        onSaveUsers: (groupId, users, autoApproval, userRoleTypeId, isTemporary, expiryDate) => {
            dispatch(groupActions.groupUsersInsertRequest(groupId, users, autoApproval, userRoleTypeId, isTemporary, expiryDate));
        },
        onSetTab: (tabIndex) => {
            dispatch(groupActions.groupSetTab(tabIndex));
        },
        onLoadItem: (checklistTemplateId) => {
            dispatch(incidentActionTemplateRequest(checklistTemplateId))
        },
        onSetIncidentActionTemplate: (incidentActionTemplate) => {
            dispatch(incidentActionTemplateSetObsolete(incidentActionTemplate))
        },
        onUpdateIncidentActionTemplate: () => {
            dispatch(incidentActionTemplateUpsertRequest())
        },
        onUpdateUser: (groupId, groupUser) => {
            dispatch(groupActions.groupUserUpdateRequest(groupId, groupUser))
        },
        onUpdateVenue: (groupId, groupVenue) => {
            dispatch(groupActions.groupVenueUpdateRequest(groupId, groupVenue))
        },
        onRemoveGroupRequest: (notificationId, groupId) => {
            dispatch(groupActions.groupAccessRequestReject(notificationId, groupId))
        },
        onArchiveTemplate: (checklistTemplateId, isAdmin, restore) => {
            dispatch(checklistTemplatesActions.checklistTemplateArchiveRequest(checklistTemplateId, isAdmin, restore))
        },
        onCloneChecklistTemplate:(groupId, checklistTemplateId) => {
            dispatch(groupActions.groupChecklistTemplateCloneRequest(groupId, checklistTemplateId))
        },
        onLoadChecklistTemplate: (checklistTemplateId) => {
            dispatch(checklistTemplateActions.loadChecklistTemplateRequest(checklistTemplateId))
        }
    }
}

const AdminGroupContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Group))

export default AdminGroupContainer