import { connect } from 'react-redux'
import Roller from '../components/integrations/roller/Roller'
import * as externalApiConnectionActions from '../common/actions/externalApiConnection'
import { externalApiConnectionLogic } from "../common/logic/externalApiConnectionLogic";
import * as AusComplyDtos from "../common/dto/AusComply.dtos";

const mapStateToProps = (state, props) => {
    return {
        venueId: state.user.details.userSession.user.venueId,
        isReady: !state.externalApiConnections.isLoading && !state.externalApiConnections.isSaving,
        externalApiConnection: externalApiConnectionLogic.findExternalApiConnection(state, AusComplyDtos.ngtEntityType.Roller, state.user.details.userSession.user.venueId, null),
        isLoading: state.externalApiConnections.rollerConfiguration.isLoading,
        isSaving: state.externalApiConnections.rollerConfiguration.isSaving,
        rollerConfiguration: state.externalApiConnections.rollerConfiguration.rollerConfiguration
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onLoad: () => {
            dispatch(externalApiConnectionActions.rollerConfigurationRequest())
        },
        onSave: (rollerConfiguration) => {
            dispatch(externalApiConnectionActions.rollerConfigurationUpdateRequest(rollerConfiguration))
        },
        onSet: (rollerConfiguration) => {
            dispatch(externalApiConnectionActions.rollerConfigurationSet(rollerConfiguration))
        },
    }
}

const RollerIntegrationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Roller)

export default RollerIntegrationContainer