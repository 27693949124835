import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { KeyName, PrintSend, RadioOption, MulitSelectOption } from '../../common/dto/common';
import Loading from '../common/Loading';
import RadioControl from '../controls/RadioControl';
import TransparentButton from '../controls/TransparentButton';
import SelectControlGroup from '../controls/SelectControlGroup';
import TextControlGroup from '../controls/TextControlGroup';
import ControlGroup from '../controls/ControlGroup';
import CheckboxControlGroup from '../controls/CheckboxControlGroup';
import Information from '../common/Information';
import MultiSelectControlGroup from '../controls/MutiSelectControlGroup';
import CardContainer from '../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';


export interface IPrintAndSendProps {
    theme: any;
    classes: any;
    printOptions?: AusComplyDtos.PrintOptions;
    isLoading: boolean;
    onPrint?: Function;
    onEmail?: Function;
    onExport?: Function;
    onCancel?: Function;
    pdfOptions?: KeyName[];
    emailOptions?: KeyName[];
    exportOptions?: KeyName[];
}

export interface IPrintAndSendState {
    canPrint: boolean;
    canEmail: boolean;
    canExport: boolean;
    option: string;
    extraRecipients: string;
    lockCC: boolean;
    options: KeyName[];
    recipients: MulitSelectOption[];
    pdfOption: string;
    emailOption: string;
    exportOption: string;
    ccVenueIsSelected: boolean;
    ccSecurityFirmIsSelected: boolean;
    ccUserIsSelected: boolean;
}

class PrintAndSend extends React.PureComponent<IPrintAndSendProps, IPrintAndSendState> {

    constructor(props: IPrintAndSendProps) {
        super(props);

        this.state = {
            option: "2",
            canPrint: true,
            canEmail: true,
            canExport: true,
            lockCC: false,
            options: [],
            extraRecipients: "",
            recipients: [],
            pdfOption: "",
            emailOption: "",
            exportOption: "",
            ccVenueIsSelected: false,
            ccSecurityFirmIsSelected: false,
            ccUserIsSelected: false
        };
        this.onOptionChanged = this.onOptionChanged.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onRecipientsChanged = this.onRecipientsChanged.bind(this);
        this.onExtraRecipientsChanged = this.onExtraRecipientsChanged.bind(this);
        this.onCcVenueChanged = this.onCcVenueChanged.bind(this);
        this.onCcSecurityFirmChanged = this.onCcSecurityFirmChanged.bind(this);
        this.onCcUserFirmChanged = this.onCcUserFirmChanged.bind(this);
        this.onPdfOptionChanged = this.onPdfOptionChanged.bind(this);
        this.onEmailOptionChanged = this.onEmailOptionChanged.bind(this);
        this.onExportOptionChanged = this.onExportOptionChanged.bind(this);
        this.setExportOption = this.setExportOption.bind(this);
    }

    componentDidMount() {

        if (this.props.printOptions) {
            this.setPrintOptions(this.props.printOptions);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.printOptions && this.props.printOptions !== prevProps.printOptions) {
            this.setPrintOptions(this.props.printOptions);
        }
    }

    setPrintOptions(printOptions: AusComplyDtos.PrintOptions) {
        let defaultOption = "1";
        if (this.props.onPrint != null) {
            defaultOption = "1"
        } else if (this.props.onEmail != null) {
            defaultOption = "2"
        } else if (this.props.onExport != null) {
            defaultOption = "3"
        }
        let pdfOption = "";
        if (this.props.pdfOptions && this.props.pdfOptions.length > 0) {
            pdfOption = this.props.pdfOptions[0].key;
        }
        let emailOption = "";
        if (this.props.emailOptions && this.props.emailOptions.length > 0) {
            emailOption = this.props.emailOptions[0].key;
        }
        let exportOption = "";
        if (this.props.exportOptions && this.props.exportOptions.length > 0) {
            exportOption = this.props.exportOptions[0].key;
        }

        let recipients: MulitSelectOption[] = [];
        printOptions.recipients.forEach((item, index) => {
            let recipient = new MulitSelectOption();
            recipient.id = index;
            recipient.code = item.key;
            recipient.name = item.value;
            recipient.selected = item.isSelected;
            recipient.isCommon = false;
            if (recipients.filter(s => s.code === item.key && s.name === item.value).length == 0) {
                recipients.push(recipient);
            }
        });

        this.setState({
            option: defaultOption,
            canPrint: this.props.onPrint != null,
            canEmail: this.props.onEmail != null,
            canExport: this.props.onExport != null,
            options: [],
            recipients,
            pdfOption,
            emailOption,
            exportOption
        });
    }

    onOptionChanged(value) {
        this.setState({
            option: value.value
        });
    }

    onPdfOptionChanged(value) {
        this.setExportOption(value);
    }

    onEmailOptionChanged(value) {
        this.setExportOption(value);
    }

    onExportOptionChanged(value) {
        this.setExportOption(value);
    }

    setExportOption(option) {
        let pdfOption = this.state.pdfOption;
        let exportOption = this.state.exportOption;
        let emailOption = this.state.emailOption;
        if (this.props.pdfOptions && this.props.pdfOptions.length > 0) {
            if (this.props.pdfOptions.filter(item => item.key == option).length > 0) {
                pdfOption = option;
            }
        }
        if (this.props.emailOptions && this.props.emailOptions.length > 0) {
            if (this.props.emailOptions.filter(item => item.key == option).length > 0) {
                emailOption = option;
            }
        }
        if (this.props.exportOptions && this.props.exportOptions.length > 0) {
            if (this.props.exportOptions.filter(item => item.key == option).length > 0) {
                exportOption = option;
            }
        }
        this.setState({
            emailOption,
            pdfOption,
            exportOption
        });
    }

    onRecipientsChanged(value) {
        let recipients = [...this.state.recipients];
        recipients.forEach((item, index) => {
            item.selected = value.filter(s => s.code === item.code && s.name === item.name).length > 0;
        });

        this.setState({
            recipients
        });
    }

    onClick() {
        if (this.state.option == "1" && this.props.onPrint) {
            this.props.onPrint(this.state.pdfOption);
        }
        if (this.state.option == "2" && this.props.onEmail) {
            let printSend = new PrintSend();
            // build the lists
            let to: string[] = [];
            let cc: string[] = [];
            let bcc: string[] = [];
            // selected recipients
            this.state.recipients.filter(i => i.selected).forEach(item => {
                if (item.code) {
                    if (to.indexOf(item.code) == -1) {
                        to.push(item.code);
                    }
                }
            });
            // extra recipients
            if (this.state.extraRecipients) {
                let extra = this.state.extraRecipients.split(';');
                extra.forEach(item => {
                    if (to.indexOf(item) == -1) {
                        to.push(item);
                    }
                });
            }

            // venue licensee
            if (this.state.ccVenueIsSelected && this.props.printOptions) {
                if (cc.indexOf(this.props.printOptions.ccVenue.key) == -1) {
                    cc.push(this.props.printOptions.ccVenue.key)
                }
            }

            // security firm
            if (this.state.ccSecurityFirmIsSelected && this.props.printOptions) {
                if (cc.indexOf(this.props.printOptions.ccSecurityFirm.key) == -1) {
                    cc.push(this.props.printOptions.ccSecurityFirm.key)
                }
            }

            // myself
            if (this.state.ccUserIsSelected && this.props.printOptions) {
                if (cc.indexOf(this.props.printOptions.ccUser.key) == -1) {
                    cc.push(this.props.printOptions.ccUser.key)
                }
            }
            printSend.recipients = to;
            printSend.ccRecipients = cc;
            printSend.bccRecipients = bcc;

            this.props.onEmail(this.state.emailOption, printSend);
        }
        if (this.state.option == "3" && this.props.onExport) {
            this.props.onExport(this.state.exportOption);
        }
    }

    onExtraRecipientsChanged(value) {

        this.setState({
            extraRecipients: value
        });
        if (this.props.printOptions) {
            if (value && value.length > 0) {
                if (this.props.printOptions.requireVenueCCForExtraRecipients) {
                    this.setState({
                        lockCC: true,
                        ccVenueIsSelected: true
                    });
                }
                if (this.props.printOptions.requireSecurityFirmCCForExtraRecipients) {
                    this.setState({
                        lockCC: true,
                        ccSecurityFirmIsSelected: true
                    });
                }
                if (!this.props.printOptions.requireVenueCCForExtraRecipients && !this.props.printOptions.requireSecurityFirmCCForExtraRecipients) {
                    this.setState({ lockCC: false });
                }
            } else {
                this.setState({ lockCC: false });
            }
        }

    }

    onCcVenueChanged(value) {
        this.setState({ ccVenueIsSelected: value });
    }

    onCcSecurityFirmChanged(value) {
        this.setState({ ccSecurityFirmIsSelected: value });
    }

    onCcUserFirmChanged(value) {
        this.setState({ ccUserIsSelected: value });
    }

    render() {
        const { classes, theme } = this.props;

        if (this.props.isLoading) {
            return (
                <Loading />
            );
        }

        let options: RadioOption[] = [];
        if (this.props.onPrint != null) {
            options.push({ id: "1", name: "Download PDF" });
        }
        if (this.props.onEmail != null) {
            options.push({ id: "2", name: "Email as PDF" });
        }
        if (this.props.onExport != null) {
            options.push({ id: "3", name: "Export to Excel (CSV)" });
        }

        let actionText = "Download";
        let emailControls;
        let reportOptions;

        if (this.state.option == "1") {
            if (this.props.pdfOptions && this.props.pdfOptions.length > 1) {
                reportOptions = (
                    <SelectControlGroup
                        text="Output report detail"
                        onChange={this.onPdfOptionChanged}
                        defaultValue={this.state.pdfOption}>
                        {this.props.pdfOptions.map((item, index) => (
                            <option key={item.key} value={item.key}>{item.name}</option>
                        ))}
                    </SelectControlGroup>
                );
            }
        }

        if (this.state.option == "2") {
            if (this.props.emailOptions && this.props.emailOptions.length > 1) {
                reportOptions = (
                    <SelectControlGroup
                        text="Output report detail"
                        onChange={this.onEmailOptionChanged}
                        defaultValue={this.state.emailOption}>
                        {this.props.emailOptions.map((item, index) => (
                            <option key={item.key} value={item.key}>{item.name}</option>
                        ))}
                    </SelectControlGroup>
                );
            }


            /*
                        
            */

            actionText = "Send";

            if (this.props.printOptions) {

                emailControls = (
                    <>
                        <TextControlGroup text="External Recipients" defaultValue={this.state.extraRecipients} onChange={this.onExtraRecipientsChanged} />
                        <ControlGroup>
                            <p>Separate multiple emails with semi-colons (;)</p>
                        </ControlGroup>
                        <CardContainer
                            title={"Recipients"}>
                            <CardContent style={{ paddingTop: 0 }}>
                                <div style={{ marginTop: '-4px' }}>
                                    <MultiSelectControlGroup
                                        items={this.state.recipients}
                                        minimumCharactersRequired={3}
                                        searchText={"Type at least three letters..."}
                                        onSelectItemsChanged={this.onRecipientsChanged} />
                                </div>
                            </CardContent></CardContainer>

                        {this.props.printOptions.ccVenue.value && (
                            <CheckboxControlGroup label={this.props.printOptions.ccVenue.value} onChanged={this.onCcVenueChanged} readonly={this.state.lockCC} text={this.props.printOptions.ccVenue.key} defaultValue={this.state.ccVenueIsSelected} />
                        )}
                        {this.props.printOptions.ccSecurityFirm.value && (
                            <CheckboxControlGroup label={this.props.printOptions.ccSecurityFirm.value} onChanged={this.onCcSecurityFirmChanged} readonly={this.state.lockCC} text={this.props.printOptions.ccSecurityFirm.key} defaultValue={this.state.ccSecurityFirmIsSelected} />
                        )}
                        <Information message={"If the email address for the Venue/Security Firm is incorrect it can be changed on the Contact page accessed from the \"Manage Venue/Security Firm\" tab."} />
                        {this.props.printOptions.ccUser.value && (
                            <CheckboxControlGroup label="CC myself" onChanged={this.onCcUserFirmChanged} text={this.props.printOptions.ccUser.key} defaultValue={this.state.ccUserIsSelected} />
                        )}
                    </>
                );
            }
        }


        if (this.state.option == "3") {
            actionText = "Export";
            if (this.props.exportOptions && this.props.exportOptions.length > 1) {
                reportOptions = (
                    <SelectControlGroup
                        text="Output report detail"
                        onChange={this.onExportOptionChanged}
                        defaultValue={this.state.exportOption}>
                        {this.props.exportOptions.map((item, index) => (
                            <option key={item.key} value={item.key}>{item.name}</option>
                        ))}
                    </SelectControlGroup>
                );
            }
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <RadioControl
                                selected={this.state.option}
                                options={options}
                                name={"printOptions"}
                                onChange={this.onOptionChanged} />
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {reportOptions}
                            {emailControls}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TransparentButton text={actionText} onClick={this.onClick} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles, { withTheme: true })(PrintAndSend);