import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import View from '../../common/View';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import IncidentTypesFilterSummary from './IncidentTypesFilterSummary';
import IncidentTypesFilter from './IncidentTypesFilter';
import FullPageDialog from '../../layout/FullPageDialog';
import IncidentTypeCard from './IncidentTypeCard';
import IncidentTypeHeader from './IncidentTypeHeader';
import IncidentTypeRow from './IncidentTypeRow';
import IncidentType from './IncidentType';

export interface IIncidentTypesProps {
    isLoading: boolean;
    isSaving: boolean;
    incidentTypes: AusComplyDtos.IncidentType[];
    incidentType: AusComplyDtos.IncidentType;
    industryCategories: AusComplyDtos.IndustryCategory[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.IncidentTypeFilter;
    error: string;
    onRefresh: Function;
    onCreateNew: Function;
    onLoadReferenceData: Function;
    onSet: Function;
    onUpdate: Function;
    classes: any;
    theme: any;
}

interface IIncidentTypesState {
    filterExpanded: boolean;
}

class IncidentTypes extends Component<IIncidentTypesProps, IIncidentTypesState> {
    constructor(props: IIncidentTypesProps) {
        super(props)

        this.state = {
            filterExpanded: false
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
    }

    componentDidMount() {
        this.props.onLoadReferenceData();
        
        this.props.onRefresh(this.props.filter, this.props.paging.page, this.props.paging.pageSize);
    }

    componentDidUpdate(prevProps) {
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.filter, page, this.props.paging.pageSize);
    }

    onSearch(filter: AusComplyDtos.IncidentTypeFilter) {
        this.props.onRefresh(filter, this.props.paging.page, this.props.paging.pageSize);
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onRefresh(new AusComplyDtos.IncidentTypeFilter(), 1, this.props.paging.pageSize);
        this.setState({ filterExpanded: false });
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onRefresh(this.props.filter, 1, this.props.paging.pageSize);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onEdit(incidentType: AusComplyDtos.IncidentType) {
        this.props.onSet(incidentType);
    }

    onCancelEdit() {
        let incidentType = new AusComplyDtos.IncidentType();
        incidentType.incidentTypeId = -1;
        this.props.onSet(incidentType);
    }

    onUpdate(incidentType: AusComplyDtos.IncidentType) {
        this.props.onUpdate(incidentType);
    }

    onUpdateFilter(filter: AusComplyDtos.IncidentTypeFilter) {

    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.incidentTypes && this.props.incidentTypes.length > 0) {
            cardList = this.props.incidentTypes.map((item, index) => {
                return (
                    <IncidentTypeCard
                        onUpdate={this.onUpdate}
                        onEdit={this.onEdit}
                        key={"card-" + item.incidentTypeId.toString()}
                        incidentType={item}
                    />
                );
            });

            rows = this.props.incidentTypes.map((item, index) => {
                return (
                    <IncidentTypeRow
                        onUpdate={this.onUpdate}
                        onEdit={this.onEdit}
                        key={"row-" + item.incidentTypeId.toString()}
                        incidentType={item} />
                );
            });
        }

        let filter;
        let filterSummary;

        if (this.state.filterExpanded) {
            filter = <IncidentTypesFilter
                filter={this.props.filter}
                industryCategories={this.props.industryCategories}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={this.onSearch}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let incidentTypeFilter = new AusComplyDtos.IncidentTypeFilter();
            incidentTypeFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentTypesFilterSummary filter={incidentTypeFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentTypesFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        rowList = (
            <TableContainer
                header={<IncidentTypeHeader />}
                rows={rows}
            />
        );

        let incidentType;
        //if (this.props.incidentType && this.props.incidentType.incidentTypeId > -1) {
            incidentType = (
                <IncidentType
                    open={this.props.incidentType && this.props.incidentType.incidentTypeId > -1}
                    incidentType={this.props.incidentType}
                    onSave={this.onUpdate}
                    onCancel={() => this.onCancelEdit()}
                    isSaving={this.props.isSaving}
                    error={this.props.error} />
            );
        //}

        return (
            <>
                <PageTitle title="Incident Types"></PageTitle>
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <VenueQuickActionsContainer>
                    <DefaultFab onClick={() => this.props.onCreateNew()} isExtended={true}>Add Incident Type</DefaultFab>
                </VenueQuickActionsContainer>
                <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
                {incidentType}
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentTypes);