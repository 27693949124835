import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { KeyName } from '../../../common/dto/common';
import TableRow from '../../common/TableRow';
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';

export interface IIncidentTypeRowProps {
    incidentType: AusComplyDtos.IncidentType;
    onEdit?: Function;
    onUpdate?: Function;
}

export default class IncidentTypeRow extends React.Component<IIncidentTypeRowProps, any> {
    constructor(props: IIncidentTypeRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        switch (command) {
            case "edit":
                if (this.props.onEdit) {
                    this.props.onEdit(this.props.incidentType);
                }
                break;
            case "remove":
                if (this.props.onUpdate) {
                    let incidentType = { ...this.props.incidentType };
                    incidentType.obsolete = true;
                    this.props.onUpdate(incidentType);
                }
                break;
            case "enable":
                if (this.props.onUpdate) {
                    let incidentType = { ...this.props.incidentType };
                    incidentType.obsolete = false;
                    this.props.onUpdate(incidentType);
                }
                break;
        }
    }

    render() {
        if (!this.props.incidentType) {
            return (<></>);
        }

        var commands: KeyName[] = [];
        if (this.props.incidentType.obsolete && this.props.onUpdate) {
            commands.push({ key: "enable", name: "Re-enable" });
        } else {
            if (this.props.onEdit) {
                commands.push({ key: "edit", name: "Edit" });
            }
            if (this.props.onUpdate) {
                commands.push({ key: "remove", name: "Remove" });
            }
        }

        let legislatedTick;
        if (this.props.incidentType.legislated) {
            legislatedTick = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        var categories = this.props.incidentType.industryCategoriesDisplay;
        if (categories.length > 100) {
            categories = categories.substring(0, 100) + "... (" + this.props.incidentType.industryCategorySelections.filter(f => f.selected).length.toString() + ")";
        }
        
        var types = this.props.incidentType.incidentCategoryTypesDisplay;
        if (types.length > 100) {
            types = types.substring(0, 100) + "... (" + this.props.incidentType.incidentCategoryTypeSelections.filter(f => f.selected).length.toString() + ")";
        }

        return (
            <TableRow isDanger={this.props.incidentType.obsolete} commands={commands} onCommand={this.onCommand}>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {this.props.incidentType.name}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    {legislatedTick}
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p style={{fontStyle: 'italic', color: '#aaa'}}>
                        {categories}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p style={{fontStyle: 'italic', color: '#aaa'}}>
                        {types}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <p>
                        {this.props.incidentType.rank}
                    </p>
                </td>
                <td onClick={() => this.onCommand("edit")}>
                    <div style={{ borderRadius: '3px', border: '1px solid #444', margin: '0 10px', width: '20px', height: '20px', backgroundColor: this.props.incidentType.color ? this.props.incidentType.color : 'transparent'}}>
                        
                    </div>
                </td>
            </TableRow>
        );
    }
}