import React, { Component } from 'react';
import { KeyName } from '../../../common/dto/common';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import AdminUserActionAuditContainer from '../../../containers/AdminUserActionAuditContainer';
import AdminUserChangePasswordContainer from '../../../containers/AdminUserChangePasswordContainer';
import UserRoleRequests from '../../userRoles/userRoleRequests/UserRoleRequests';
import UserRoles from '../../userRoles/UserRoles';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import ProfileOtherDocuments from '../../profile/ProfileOtherDocuments';
import ProfileComplianceDocuments from '../../profile/ProfileComplianceDocuments';
import Tabs from '../../common/Tabs';
import LayoutForm from '../../layout/LayoutForm';
import PageTitle from '../../common/PageTitle';
import ControlGroup from "../../controls/ControlGroup";
import CardContent from '@material-ui/core/CardContent';
import CheckboxControl from "../../controls/CheckboxControl";
import TextControlGroup from '../../controls/TextControlGroup';
import PasswordControlGroup from '../../controls/PasswordControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import CardContainer from '../../common/CardContainer';
import DefaultButton from '../../controls/DefaultButton';
import ProfileAvatarEdit from '../../profile/ProfileAvatarEdit';
import Box from '@material-ui/core/Box';
import Saving from '../../common/Saving';
import FullPageDialog from '../../layout/FullPageDialog';
import DefaultFab from '../../common/DefaultFab';
import PersonAddIcon from '../../../resources/PersonAddIcon';
import AddUserRoleContainer from '../../../containers/AddUserRoleContainer';
import EditUserRoleContainer from '../../../containers/EditUserRoleContainer';
import RemoveUserRoleContainer from '../../../containers/RemoveUserRoleContainer';
import SecondaryFab from '../../common/SecondaryFab';
import UserDirectoryContainer from '../../../containers/UserDirectoryContainer';

export interface IUserProps {
    userId: number;
    user: AusComplyDtos.User;
    isLoading: boolean;
    isSaving: boolean;
    onLoad: Function;
    onUpdate: Function;
    onReset: Function;
    onSave: Function;
    onCheckLicense: Function;
    onUserRequestDismiss: Function;
    onUserRoleEnableRequest: Function;
    errors: any;
    isCheckingLicense: boolean;
    states: AusComplyDtos.State[];
    genders: AusComplyDtos.Gender[];
    userRoleRequests: AusComplyDtos.UserRoleRequest[];
    userRoleSummaries: AusComplyDtos.UserRoleSummary[];
    otherDocuments: AusComplyDtos.UserOtherDocument[];
    otherDocumentTypes: AusComplyDtos.DocumentType[];
    onSaveOtherDocuments: Function;
    onRemoveOtherDocument: Function;
    complianceDocuments: AusComplyDtos.UserComplianceDocument[];
    complianceDocumentTypes: AusComplyDtos.DocumentType[];
    onSaveComplianceDocuments: Function;
    onRemoveComplianceDocument: Function;
    onImpersonate: Function;
    avatar: AusComplyDtos.File;
    currentUser: AusComplyDtos.ngtUser,
    hasEditPermission: boolean,
    isVenueUserRoleType: boolean,
    isSecurityFirmRoleType: boolean,
    onPushTest: Function;
    history: any;
    location: any;
}

interface IUserState {
    selectedTab: number;
    editUserRoleId: number;
    removeUserRoleId: number;
    showAddVenueUserRole: boolean;
    showAddSecurityFirmUserRole: boolean;
    obsoleteUserRoles: boolean;
    obsoleteDocuments: boolean;
    pushTestIncidentId: string;
}

export default class User extends Component<IUserProps, IUserState> {
    constructor(props: IUserProps) {
        super(props)
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSaveOtherDocuments = this.onSaveOtherDocuments.bind(this);
        this.onRemoveOtherDocument = this.onRemoveOtherDocument.bind(this);
        this.onSaveComplianceDocuments = this.onSaveComplianceDocuments.bind(this);
        this.onRemoveComplianceDocument = this.onRemoveComplianceDocument.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onEditUserRole = this.onEditUserRole.bind(this);
        this.onRemoveUserRole = this.onRemoveUserRole.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onAcceptedTermsChanged = this.onAcceptedTermsChanged.bind(this);
        this.onVenueStaffChanged = this.onVenueStaffChanged.bind(this);
        this.onSecurityStaffChanged = this.onSecurityStaffChanged.bind(this);
        this.onCheckSled = this.onCheckSled.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.onToggleShowAddVenueUserRole = this.onToggleShowAddVenueUserRole.bind(this);
        this.onToggleShowAddSecurityFirmUserRole = this.onToggleShowAddSecurityFirmUserRole.bind(this);
        this.onObsoleteUserRolesChanged = this.onObsoleteUserRolesChanged.bind(this);
        this.onObsoleteDocumentsChanged = this.onObsoleteDocumentsChanged.bind(this);
        this.onVerified = this.onVerified.bind(this);
        this.onImpersonate = this.onImpersonate.bind(this);
        this.state = {
            selectedTab: 0,
            editUserRoleId: 0,
            removeUserRoleId: 0,
            showAddVenueUserRole: false,
            showAddSecurityFirmUserRole: false,
            obsoleteUserRoles: false,
            obsoleteDocuments: false,
            pushTestIncidentId: "0"
        };
    }

    componentDidMount() {
        if (this.props.userId != this.props.user.userId && this.props.userId > 0) {
            this.props.onLoad(this.props.userId);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.userId != this.props.user.userId && this.props.userId > 0 && !this.props.isLoading) {
            this.props.onLoad(this.props.userId);
        }
    }

    handleTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    onCancel() {
        this.props.onReset();
        var location = this.props.location.pathname || "";
        let backLocation = location.split('/' + this.props.userId.toString())[0] + "s";
        this.props.history.push(backLocation);
    }

    onSaveOtherDocuments(documentTypeId, files) {
        this.props.onSaveOtherDocuments(this.props.userId, documentTypeId, files);
    }

    onRemoveOtherDocument(otherDocument) {
        this.props.onRemoveOtherDocument(this.props.userId, otherDocument);
    }

    onSaveComplianceDocuments(documentTypeId, expiryDate, files) {
        this.props.onSaveComplianceDocuments(this.props.userId, documentTypeId, expiryDate, files);
    }

    onRemoveComplianceDocument(complianceDocument) {
        this.props.onRemoveComplianceDocument(this.props.userId, complianceDocument);
    }

    onVenueStaffChanged(value) {
        let user = { ...this.props.user };
        user.isVenueStaff = value;
        this.props.onUpdate(user);
    }

    onSecurityStaffChanged(value) {
        let user = { ...this.props.user };
        user.isSecurityStaff = value;
        this.props.onUpdate(user);
    }

    onValueChanged(fieldName: string, value: any) {
        let user = { ...this.props.user };
        user[fieldName] = value;
        this.props.onUpdate(user);
    }

    onAcceptedTermsChanged(value) {
        let user = { ...this.props.user };
        user.hasAcceptedTerms = value;
        this.props.onUpdate(user);
    }

    onVerified(value) {
        let user = { ...this.props.user };
        if (value) {
            user.dateVerifiedUtc = new Date().toUTCString();
        } else {
            user.dateVerifiedUtc = "";
        }
        user.isVerified = value;
        this.props.onUpdate(user);
    }

    onObsoleteUserRolesChanged(value) {
        this.setState({ obsoleteUserRoles: value });
    }

    onObsoleteDocumentsChanged(value) {
        this.setState({ obsoleteDocuments: value });
    }

    onSave() {
        var user = { ...this.props.user };
        this.props.onSave(user);
    }

    onEditUserRole(userRoleId: number, refresh: boolean) {
        this.setState({ editUserRoleId: userRoleId }, () => {
            if (refresh) {
                this.props.onLoad(this.props.userId);
            }
        });
    }

    onRemoveUserRole(userRoleId: number, refresh: boolean) {
        this.setState({ removeUserRoleId: userRoleId }, () => {
            if (refresh) {
                this.props.onLoad(this.props.userId);
            }
        });
    }

    onCheckSled() {
        this.props.onCheckLicense(this.props.user);
    }

    onCommand(command) {

        switch (command) {
            case "addVenueUserRole":
                this.onToggleShowAddVenueUserRole(false);
                break;
            case "addSecurityFirmUserRole":
                this.onToggleShowAddSecurityFirmUserRole(false);
                break;
        }
    }

    onToggleShowAddVenueUserRole(reload: boolean) {
        this.setState({ showAddVenueUserRole: !this.state.showAddVenueUserRole }, () => {
            if (reload) {
                this.props.onLoad(this.props.userId)
            }
        });
    }

    onToggleShowAddSecurityFirmUserRole(reload: boolean) {
        this.setState({ showAddSecurityFirmUserRole: !this.state.showAddSecurityFirmUserRole }, () => {
            if (reload) {
                this.props.onLoad(this.props.userId)
            }
        });
    }

    getCommands(): KeyName[] {
        let moreCommands: KeyName[] = [];
        if (this.props.hasEditPermission && this.props.isVenueUserRoleType) {
            moreCommands.push({ key: "addVenueUserRole", name: "Add Venue User Role" });
        }
        if (this.props.hasEditPermission && this.props.isSecurityFirmRoleType) {
            moreCommands.push({ key: "addSecurityFirmUserRole", name: "Add Security Firm User Role" });
        }
        return moreCommands;
    }

    onImpersonate(userId: number) {
        this.props.onImpersonate(userId);
        this.props.history.push("/");
    }

    render() {
        let title = "User"
        if (this.props.user && !this.props.isLoading) {
            title = this.props.user.displayName || title;
        }
        let error;
        let moreCommands: KeyName[] = this.getCommands();
        let userRoleSummaries: AusComplyDtos.UserRoleSummary[] = [];
        if (this.props.userRoleSummaries) {
            userRoleSummaries = this.props.userRoleSummaries.filter((item) => this.state.obsoleteUserRoles || !item.userRoleObsolete);
        }
        let complianceDocuments: AusComplyDtos.UserComplianceDocument[] = [];
        if (this.props.complianceDocuments) {
            complianceDocuments = this.props.complianceDocuments.filter((item) => this.state.obsoleteDocuments || !item.obsolete);
        }
        let otherDocuments: AusComplyDtos.UserOtherDocument[] = [];
        if (this.props.complianceDocuments) {
            otherDocuments = this.props.otherDocuments.filter((item) => this.state.obsoleteDocuments || !item.obsolete);
        }
        let tabs = <div style={{ display: 'display-block', marginTop: '10px' }}>
            <Tabs selected={this.state.selectedTab} labels={["Roles", "General", "Documents", "Notifications", "Password", "Actions"]} onClick={index => this.handleTabChange(null, index)}></Tabs>
        </div>

        if (this.state.selectedTab == 2) {
            return <UserDirectoryContainer userId={this.props.user.userId} tabs={tabs} title={title} />
        }

        return (
            <>
                <LayoutForm
                    loading={this.props.isLoading}
                    saving={this.props.isSaving}
                    error={""}
                    fullWidth={true}
                    header={<PageTitle title={title}>
                        <Box p={0} display="flex" flexDirection={'row'}>
                            <Box flexGrow={1} p={0}></Box>
                            <Box p={0}>
                                <DefaultButton
                                    onClick={() => this.onCancel()}
                                    text={"Back"} />
                            </Box>
                        </Box>
                    </PageTitle>}
                >
                    <form>
                        {tabs}
                        {this.state.selectedTab === 0 && (
                            <>
                                <CardContainer title={"Filter"}>
                                    <CardContent>
                                        <CheckboxControl
                                            text={"Show removed user roles"}
                                            defaultValue={this.state.obsoleteUserRoles}
                                            onChanged={this.onObsoleteUserRolesChanged} />
                                    </CardContent>
                                </CardContainer>
                                <UserRoles
                                    userRoleSummaries={userRoleSummaries}
                                    onEnable={(userRoleId) => this.props.onUserRoleEnableRequest(userRoleId)}
                                    onEdit={(value) => this.onEditUserRole(value, false)}
                                    onRemove={(value) => this.onRemoveUserRole(value, false)} />
                                <UserRoleRequests
                                    userRoleRequests={this.props.userRoleRequests}
                                    onDismiss={this.props.onUserRequestDismiss}
                                />
                            </>
                        )}
                        {this.state.selectedTab === 1 && (
                            <>
                                {error}
                                <ProfileAvatarEdit avatar={this.props.avatar} />
                                <ControlGroup text={"User Type(s)"}>
                                    <CheckboxControl
                                        text={"Venue Staff"}
                                        defaultValue={this.props.user.isVenueStaff}
                                        readonly={!this.props.user.canEditIsVenueStaff}
                                        onChanged={this.onVenueStaffChanged} />
                                    <CheckboxControl
                                        text={"Security Staff"}
                                        defaultValue={this.props.user.isSecurityStaff}
                                        readonly={!this.props.user.canEditIsSecurityStaff}
                                        onChanged={this.onSecurityStaffChanged} />
                                </ControlGroup>
                                {this.props.user.isVenueStaff && (
                                    <TextControlGroup
                                        text={"RSA Number"}
                                        error={this.props.errors["rsaNumber"]}
                                        defaultValue={this.props.user.rsaNumber}
                                        onBlur={(value) => this.onValueChanged("rsaNumber", value)} />
                                )}
                                {this.props.user.isSecurityStaff && (
                                    <>
                                        <SelectControlGroup
                                            text="State Licensed"
                                            error={this.props.errors["stateLicensedId"]}
                                            onChange={(value) => this.onValueChanged("stateLicensedId", value)}
                                            defaultValue={this.props.user.stateLicensedId}>
                                            <option value={0} />
                                            {this.props.states && this.props.states.map((item, index) => (
                                                <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                                            ))}
                                        </SelectControlGroup>
                                        <TextControlGroup
                                            text={"License Number"}
                                            error={this.props.errors["licenceNumber"]}
                                            defaultValue={this.props.user.licenceNumber}
                                            onBlur={(value) => this.onValueChanged("licenceNumber", value)}
                                            readonly={!this.props.user.canEditLicenceNumber} />
                                        {this.props.user.sledEnabled && (
                                            <>
                                                <TextControlGroup text={"Last Licence Check"} defaultValue={this.props.user.dateLastSledCheckDisplay} readonly={true} />
                                                {(this.props.user.stateId == 2) && (
                                                    <TextControlGroup text={"Licence Status"} defaultValue={this.props.user.sledStatusDisplay} readonly={true} />
                                                )}
                                                {(this.props.isCheckingLicense) && (
                                                    <ControlGroup text={" "}>
                                                        <Saving message={"Checking License"} />
                                                    </ControlGroup>
                                                )}
                                                {(!this.props.isCheckingLicense && this.props.user.canCheckSled) && (
                                                    <ControlGroup>
                                                        <DefaultButton text={"Check License"} onClick={() => this.onCheckSled()} />
                                                    </ControlGroup>
                                                )}
                                            </>
                                        )}

                                    </>
                                )}
                                <TextControlGroup
                                    text={"First Name"}
                                    error={this.props.errors["givenName"]}
                                    defaultValue={this.props.user.givenName}
                                    readonly={!this.props.user.canEditGivenName}
                                    onBlur={(value) => this.onValueChanged("givenName", value)} />
                                <TextControlGroup
                                    text={"Last Name"}
                                    error={this.props.errors["surname"]}
                                    defaultValue={this.props.user.surname}
                                    readonly={!this.props.user.canEditSurname}
                                    onBlur={(value) => this.onValueChanged("surname", value)} />
                                <TextControlGroup
                                    text={"Nickname"}
                                    error={this.props.errors["nickname"]}
                                    defaultValue={this.props.user.nickname}
                                    onBlur={(value) => this.onValueChanged("nickname", value)} />
                                <SelectControlGroup
                                    text="Gender"
                                    error={this.props.errors["genderId"]}
                                    onChange={(value) => this.onValueChanged("genderId", value)}
                                    defaultValue={this.props.user.genderId}>
                                    <option value={0} />
                                    {this.props.genders && this.props.genders.map((item, index) => (
                                        <option key={item.genderId} value={item.genderId}>{item.name}</option>
                                    ))}
                                </SelectControlGroup>
                                <TextControlGroup
                                    text={"Address"}
                                    error={this.props.errors["address"]}
                                    defaultValue={this.props.user.address}
                                    onBlur={(value) => this.onValueChanged("address", value)} />
                                <TextControlGroup
                                    text={"Suburb"}
                                    error={this.props.errors["suburb"]}
                                    defaultValue={this.props.user.suburb}
                                    onBlur={(value) => this.onValueChanged("suburb", value)} />
                                <SelectControlGroup
                                    text="State"
                                    error={this.props.errors["stateId"]}
                                    onChange={(value) => this.onValueChanged("stateId", value)}
                                    defaultValue={this.props.user.stateId}>
                                    <option value={0} />
                                    {this.props.states && this.props.states.map((item, index) => (
                                        <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                                    ))}
                                </SelectControlGroup>
                                <TextControlGroup
                                    text={"Postcode"}
                                    error={this.props.errors["postcode"]}
                                    defaultValue={this.props.user.postcode}
                                    onBlur={(value) => this.onValueChanged("postcode", value)} />
                                <TextControlGroup
                                    text={"Email Address"}
                                    error={this.props.errors["email"]}
                                    defaultValue={this.props.user.email}
                                    onBlur={(value) => this.onValueChanged("email", value)} />
                                <TextControlGroup
                                    text={"Mobile"}
                                    error={this.props.errors["mobileNo"]}
                                    defaultValue={this.props.user.mobileNo}
                                    onBlur={(value) => this.onValueChanged("mobileNo", value)} />
                                <TextControlGroup
                                    text={"Emergency Contact Name"}
                                    error={this.props.errors["emergencyContactName"]}
                                    defaultValue={this.props.user.emergencyContactName}
                                    onBlur={(value) => this.onValueChanged("emergencyContactName", value)} />
                                <TextControlGroup
                                    text={"Emergency Contact Mobile"}
                                    error={this.props.errors["emergencyContactNumber"]}
                                    defaultValue={this.props.user.emergencyContactNumber}
                                    onBlur={(value) => this.onValueChanged("emergencyContactNumber", value)} />
                                <ControlGroup text={" "}>
                                    <CheckboxControl text={"Has agreed to the Terms and Conditions"}
                                        defaultValue={this.props.user.hasAcceptedTerms}
                                        onChanged={this.onAcceptedTermsChanged} />
                                </ControlGroup>
                                <ControlGroup text={" "}>
                                    <CheckboxControl text={"Email is verified"}
                                        defaultValue={this.props.user.isVerified}
                                        onChanged={this.onVerified} />
                                </ControlGroup>
                                <ControlGroup text={" "}>
                                    <CheckboxControl text={"Obsolete"}
                                        defaultValue={this.props.user.obsolete}
                                        onChanged={(value) => this.onValueChanged("obsolete", value)} />
                                </ControlGroup>
                                <ControlGroup text={" "}>
                                    <DefaultButton disabled={this.props.errors.count > 0} onClick={() => this.onSave()}>Save</DefaultButton>
                                </ControlGroup>
                            </>
                        )}
                        {this.state.selectedTab === 2 && (
                            <>
                                <CardContainer title={"Filter"}>
                                    <CardContent>
                                        <CheckboxControl
                                            text={"Show removed documents"}
                                            defaultValue={this.state.obsoleteDocuments}
                                            onChanged={this.onObsoleteDocumentsChanged} />
                                    </CardContent>
                                </CardContainer>
                                <ProfileComplianceDocuments
                                    showObsolete={this.state.obsoleteDocuments}
                                    documents={complianceDocuments}
                                    onSave={this.onSaveComplianceDocuments}
                                    onRemove={this.onRemoveComplianceDocument}
                                    documentTypes={this.props.complianceDocumentTypes}
                                />
                                <ProfileOtherDocuments
                                    showObsolete={this.state.obsoleteDocuments}
                                    documents={otherDocuments}
                                    onSave={this.onSaveOtherDocuments}
                                    onRemove={this.onRemoveOtherDocument}
                                    documentTypes={this.props.otherDocumentTypes} />
                            </>
                        )}
                        {this.state.selectedTab === 3 && (
                            <>
                                {error}
                                <ControlGroup text="External Notification Options">
                                    <TextDisplay>All notifications will also appear on notifications page on the website or app</TextDisplay>
                                </ControlGroup>
                                <ControlGroup text={" "}>
                                    <CheckboxControl
                                        text={"Push Notifications (iOS/Android)"}
                                        label={" "}
                                        readonly={true}
                                        defaultValue={this.props.user.inAppNotification}
                                        onChanged={(value) => this.onValueChanged("inAppNotification", value)} />
                                </ControlGroup>
                                <ControlGroup text={"Incident / Checklist / Facial Recognition Notifications"}>
                                    <CheckboxControl text={"Email"}
                                        label={" "}
                                        defaultValue={this.props.user.emailNotification}
                                        onChanged={(value) => this.onValueChanged("emailNotification", value)} />
                                    <br />
                                    <CheckboxControl
                                        text={"SMS"}
                                        label={" "}
                                        defaultValue={this.props.user.smsNotification}
                                        onChanged={(value) => this.onValueChanged("smsNotification", value)} />
                                </ControlGroup>
                                <ControlGroup text={"AusComply / Administrator Notifications"}>
                                    <CheckboxControl text={"Email"}
                                        label={" "}
                                        defaultValue={this.props.user.globalEmailBroadcastNotification}
                                        onChanged={(value) => this.onValueChanged("globalEmailBroadcastNotification", value)} />
                                    <br />
                                    <CheckboxControl
                                        text={"SMS"}
                                        label={" "}
                                        defaultValue={this.props.user.globalSmsBroadcastNotification}
                                        onChanged={(value) => this.onValueChanged("globalSmsBroadcastNotification", value)} />
                                </ControlGroup>
                                <ControlGroup text={"Venue / Security Firm Specific Broadcast Notification"}>
                                    <CheckboxControl text={"Email"}
                                        label={" "}
                                        defaultValue={this.props.user.venueSecurityFirmEmailBroadcastNotification}
                                        onChanged={(value) => this.onValueChanged("venueSecurityFirmEmailBroadcastNotification", value)} />
                                    <br />
                                    <CheckboxControl
                                        text={"SMS"}
                                        label={" "}
                                        defaultValue={this.props.user.venueSecurityFirmSmsBroadcastNotification}
                                        onChanged={(value) => this.onValueChanged("venueSecurityFirmSmsBroadcastNotification", value)} />
                                </ControlGroup>
                                <ControlGroup text={" "}>
                                    <DefaultButton disabled={this.props.errors.count > 0} onClick={() => this.onSave()}>Save</DefaultButton>
                                </ControlGroup>
                                <TextControlGroup
                                    text={"Test Incident ID"}
                                    defaultValue={this.state.pushTestIncidentId}
                                    onChange={(value) => this.setState({ pushTestIncidentId: value })} />
                                <ControlGroup text={" "}>
                                    <DefaultButton onClick={() => this.props.onPushTest(this.props.userId, this.state.pushTestIncidentId)}>Push Test</DefaultButton>
                                </ControlGroup>
                            </>
                        )}
                        {this.state.selectedTab === 4 && (
                            <>
                                <AdminUserChangePasswordContainer userId={this.props.userId} />
                            </>
                        )}
                        {this.state.selectedTab === 5 && (
                            <>
                                <AdminUserActionAuditContainer userId={this.props.userId} />
                            </>
                        )}
                    </form>
                </LayoutForm>
                <VenueQuickActionsContainer>
                    <SecondaryFab isExtended={true} onClick={() => this.onImpersonate(this.props.userId)}>
                        Impersonate
                    </SecondaryFab>
                    {moreCommands.length > 0 && (
                        <DefaultFab actions={moreCommands} onClick={this.onCommand}><PersonAddIcon style={{ marginTop: '6px', fontSize: '1.5rem', height: '1em', width: '1em' }} /></DefaultFab>
                    )}
                </VenueQuickActionsContainer>
                <FullPageDialog
                    open={this.state.showAddVenueUserRole}
                    title={"Add Venue User Role(s)"}
                    onDismissed={() => this.onToggleShowAddVenueUserRole(false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.showAddVenueUserRole && (
                        <AddUserRoleContainer userId={this.props.userId} onSuccess={() => this.onToggleShowAddVenueUserRole(false)} forSecurityFirm={false} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.showAddSecurityFirmUserRole}
                    title={"Add Security Firm User Role(s)"}
                    onDismissed={() => this.onToggleShowAddSecurityFirmUserRole(false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {this.state.showAddSecurityFirmUserRole && (
                        <AddUserRoleContainer userId={this.props.userId} onSuccess={() => this.onToggleShowAddSecurityFirmUserRole(false)} forSecurityFirm={true} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.editUserRoleId != 0}
                    title={"Edit User Role"}
                    onDismissed={() => this.onEditUserRole(0, false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.editUserRoleId != 0) && (
                        <EditUserRoleContainer userRoleId={this.state.editUserRoleId} onSuccess={() => this.onEditUserRole(0, true)} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.removeUserRoleId != 0}
                    title={"Remove User Role"}
                    onDismissed={() => this.onRemoveUserRole(0, false)}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.removeUserRoleId != 0) && (
                        <RemoveUserRoleContainer userRoleId={this.state.removeUserRoleId}
                            onSuccess={() => this.onRemoveUserRole(0, true)} />
                    )}
                </FullPageDialog>
            </>
        );
    }
}