import { connect } from 'react-redux'
import { withRouter } from "react-router";
import IncidentActionTemplates from '../components/incidentActions/incidentActionTemplates/IncidentActionTemplates'
import {
    incidentActionTemplateSet,
    incidentActionTemplatesSetFilter,
    incidentActionTemplateUpsertRequest,
    incidentActionTemplatesReset,
    incidentActionTemplateRequest
} from '../common/actions/incidentActionTemplates';

const mapStateToProps = (state, props) => {
    return {
        venueId: undefined,
        groupId: undefined,
        filter: state.incidentActionTemplates.incidentActionTemplates.filter,
        isLoading: state.incidentActionTemplates.incidentActionTemplates.isLoading,
        incidentActionTemplates: state.incidentActionTemplates.incidentActionTemplates.incidentActionTemplates,
        canEdit: true,
        incidentActionTemplate: state.incidentActionTemplates.incidentActionTemplate.incidentActionTemplate,
        isLoadingItem: state.incidentActionTemplates.incidentActionTemplate.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetFilter: (filter) => {
            dispatch(incidentActionTemplatesSetFilter(filter))
        },
        onSet: (incidentActionTemplate) => {
            dispatch(incidentActionTemplateSet(incidentActionTemplate))
        },
        onUpdate: () => {
            dispatch(incidentActionTemplateUpsertRequest())
        },
        onReset: () => {
            dispatch(incidentActionTemplatesReset())
        },
        onLoadItem: (checklistTemplateId) => {
            dispatch(incidentActionTemplateRequest(checklistTemplateId))
        }
    }
}

const AdminIncidentActionTemplatesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentActionTemplates))

export default AdminIncidentActionTemplatesContainer