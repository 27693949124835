import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import View from '../../common/View';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import IncidentCategoryTypesFilterSummary from './IncidentCategoryTypesFilterSummary';
import IncidentCategoryTypesFilter from './IncidentCategoryTypesFilter';
import FullPageDialog from '../../layout/FullPageDialog';
import IncidentCategoryTypeCard from './IncidentCategoryTypeCard';
import IncidentCategoryTypeHeader from './IncidentCategoryTypeHeader';
import IncidentCategoryTypeRow from './IncidentCategoryTypeRow';
import IncidentCategoryType from './IncidentCategoryType';

export interface IIncidentCategoryTypesProps {
    isLoading: boolean;
    isSaving: boolean;
    incidentCategoryTypes: AusComplyDtos.IncidentCategoryType[];
    incidentCategoryType: AusComplyDtos.IncidentCategoryType;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.IncidentCategoryTypeFilter;
    states: AusComplyDtos.State[];
    error: string;
    canAdd: boolean;
    canEdit: boolean;
    canDelete: boolean;
    onRefresh: Function;
    onCreateNew: Function;
    onSet: Function;
    onUpdate: Function;
    classes: any;
    theme: any;
}

interface IIncidentCategoryTypesState {
    filterExpanded: boolean;
    filter: AusComplyDtos.IncidentCategoryTypeFilter;
}

class IncidentCategoryTypes extends Component<IIncidentCategoryTypesProps, IIncidentCategoryTypesState> {
    constructor(props: IIncidentCategoryTypesProps) {
        super(props)

        this.state = {
            filterExpanded: false,
            filter: new AusComplyDtos.IncidentCategoryTypeFilter()
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
    }

    componentDidMount() {
        this.props.onRefresh(this.props.filter, this.props.paging.page, this.props.paging.pageSize);
    }

    componentDidUpdate(prevProps) {
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.filter, page, this.props.paging.pageSize);
    }

    onSearch(filter: AusComplyDtos.IncidentCategoryTypeFilter) {
        this.props.onRefresh(filter, this.props.paging.page, this.props.paging.pageSize);
        this.setState({
            filterExpanded: false
        });
    }

    onResetSearch() {
        this.props.onRefresh(new AusComplyDtos.IncidentCategoryTypeFilter(), 1, this.props.paging.pageSize);
        this.setState({ filterExpanded: false });
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onRefresh(this.state.filter, 1, this.props.paging.pageSize);
        } else {
            this.setState({filter: this.props.filter});
        }
        this.setState({
            filterExpanded: value
        })
    }

    onEdit(incidentCategoryType: AusComplyDtos.IncidentCategoryType) {
        this.props.onSet(incidentCategoryType);
    }

    onCancelEdit() {
        let incidentCategoryType = new AusComplyDtos.IncidentCategoryType();
        incidentCategoryType.incidentCategoryTypeId = -1;
        this.props.onSet(incidentCategoryType);
    }

    onUpdate(incidentCategoryType: AusComplyDtos.IncidentCategoryType) {
        this.props.onUpdate(incidentCategoryType);
    }

    onUpdateFilter(filter: AusComplyDtos.IncidentCategoryTypeFilter) {
        this.setState({filter});
    }

    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.incidentCategoryTypes && this.props.incidentCategoryTypes.length > 0) {
            cardList = this.props.incidentCategoryTypes.map((item, index) => {
                return (
                    <IncidentCategoryTypeCard
                        onUpdate={this.onUpdate}
                        onEdit={this.onEdit}
                        canEdit={this.props.canEdit}
                        canDelete={this.props.canDelete}
                        key={"card-" + item.incidentCategoryTypeId.toString()}
                        incidentCategoryType={item}
                    />
                );
            });

            rows = this.props.incidentCategoryTypes.map((item, index) => {
                return (
                    <IncidentCategoryTypeRow
                        onUpdate={this.onUpdate}
                        onEdit={this.onEdit}
                        canEdit={this.props.canEdit}
                        canDelete={this.props.canDelete}
                        key={"row-" + item.incidentCategoryTypeId.toString()}
                        incidentCategoryType={item} />
                );
            });
        }

        let filter;
        let filterSummary;

        if (this.state.filterExpanded) {
            filter = <IncidentCategoryTypesFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={() => this.filterExpanded(false)}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let incidentCategoryTypeFilter = new AusComplyDtos.IncidentCategoryTypeFilter();
            incidentCategoryTypeFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentCategoryTypesFilterSummary filter={incidentCategoryTypeFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentCategoryTypesFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        rowList = (
            <TableContainer
                header={<IncidentCategoryTypeHeader />}
                rows={rows}
            />
        );

        let incidentCategoryType;
        if (this.props.incidentCategoryType && this.props.incidentCategoryType.incidentCategoryTypeId > -1) {
            incidentCategoryType = (
                <IncidentCategoryType
                    incidentCategoryType={this.props.incidentCategoryType}
                    onSave={this.onUpdate}
                    states={this.props.states}
                    onCancel={() => this.onCancelEdit()}
                    isSaving={this.props.isSaving}
                    error={this.props.error} />
            );
        }

        let addButton;
        if (this.props.canAdd) {
            addButton = (<DefaultFab onClick={() => this.props.onCreateNew()} isExtended={true}>Add Incident Category</DefaultFab>);
        }

        return (
            <>
                <PageTitle title="Incident Category Types"></PageTitle>
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <VenueQuickActionsContainer>
                    {addButton}
                </VenueQuickActionsContainer>
                <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
                <FullPageDialog open={this.props.incidentCategoryType.incidentCategoryTypeId != -1} title="Incident Category Type" onDismissed={() => this.onCancelEdit()}>
                    {incidentCategoryType}
                </FullPageDialog>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentCategoryTypes);