export const
    // INCIDENT_ACTION_TEMPLATES_REFRESH = "INCIDENT_ACTION_TEMPLATES_REFRESH",
    INCIDENT_ACTION_TEMPLATES_RESET = "INCIDENT_ACTION_TEMPLATES_RESET",
    INCIDENT_ACTION_TEMPLATES_SET_FILTER = "INCIDENT_ACTION_TEMPLATES_SET_FILTER",
    INCIDENT_ACTION_TEMPLATES_REQUEST = "INCIDENT_ACTION_TEMPLATES_REQUEST",
    INCIDENT_ACTION_TEMPLATES_REQUEST_FAILURE = "INCIDENT_ACTION_TEMPLATES_REQUEST_FAILURE",
    INCIDENT_ACTION_TEMPLATES_REQUEST_SUCCESS = "INCIDENT_ACTION_TEMPLATES_REQUEST_SUCCESS",
    INCIDENT_ACTION_TEMPLATE_SET = "INCIDENT_ACTION_TEMPLATE_SET",
    INCIDENT_ACTION_TEMPLATE_REQUEST = "INCIDENT_ACTION_TEMPLATE_REQUEST",
    INCIDENT_ACTION_TEMPLATE_REQUEST_SUCCESS = "INCIDENT_ACTION_TEMPLATE_REQUEST_SUCCESS",
    INCIDENT_ACTION_TEMPLATE_REQUEST_FAILURE = "INCIDENT_ACTION_TEMPLATE_REQUEST_FAILURE",
    INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST = "INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST",
    INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_SUCCESS = "INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_SUCCESS",
    INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_FAILURE = "INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_FAILURE",
    INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST = "INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST",
    INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_SUCCESS = "INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_SUCCESS",
    INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_FAILURE = "INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_FAILURE",
    INCIDENT_ACTION_TEMPLATE_ADD_CHECKLIST_TEMPLATE_ITEM = "INCIDENT_ACTION_TEMPLATE_ADD_CHECKLIST_TEMPLATE_ITEM",
    INCIDENT_ACTION_TEMPLATE_MOVE_CHECKLIST_TEMPLATE_ITEM = "INCIDENT_ACTION_TEMPLATE_MOVE_CHECKLIST_TEMPLATE_ITEM",
    INCIDENT_ACTION_TEMPLATE_REMOVE_CHECKLIST_TEMPLATE_ITEM = "INCIDENT_ACTION_TEMPLATE_REMOVE_CHECKLIST_TEMPLATE_ITEM",
    INCIDENT_ACTION_TEMPLATE_SET_CHECKLIST_TEMPLATE_ITEM = "INCIDENT_ACTION_TEMPLATE_SET_CHECKLIST_TEMPLATE_ITEM",
    INCIDENT_ACTION_TEMPLATE_SET_OBSOLETE = "INCIDENT_ACTION_TEMPLATE_SET_OBSOLETE"
    ;

    /*
export function incidentActionTemplatesRefresh() {
    return {
        type: INCIDENT_ACTION_TEMPLATES_REFRESH
    }
}
*/

export function incidentActionTemplatesReset() {
    return {
        type: INCIDENT_ACTION_TEMPLATES_RESET
    };
}

export function incidentActionTemplatesSetFilter(filter) {
    return {
        type: INCIDENT_ACTION_TEMPLATES_SET_FILTER, 
        filter
    }
}

export function incidentActionTemplatesRequest() {
    return {
        type: INCIDENT_ACTION_TEMPLATES_REQUEST
    };
}
    
export function incidentActionTemplatesRequestSuccess(filter, incidentActionTemplates) {
    return {
        type: INCIDENT_ACTION_TEMPLATES_REQUEST_SUCCESS,
        filter,
        incidentActionTemplates
    };
}

export function incidentActionTemplatesRequestFailure(error) {
    return {
        type: INCIDENT_ACTION_TEMPLATES_REQUEST_FAILURE,
        error
    };
}

export function incidentActionTemplateSet(incidentActionTemplate) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_SET,
        incidentActionTemplate
    };
}

export function incidentActionTemplateSetObsolete(incidentActionTemplate) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_SET_OBSOLETE,
        incidentActionTemplate
    };
}

export function incidentActionTemplateRequest(incidentActionTemplateId) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_REQUEST,
        incidentActionTemplateId
    };
}

export function incidentActionTemplateRequestSuccess(incidentActionTemplate, incidentCategoryTypes) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_REQUEST_SUCCESS,
        incidentActionTemplate,
        incidentCategoryTypes
    };
}

export function incidentActionTemplateRequestFailure(error) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_REQUEST_FAILURE,
        error
    };
}

export function incidentActionTemplateCreateRequest(venueId, groupId) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST,
        venueId, 
        groupId
    };
}

export function incidentActionTemplateCreateRequestSuccess(incidentActionTemplate, incidentCategoryTypes) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_SUCCESS,
        incidentActionTemplate,
        incidentCategoryTypes
    };
}

export function incidentActionTemplateCreateRequestFailure(error) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST_FAILURE,
        error
    };
}

export function incidentActionTemplateUpsertRequest() {
    return {
        type: INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST
    };
}

export function incidentActionTemplateUpsertRequestSuccess(incidentActionTemplate) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_SUCCESS,
        incidentActionTemplate
    };
}

export function incidentActionTemplateUpsertRequestFailure(error) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_FAILURE,
        error
    };
}

export function incidentActionTemplateAddChecklistTemplateItem(checklistItemType, parentId, position) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_ADD_CHECKLIST_TEMPLATE_ITEM,
        checklistItemType,
        parentId, 
        position
    }
}

export function incidentActionRemoveChecklistTemplateItem(checklistTemplateItem) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_REMOVE_CHECKLIST_TEMPLATE_ITEM,
        checklistTemplateItem
    }
}

export function incidentActionMoveChecklistTemplateItem(checklistTemplateItem, parentId, position) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_MOVE_CHECKLIST_TEMPLATE_ITEM,
        checklistTemplateItem, 
        parentId, 
        position
    }
}

export function incidentActionSetChecklistTemplateItem(checklistTemplateItem) {
    return {
        type: INCIDENT_ACTION_TEMPLATE_SET_CHECKLIST_TEMPLATE_ITEM,
        checklistTemplateItem
    }
}
