import React, { Component } from 'react';
import { Incident as IncidentDTO, IncidentOptions as IncidentOptionsDTO, IncidentFlagType } from "../../../common/dto/AusComply.dtos";
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import SwipeContainer from '../../layout/SwipeContainer';
import { commonLogic } from '../../../common/logic/common';
import View from '../../common/View';
import PageMessage from '../../common/PageMessage';
import CheckListItem from '../../checklists/checklist/CheckListItem';
import { checklistLogic } from '../../../common/logic/checklistLogic';


export interface IIncidentWizardChecklistsProps {
    incident: IncidentDTO;
    incidentOptions: IncidentOptionsDTO;
    onUpdateIncident: Function;
    onSwipedRight?: Function;
    onSwipedLeft?: Function;
    isUploading: boolean;
    onUploadAttachment: Function;
}

export default class IncidentWizardChecklists extends Component<IIncidentWizardChecklistsProps, any> {
    constructor(props: IIncidentWizardChecklistsProps) {
        super(props)
        this.state = {
        };
    }

    componentDidMount() {
        try {

        } catch (err) {
            commonLogic.trackException(err, "IncidentWizardChecklists.componentDidMount", {})
        }
    }

    onChecklistItemUpdateByIndex(checkListItem: AusComplyDtos.ChecklistItem, index: number, checklistIndex: number) {
        let incident = { ...this.props.incident };
        if (checklistIndex < incident.checklists.length) {
            let checklist = incident.checklists[checklistIndex];
            if (index < checklist.checkListDisplayItems.length) {
                if (checklist.checkListDisplayItems[index].item.checklistItemId == checkListItem.checklistItemId) {
                    checklist.checkListDisplayItems[index].item = { ...checkListItem };
                    //checklist.checkListDisplayItems[index].isRequired = checklistLogic.validateChecklistDisplayItemIsRequired( checklist.checkListDisplayItems[index]);
                }
                // check all requirements again
                checklist.checkListDisplayItems.forEach(checkListDisplayItem => {
                    checkListDisplayItem.isRequired = checklistLogic.validateChecklistDisplayItemIsRequired(checkListDisplayItem);
                });
                checklist.checkListDisplayItems = checklistLogic.validateChecklistDisplayItemsShown(checklist.checkListDisplayItems);
                this.props.onUpdateIncident(incident);
            }            
        }
    }

    renderDisplayItem(checkListDisplayItem: AusComplyDtos.CheckListDisplayItem, index: number, isReadonly: boolean, checklistIndex: number) {
        // find the template
        if (checkListDisplayItem.isTemplateMissing) {
            return (<View key={"checklistitem-" + index.toString()}>
                <PageMessage text={"Item template is missing"}></PageMessage>
            </View>);
        }

        if (!checkListDisplayItem.template || !checkListDisplayItem.item) {
            return (<View key={"checklistitem-" + index.toString()}></View>);
        }

        let key = "checklistitem-" + index.toString();
        if (checkListDisplayItem.item.checklistItemId) {
            key = key + "_" + checkListDisplayItem.item.checklistItemId.toString();
        }
        if (checkListDisplayItem.template.checklistTemplateItemId) {
            key = key + "_" + checkListDisplayItem.template.checklistTemplateItemId.toString();
        }

        return (
            <CheckListItem key={key}
                checkListDisplayItem={checkListDisplayItem}
                checklistUserSelections={this.props.incidentOptions.checklistUserSelections}
                onUploadAttachment={this.props.onUploadAttachment}
                onUpdate={(i) => this.onChecklistItemUpdateByIndex(i, index, checklistIndex)}
                isReadonly={isReadonly}
                isUploading={this.props.isUploading} />
        )
    }

    render() {

        let checklists;
        if (this.props.incident.checklists) {
            checklists = this.props.incident.checklists.map((checklist, index) => {
                return <CardContainer
                    key={"checklist" + checklist.checklistTemplateId.toString()}
                    title={checklist.name}>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        {checklist.checkListDisplayItems.map((item, itemIndex) => {
                                            return <div key={"checklistitem" + item.template.checklistTemplateItemId}>
                                                {this.renderDisplayItem(item, itemIndex, item.item.readonly, index)}
                                            </div>;
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardContainer>

            });
        }

        //  return this.renderDisplayItem(item, index, this.props.isReadonly);

        return (
            <SwipeContainer onSwipedLeft={this.props.onSwipedLeft} onSwipedRight={this.props.onSwipedRight}>
                {checklists}
            </SwipeContainer>
        );
    }
}