import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { KeyName } from '../../common/dto/common';
import MoreCommands from '../common/MoreCommands';

export interface ICardRowProps {
    commands?: KeyName[];
    onCommand?: Function;
    onClick?: Function;
    isObsolete?: boolean;
    isDanger?: boolean;
    isWarning?: boolean;
    isInfo?: boolean;
    isSuccess?: boolean;
    style?: any;
    cardContentStyle?: any;
    classes: any;
    theme: any;
}

class CardRow extends React.Component<ICardRowProps, any> {
    constructor(props: ICardRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command);
        }
    }

    onClick(){
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        const { classes } = this.props;
        let className = classes.cardIncidentPending;
        if (this.props.isObsolete) {
            className = classes.cardObsolete;
        } else if (this.props.isDanger) {
            className = classes.cardIncidentRejected;
        } else if (this.props.isWarning) {
            className = classes.cardIncidentDraft;
        } else if (this.props.isInfo) {
            className = classes.cardIncidentCurrent;
        } else if (this.props.isSuccess) {
            className = classes.cardIncidentApproved;
        }


        return (
            <Card style={{ marginTop: '6px', marginBottom: '6px', position: 'relative', ...this.props.style }} className={className} onClick={this.onClick}>
                <CardContent style={this.props.cardContentStyle}>
                    <Box p={0} display="flex" flexDirection={'row'}>
                        <Box flexGrow={1} p={0}>
                            {this.props.children}
                        </Box>
                        <Box p={0}>
                            {(this.props.commands && this.props.commands.length > 0) && (
                                <MoreCommands
                                    commands={this.props.commands}
                                    onCommand={this.onCommand}
                                    style={{ marginLeft: '20px' }} />
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        );
    }
}
export default withStyles(styles, { withTheme: true })(CardRow);