import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import View from '../../common/View';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle';
import PagedList from '../../common/PagedList';
import TableContainer from '../../layout/TableContainer';
import UserRoleCard from './UserRoleCard';
import UserRoleHeader from './UserRoleHeader';
import UserRoleRow from './UserRoleRow';
import UserRolesFilterSummary from './UserRolesFilterSummary';
import UserRolesFilter from './UserRolesFilter';
import FullPageDialog from '../../layout/FullPageDialog';
import EditUserRoleContainer from '../../../containers/EditUserRoleContainer';
import RemoveUserRoleContainer from '../../../containers/RemoveUserRoleContainer';
import UserViewContainer from '../../../containers/UserViewContainer';

export interface IUserRolesProps {
    isLoading: boolean;
    userRoles: AusComplyDtos.UserRoleSummary[];
    userRoleTypes: AusComplyDtos.UserRoleType[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.UserRoleFilter;
    onRefresh: Function;
    onUserRoleEnableRequest: Function;
    onLoadReferenceData: Function;
    onFilterUpdate: Function;
    history: any;
}

interface IUserRolesState {
    viewUserId: number;
    editUserRoleId: number;
    removeUserRoleId: number;
    filterExpanded: boolean;
}

class UserRoles extends Component<IUserRolesProps, IUserRolesState> {
    constructor(props: IUserRolesProps) {
        super(props)

        this.state = {
            viewUserId: 0,
            editUserRoleId: 0,
            removeUserRoleId: 0,
            filterExpanded: false,
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onCommand = this.onCommand.bind(this);
    }

    componentDidMount() {
        //this.setState({ filter: this.props.filter });
        this.props.onRefresh(this.props.filter, this.props.paging.page);
        this.props.onLoadReferenceData();
    }

    componentDidUpdate(prevProps) {
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.filter, page, this.props.paging.pageSize);
    }

    onCommand(command: string, userRole: AusComplyDtos.UserRoleSummary) {
        switch (command) {
            case "enable":
                this.props.onUserRoleEnableRequest(userRole.userRoleId);
                break;
            case "edit":
                this.setState({ viewUserId: 0, editUserRoleId: userRole.userRoleId, removeUserRoleId: 0 });
                break;
            case "delete":
                this.setState({ viewUserId: 0, editUserRoleId: 0, removeUserRoleId: userRole.userRoleId });
                break;
            case "editUser":
                this.props.history.push('/admin/userrole/' + userRole.userId);
                break;
            case "view":
                this.setState({ viewUserId: userRole.userId, editUserRoleId: 0, removeUserRoleId: 0 });
                break;
            case "close":
                this.setState({ viewUserId: 0, editUserRoleId: 0, removeUserRoleId: 0 });
                break;
            case "refresh":
                this.setState({ viewUserId: 0, editUserRoleId: 0, removeUserRoleId: 0 });
                this.props.onRefresh(this.props.filter, this.props.paging.page);
                break;
            default:
                break;
        }
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onRefresh(this.props.filter, 1, this.props.paging.pageSize);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(userRoleFilter: AusComplyDtos.UserRoleFilter) {
        this.props.onFilterUpdate(userRoleFilter);
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            let userRoleFilter = new AusComplyDtos.UserRoleFilter();
            this.props.onFilterUpdate(userRoleFilter);
            this.props.onRefresh(userRoleFilter, 1, this.props.paging.pageSize);
        });
    }

    render() {
        let cardList;
        let rowList;
        let rows;

        if (this.props.userRoles && this.props.userRoles.length > 0) {
            cardList = this.props.userRoles.map((item, index) => {
                return (
                    <UserRoleCard
                        onCommand={this.onCommand}
                        key={"card-" + item.userRoleId.toString()}
                        userRole={item}
                    />
                );
            });

            rows = this.props.userRoles.map((item, index) => {
                return (
                    <UserRoleRow
                        onCommand={this.onCommand}
                        key={"row-" + item.userRoleId.toString()}
                        userRole={item} />
                );
            });
        }


        rowList = (
            <TableContainer
                header={<UserRoleHeader />}
                rows={rows}
            />
        );

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <UserRolesFilter
                filter={this.props.filter}
                userRoleTypes={this.props.userRoleTypes}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={() => this.filterExpanded(false)}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let userFilter = new AusComplyDtos.UserRoleFilter();
            userFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <UserRolesFilterSummary filter={userFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <UserRolesFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        return (
            <>
                <PageTitle title="User Roles"></PageTitle>
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                />
                <FullPageDialog open={this.state.filterExpanded} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.editUserRoleId != 0}
                    title={"Edit User Role"}
                    onDismissed={() => this.onCommand("close", new AusComplyDtos.UserRoleSummary())}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.editUserRoleId != 0) && (
                        <EditUserRoleContainer
                            userRoleId={this.state.editUserRoleId}
                            onSuccess={() => this.onCommand("refresh", new AusComplyDtos.UserRoleSummary())} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.removeUserRoleId != 0}
                    title={"Remove User Role"}
                    onDismissed={() => this.onCommand("close", new AusComplyDtos.UserRoleSummary())}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.removeUserRoleId != 0) && (
                        <RemoveUserRoleContainer
                            userRoleId={this.state.removeUserRoleId}
                            onSuccess={() => this.onCommand("refresh", new AusComplyDtos.UserRoleSummary())} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.viewUserId != 0}
                    title={"View User"}
                    onDismissed={() => this.onCommand("close", new AusComplyDtos.UserRoleSummary())}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.viewUserId != 0) && (
                        <UserViewContainer
                            userId={this.state.viewUserId}
                            onSuccess={() => this.onCommand("close", new AusComplyDtos.UserRoleSummary())} />
                    )}
                </FullPageDialog>
            </>
        );
    }
}

export default withStyles(styles, { withTheme: true })(UserRoles);