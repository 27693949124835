import { makeActionCreator } from './helpers';

export const
    PROFILE_REQUEST = "PROFILE_REQUEST",
    PROFILE_REQUEST_SUCCESS = "PROFILE_REQUEST_SUCCESS",
    PROFILE_REQUEST_FAILURE = "PROFILE_REQUEST_FAILURE",
    PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST = "PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST",
    PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST_SUCCESS = "PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST_SUCCESS",
    PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST_FAILURE = "PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST_FAILURE",
    PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST = "PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST",
    PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST_SUCCESS = "PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST_SUCCESS",
    PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST_FAILURE = "PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST_FAILURE",
    PROFILE_USER_ROLE_REQUEST = "PROFILE_USER_ROLE_REQUEST",
    PROFILE_USER_ROLE_REQUEST_SUCCESS = "PROFILE_USER_ROLE_REQUEST_SUCCESS",
    PROFILE_USER_ROLE_REQUEST_FAILURE = "PROFILE_USER_ROLE_REQUEST_FAILURE",
    PROFILE_SET_API_SOURCE = "PROFILE_SET_API_SOURCE",
    PROFILE_SET_API_SOURCE_ON_HYRDATE = "PROFILE_SET_API_SOURCE_ON_HYRDATE",
    PROFILE_SET_THEME = "PROFILE_SET_THEME",
    PROFILE_RESET = "PROFILE_RESET",
    PROFILE_UPDATE = "PROFILE_UPDATE",
    PROFILE_SAVE_REQUEST = "PROFILE_SAVE_REQUEST",
    PROFILE_SAVE_REQUEST_SUCCESS = "PROFILE_SAVE_REQUEST_SUCCESS",
    PROFILE_SAVE_REQUEST_FAILURE = "PROFILE_SAVE_REQUEST_FAILURE",
    PROFILE_OTHER_DOCUMENTS_SAVE = "PROFILE_OTHER_DOCUMENTS_SAVE",
    PROFILE_OTHER_DOCUMENTS_SAVE_SUCCESS = "PROFILE_OTHER_DOCUMENTS_SAVE_SUCCESS",
    PROFILE_OTHER_DOCUMENTS_SAVE_FAILURE = "PROFILE_OTHER_DOCUMENTS_SAVE_FAILURE",
    PROFILE_OTHER_DOCUMENT_REMOVE = "PROFILE_OTHER_DOCUMENT_REMOVE",
    PROFILE_OTHER_DOCUMENT_REMOVE_SUCCESS = "PROFILE_OTHER_DOCUMENT_REMOVE_SUCCESS",
    PROFILE_OTHER_DOCUMENT_REMOVE_FAILURE = "PROFILE_OTHER_DOCUMENT_REMOVE_FAILURE",
    PROFILE_COMPLIANCE_DOCUMENTS_SAVE = "PROFILE_COMPLIANCE_DOCUMENTS_SAVE",
    PROFILE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS = "PROFILE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS",
    PROFILE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE = "PROFILE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE",
    PROFILE_COMPLIANCE_DOCUMENT_REMOVE = "PROFILE_COMPLIANCE_DOCUMENT_REMOVE",
    PROFILE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS = "PROFILE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS",
    PROFILE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE = "PROFILE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE",
    PROFILE_SLED_CHECK_REQUEST = "PROFILE_SLED_CHECK_REQUEST",
    PROFILE_SLED_CHECK_REQUEST_SUCCESS = "PROFILE_SLED_CHECK_REQUEST_SUCCESS",
    PROFILE_SLED_CHECK_REQUEST_FAILURE = "PROFILE_SLED_CHECK_REQUEST_FAILURE",
    PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST = "PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST",
    PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS = "PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS",
    PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST_FAILURE = "PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST_FAILURE",
    PROFILE_UPDATE_AVATAR_REQUEST = "PROFILE_UPDATE_AVATAR_REQUEST",
    PROFILE_UPDATE_AVATAR_REQUEST_SUCCESS = "PROFILE_UPDATE_AVATAR_REQUEST_SUCCESS",
    PROFILE_UPDATE_AVATAR_REQUEST_FAILURE = "PROFILE_UPDATE_AVATAR_REQUEST_FAILURE",
    PROFILE_CHANGE_PASSWORD_REQUEST = "PROFILE_CHANGE_PASSWORD_REQUEST",
    PROFILE_CHANGE_PASSWORD_REQUEST_SUCCESS = "PROFILE_CHANGE_PASSWORD_REQUEST_SUCCESS",
    PROFILE_CHANGE_PASSWORD_REQUEST_FAILURE = "PROFILE_CHANGE_PASSWORD_REQUEST_FAILURE",
    PROFILE_DELETE_REQUEST = "PROFILE_DELETE_REQUEST",
    PROFILE_DELETE_REQUEST_SUCCESS = "PROFILE_DELETE_REQUEST_SUCCESS",
    PROFILE_DELETE_REQUEST_FAILURE = "PROFILE_DELETE_REQUEST_FAILURE";


export const profileDeleteRequest = makeActionCreator(PROFILE_DELETE_REQUEST, 'reconfirmedEmail');
export const profileDeleteRequestSuccess = makeActionCreator(PROFILE_DELETE_REQUEST_SUCCESS);
export const profileDeleteRequestFailure = makeActionCreator(PROFILE_DELETE_REQUEST_FAILURE, 'error');

export function profileRequest() {
    return {
        type: PROFILE_REQUEST
    };
}

export function profileRequestSuccess(profile, states, genders, otherDocuments, otherDocumentTypes, complianceDocuments, complianceDocumentTypes, avatar, groupAccessRequests) {
    return {
        type: PROFILE_REQUEST_SUCCESS,
        profile,
        states,
        genders,
        otherDocuments,
        otherDocumentTypes,
        complianceDocuments,
        complianceDocumentTypes,
        avatar, 
        groupAccessRequests
    };
}

export function profileRequestFailure(error) {
    return {
        type: PROFILE_REQUEST_FAILURE,
        error
    };
}

export function profileSetApiSourceDemo() {
    return {
        type: PROFILE_SET_API_SOURCE,
        source: 'DEMO'
    };
}

export function profileSetApiSourceProduction() {
    return {
        type: PROFILE_SET_API_SOURCE,
        source: ''
    };
}

export function profileSetApiSourceDevelopment() {
    return {
        type: PROFILE_SET_API_SOURCE,
        source: 'DEVELOPMENT'
    };
}

export function profileSetApiSourceStaging() {
    return {
        type: PROFILE_SET_API_SOURCE,
        source: 'STAGING'
    };
}

export function profileSetApiSourceOnHydrate() {
    return {
        type: PROFILE_SET_API_SOURCE_ON_HYRDATE
    };
}

export function profileSetTheme(isDark) {
    return {
        type: PROFILE_SET_THEME,
        isDark
    };
}

export function profileReset() {
    return {
        type: PROFILE_RESET
    };
}

export function profileUpdate(profile) {
    return {
        type: PROFILE_UPDATE,
        profile
    };
}

export function profileVenuesAvailableToRequestRequest() {
    return {
        type: PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST
    };
}

export function profileVenuesAvailableToRequestSuccess(venues) {
    return {
        type: PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST_SUCCESS,
        venues
    };
}

export function profileVenuesAvailableToRequestFailure(error) {
    return {
        type: PROFILE_VENUES_AVAILABLE_TO_REQUEST_REQUEST_FAILURE,
        error
    };
}

export function profileSecurityFirmsAvailableToRequestRequest() {
    return {
        type: PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST
    };
}

export function profileSecurityFirmsAvailableToRequestSuccess(securityFirms) {
    return {
        type: PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST_SUCCESS,
        securityFirms
    };
}

export function profileSecurityFirmsAvailableToRequestFailure(error) {
    return {
        type: PROFILE_SECURITY_FIRMS_AVAILABLE_TO_REQUEST_REQUEST_FAILURE,
        error
    };
}

export function profileUserRoleRequest(registerAs, venueIds, securityFirmIds) {
    return {
        type: PROFILE_USER_ROLE_REQUEST,
        registerAs,
        venueIds,
        securityFirmIds
    };
}

export function profileUserRoleRequestSuccess() {
    return {
        type: PROFILE_USER_ROLE_REQUEST_SUCCESS
    };
}

export function profileUserRoleRequestFailure(error) {
    return {
        type: PROFILE_USER_ROLE_REQUEST_FAILURE,
        error
    };
}

export function profileSaveOtherDocuments(documentTypeId, files) {
    return {
        type: PROFILE_OTHER_DOCUMENTS_SAVE,
        documentTypeId,
        files
    };
}

export function profileSaveOtherDocumentsSuccess(userOtherDocuments) {
    return {
        type: PROFILE_OTHER_DOCUMENTS_SAVE_SUCCESS,
        userOtherDocuments
    };
}

export function profileSaveOtherDocumentsFailure(error) {
    return {
        type: PROFILE_OTHER_DOCUMENTS_SAVE_FAILURE,
        error
    };
}

export function profileRemoveOtherDocument(otherDocument) {
    return {
        type: PROFILE_OTHER_DOCUMENT_REMOVE,
        otherDocument
    };
}

export function profileRemoveOtherDocumentSuccess(userOtherDocuments) {
    return {
        type: PROFILE_OTHER_DOCUMENT_REMOVE_SUCCESS,
        userOtherDocuments
    };
}

export function profileRemoveOtherDocumentFailure(error) {
    return {
        type: PROFILE_OTHER_DOCUMENT_REMOVE_FAILURE,
        error
    };
}

export function profileSaveComplianceDocuments(documentTypeId, expiryDate, files) {
    return {
        type: PROFILE_COMPLIANCE_DOCUMENTS_SAVE,
        documentTypeId,
        expiryDate,
        files
    };
}

export function profileSaveComplianceDocumentsSuccess(userComplianceDocuments) {
    return {
        type: PROFILE_COMPLIANCE_DOCUMENTS_SAVE_SUCCESS,
        userComplianceDocuments
    };
}

export function profileSaveComplianceDocumentsFailure(error) {
    return {
        type: PROFILE_COMPLIANCE_DOCUMENTS_SAVE_FAILURE,
        error
    };
}

export function profileRemoveComplianceDocument(complianceDocument) {
    return {
        type: PROFILE_COMPLIANCE_DOCUMENT_REMOVE,
        complianceDocument
    };
}

export function profileRemoveComplianceDocumentSuccess(userComplianceDocuments) {
    return {
        type: PROFILE_COMPLIANCE_DOCUMENT_REMOVE_SUCCESS,
        userComplianceDocuments
    };
}

export function profileRemoveComplianceDocumentFailure(error) {
    return {
        type: PROFILE_COMPLIANCE_DOCUMENT_REMOVE_FAILURE,
        error
    };
}

export function profileSaveRequest(profile) {
    return {
        type: PROFILE_SAVE_REQUEST,
        profile
    };
}

export function profileSaveRequestSuccess(profile) {
    return {
        type: PROFILE_SAVE_REQUEST_SUCCESS,
        profile
    };
}

export function profileSaveRequestFailure(error) {
    return {
        type: PROFILE_SAVE_REQUEST_FAILURE,
        error
    };
}

export function profileSledCheckRequest(profile) {
    return {
        type: PROFILE_SLED_CHECK_REQUEST,
        profile
    };
}

export function profileSledCheckRequestSuccess(profile) {
    return {
        type: PROFILE_SLED_CHECK_REQUEST_SUCCESS,
        profile
    };
}

export function profileSledCheckRequestFailure(error) {
    return {
        type: PROFILE_SLED_CHECK_REQUEST_FAILURE,
        error
    };
}

export function profileResendVerificationEmailRequest() {
    return {
        type: PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST
    };
}

export function profileResendVerificationEmailRequestSuccess() {
    return {
        type: PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST_SUCCESS,

    };
}

export function profileResendVerificationEmailRequestFailure(error) {
    return {
        type: PROFILE_RESEND_VERIFICATION_EMAIL_REQUEST_FAILURE,
        error
    };
}

export function profileUpdateAvatarRequest(file) {
    return {
        type: PROFILE_UPDATE_AVATAR_REQUEST,
        file
    };
}

export function profileUpdateAvatarRequestSuccess(file) {
    return {
        type: PROFILE_UPDATE_AVATAR_REQUEST_SUCCESS,
        file
    };
}

export function profileUpdateAvatarRequestFailure(error) {
    return {
        type: PROFILE_UPDATE_AVATAR_REQUEST_FAILURE,
        error
    };
}

export function profileChangePasswordRequest(userId, oldPassword, newPassword, newPasswordConfirm) {
    return {
        type: PROFILE_CHANGE_PASSWORD_REQUEST,
        userId, 
        oldPassword, 
        newPassword, 
        newPasswordConfirm
    };
}

export function profileChangePasswordRequestSuccess() {
    return {
        type: PROFILE_CHANGE_PASSWORD_REQUEST_SUCCESS
    };
}

export function profileChangePasswordRequestFailure(error) {
    return {
        type: PROFILE_CHANGE_PASSWORD_REQUEST_FAILURE,
        error
    };
}