import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageTitle from '../../common/PageTitle'
import FullPageDialog from '../../layout/FullPageDialog';
import VenueQuickActionsContainer from '../../../containers/VenueQuickActionsContainer';
import DefaultFab from '../../common/DefaultFab';
import SubTitle from '../../common/SubTitle';
import List from '../../common/List';
import View from '../../common/View';
import PagedList from '../../common/PagedList';
import Loading from '../../common/Loading';
import TableContainer from '../../layout/TableContainer';
import IncidentActionTemplateCard from './IncidentActionTemplateCard';
import IncidentActionTemplateHeader from './IncidentActionTemplateHeader';
import IncidentActionTemplateRow from './IncidentActionTemplateRow';
import PageMessage from '../../common/PageMessage';
import IncidentActionTemplateLegend from './IncidentActionTemplateLegend';
import ConfirmDialog from '../../controls/ConfirmDialog';
import IncidentActionTemplateFilterSummary from './IncidentActionTemplateFilterSummary';
import IncidentActionTemplateFilter from './IncidentActionTemplateFilter';
import CheckListItem from '../../checklists/checklist/CheckListItem';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';

export interface IIncidentActionTemplatesProps {
    isLoading: boolean;
    venueId: number;
    groupId: number;
    incidentActionTemplates: AusComplyDtos.IncidentActionTemplate[];
    filter: AusComplyDtos.IncidentActionTemplateFilter;
    onUpdate: Function;
    onSetFilter: Function;
    onSet: Function;
    onReset: Function;
    canEdit: boolean;
    onLoadItem: Function;
    incidentActionTemplate: AusComplyDtos.IncidentActionTemplate;
    isLoadingItem: boolean;
    showFunctionalAreas: boolean,
    history: any;
    location: any;
}

export default class IncidentActionTemplates extends Component<IIncidentActionTemplatesProps, any> {
    constructor(props: IIncidentActionTemplatesProps) {
        super(props)

        this.state = {
            confirmRemove: false,
            confirmUndoRemove: false,
            previewItemId: 0,
            preview: []
        };
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.removeCancel = this.removeCancel.bind(this);
        this.removeConfirmed = this.removeConfirmed.bind(this);
        this.removeUndoConfirmed = this.removeUndoConfirmed.bind(this);
        this.prepareFilter = this.prepareFilter.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onPreviewItem = this.onPreviewItem.bind(this);
    }

    componentDidMount() {
        this.props.onSetFilter(this.prepareFilter(this.props.filter));
        this.props.onReset();
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoadingItem && prevProps.isLoadingItem && this.props.incidentActionTemplate) {
            let preview: AusComplyDtos.CheckListDisplayItem[] = [];
            let offset: number = 0;

            this.props.incidentActionTemplate.checklistTemplateItems.forEach(item => {
                if (item.level === 0) {
                    var display = new AusComplyDtos.CheckListDisplayItem();
                    display.offset = offset;
                    display.template = item;
                    display.item = new AusComplyDtos.ChecklistItem();
                    display.isRequired = item.mandatory;
                    display.show = true;
                    preview.push(display);
                    this.flattenForPreview(preview, item.groupedItems, offset + 1);
                }
            });

            this.setState({
                preview
            });
        }
    }

    prepareFilter(filter: AusComplyDtos.IncidentActionTemplateFilter) {
        let preparedFilter = { ...filter };
        preparedFilter.venueId = this.props.venueId;
        return preparedFilter;
    }

    handlePageSelected(page) {
        let preparedFilter = this.prepareFilter(this.props.filter);
        preparedFilter.paging.page = page;
        this.props.onSetFilter(preparedFilter);
    }

    onCommand(command: string, incidentActionTemplate: AusComplyDtos.IncidentActionTemplate) {
        switch (command) {
            case "add":
                var url = this.props.location.pathname + "/0";
                this.props.history.push(url);
                break;
            case "edit":
                var url = this.props.location.pathname + "/" + incidentActionTemplate.incidentActionTemplateId;
                this.props.history.push(url);
                break;
            case "delete":
                var updated = { ...incidentActionTemplate };
                updated.obsolete = true;
                this.props.onSet(updated);
                this.setState({
                    confirmRemove: true
                });
                break;
            case "undodelete":
                var updated = { ...incidentActionTemplate };
                updated.obsolete = false;
                this.props.onSet(updated);
                this.setState({
                    confirmUndoRemove: true
                });
                break;
            case "preview":
                this.onPreviewItem(incidentActionTemplate.incidentActionTemplateId);
                break;
            default:
                break;
        }
    }

    removeCancel() {
        this.setState({
            confirmRemove: false,
            confirmUndoRemove: false,
            contactToRemove: {}
        });
    }

    removeConfirmed() {
        if (this.props.onUpdate && this.props.onSet) {
            this.setState({
                confirmRemove: false
            }, () => {
                this.props.onUpdate();
            });
        }
    }

    filterExpanded(value: boolean) {
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(filter: AusComplyDtos.IncidentActionTemplateFilter) {
        let preparedFilter = this.prepareFilter(filter);
        preparedFilter.paging.page = 1;
        this.setState({ filterExpanded: false }, () => {
            this.props.onSetFilter(preparedFilter);
        })
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            let filter = new AusComplyDtos.IncidentActionTemplateFilter();
            filter.paging = this.props.filter.paging;
            filter.paging.page = 1;
            this.props.onSetFilter(this.prepareFilter(filter));
        });
    }

    removeUndoConfirmed() {
        if (this.props.onUpdate && this.props.onSet) {
            this.setState({
                confirmUndoRemove: false
            }, () => {
                this.props.onUpdate();
            });
        }
    }

    onPreviewItem(previewItemId: number) {
        this.props.onReset();
        if (previewItemId > 0) {
            this.props.onLoadItem(previewItemId);
        }
        this.setState({ previewItemId });
    }


    flattenForPreview(preview: AusComplyDtos.CheckListDisplayItem[], groupedItems: AusComplyDtos.ChecklistTemplateItem[], offset: number) {
        groupedItems.forEach(item => {
            var display = new AusComplyDtos.CheckListDisplayItem();
            display.offset = offset;
            display.template = item;
            display.item = new AusComplyDtos.ChecklistItem();
            display.isRequired = item.mandatory;
            display.show = true;
            preview.push(display);
            this.flattenForPreview(preview, item.groupedItems, offset + 1);
        });
    }


    render() {
        let cardList;
        let rows;
        let rowList;

        if (this.props.incidentActionTemplates && this.props.incidentActionTemplates.length > 0) {
            cardList = this.props.incidentActionTemplates.map((item, index) => {
                return (
                    <IncidentActionTemplateCard
                        onCommand={this.onCommand}
                        showFunctionalAreas={this.props.showFunctionalAreas}
                        canEdit={true}
                        key={"card-" + item.incidentActionTemplateId.toString()}
                        incidentActionTemplate={item}
                    />
                );
            });
            rows = this.props.incidentActionTemplates.map((item, index) => {
                return (
                    <IncidentActionTemplateRow
                        onCommand={this.onCommand}
                        showFunctionalAreas={this.props.showFunctionalAreas}
                        canEdit={true}
                        key={"row-" + item.incidentActionTemplateId.toString()}
                        incidentActionTemplate={item}
                    />
                );
            });
            rowList = (
                <TableContainer
                    header={<IncidentActionTemplateHeader
                        showFunctionalAreas={this.props.showFunctionalAreas}
                    />}
                    rows={rows}
                />
            );
        }

        let create;
        if (true) {
            create = (
                <TransparentButton onClick={() => this.onCommand("add", new AusComplyDtos.IncidentActionTemplate())} text={"Add Incident Action Template"} />
            );
        }

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <IncidentActionTemplateFilter
                filter={this.props.filter}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let filter = new AusComplyDtos.IncidentActionTemplateFilter();
            filter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentActionTemplateFilterSummary filter={filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentActionTemplateFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        let preview;
        if (this.state.previewItemId > 0 && this.props.isLoadingItem) {
            preview = <Loading />;
        } else if (this.state.previewItemId > 0 && !this.props.isLoadingItem) {
            preview = this.state.preview.map((item, index) => {
                return <CheckListItem key={"incidentActionitem-" + index.toString()}
                    checkListDisplayItem={item}
                    checklistUserSelections={[]}
                    onUploadAttachment={() => { }}
                    onUpdate={(i) => { }}
                    isReadonly={false}
                    isUploading={false} />;
            });

        }

        let title = "Incident Action Templates";
        if (this.props.venueId > 0) {
            title = "Venue Incident Action Templates";
        } else if (this.props.groupId > 0) {
            title = "Group Incident Action Templates";
        } else {
            title = "Incident Action Templates";
        }


        return (<PageLayout
            headerText={title}
            footerLeftContent={create}
        >
            {filterSummary}
            <PagedList
                loading={this.props.isLoading}
                paging={this.props.filter.paging}
                cards={cardList}
                rows={rowList}
                footer={<IncidentActionTemplateLegend />}
                onPageSelected={i => this.handlePageSelected(i)}
            />
            <FullPageDialog
                open={this.state.filterExpanded}
                notFullWidth={true}
                title="Search" onDismissed={() => this.filterExpanded(false)}>
                {filter}
            </FullPageDialog>
            <ConfirmDialog
                title={"Confirm remove"}
                text={"Are you sure you wish to remove this incident action template"}
                show={this.state.confirmRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeConfirmed} />
            <ConfirmDialog
                title={"Confirm undo remove"}
                text={"Are you sure you wish to re-enable this incident action template"}
                show={this.state.confirmUndoRemove}
                onCancel={this.removeCancel}
                onConfirm={this.removeUndoConfirmed} />
            <FullPageDialog
                open={this.state.previewItemId > 0}
                title="Preview" onDismissed={() => this.onPreviewItem(0)}>
                {preview}
            </FullPageDialog>
        </PageLayout>);
    }
}