import React, { Component } from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Incident as IncidentDTO } from "../../../common/dto/AusComply.dtos";
import ControlGroup from '../../controls/ControlGroup';
import TextDisplay from '../../controls/TextDisplay';
import ImagePreview from '../../common/ImagePreview';
import Box from '@material-ui/core/Box';
import Thumbnail from '../../common/Thumbnail';

export interface IIncidentViewPeopleAndGroupsProps {
    incident: IncidentDTO;
    theme: any;
    classes: any;
}

class IncidentViewPeopleAndGroups extends Component<IIncidentViewPeopleAndGroupsProps, any> {
    constructor(props: IIncidentViewPeopleAndGroupsProps) {
        super(props)
        this.state = {
            selectedFilePath: "",
            selectedFileName: ""
        };
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
    }

    onSelectFilePath(filePath, text) {
        this.setState({
            selectedFilePath: filePath,
            selectedFileName: text
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    render() {
        const { classes, theme } = this.props;
        if ((!this.props.incident.incidentPatrons || this.props.incident.incidentPatrons.length == 0) && this.props.incident.numberInvolved == 0 && this.props.incident.involvedDescription == "") {
            return (<></>);
        }
        let group;
        if (this.props.incident.numberInvolved > 0 || (this.props.incident.involvedDescription && this.props.incident.involvedDescription != "")) {
            group = (
                <TextDisplay>
                    {this.props.incident.numberInvolved > 0 && (
                        <>Number of people involved: {this.props.incident.numberInvolved}</>
                    )}
                    {(this.props.incident.numberInvolved > 0 && this.props.incident.involvedDescription && this.props.incident.involvedDescription != "") && (
                        <br />
                    )}
                    {(this.props.incident.involvedDescription && this.props.incident.involvedDescription != "") && (
                        <>Person(s) Descriptions: {this.props.incident.involvedDescription}</>
                    )}
                </TextDisplay>
            );
        }
        let people;
        if (this.props.incident.incidentPatrons && this.props.incident.incidentPatrons.length > 0) {
            people = this.props.incident.incidentPatrons.filter(p => !p.obsolete).map((item, index) => {
                return (
                    <div key={"incident-patron-" + item.incidentPatronId}>
                        {index > 0 && (<br />)}
                        <TextDisplay>
                            <b>{item.name}</b>
                            {item.incidentPatronInvolvement && (
                                <><br />Involvement: {item.incidentPatronInvolvement.name} </>
                            )}
                            {item.gender && (
                                <><br />Gender: {item.gender.name} </>
                            )}
                            {item.incidentPatronAgeRange && (
                                <><br />Age: {item.incidentPatronAgeRange.name} </>
                            )}
                            {item.incidentPatronRacialAppearance && (
                                <><br />Racial Appearance: {item.incidentPatronRacialAppearance.name} </>
                            )}
                            {item.incidentPatronHeight && (
                                <><br />Height Range: {item.incidentPatronHeight.name} </>
                            )}
                            {item.incidentPatronWeight && (
                                <><br />Weight Range: {item.incidentPatronWeight.name} </>
                            )}
                            {item.incidentPatronBuild && (
                                <><br />Build: {item.incidentPatronBuild.name} </>
                            )}
                            {item.clothing && (
                                <><br />Clothing: {item.clothing} </>
                            )}
                            {item.incidentPatronClothingTop && (
                                <><br />Top: {item.incidentPatronClothingTop.name} </>
                            )}
                            {item.incidentPatronClothingTopColour && (
                                <><br />Top Colour: {item.incidentPatronClothingTopColour.name} </>
                            )}
                            {item.incidentPatronClothingBottom && (
                                <><br />Bottom: {item.incidentPatronClothingBottom.name} </>
                            )}
                            {item.incidentPatronClothingBottomColour && (
                                <><br />Bottom Colour: {item.incidentPatronClothingBottomColour.name} </>
                            )}
                            {item.incidentPatronHairColour && (
                                <><br />Hair Colour: {item.incidentPatronHairColour.name} </>
                            )}
                            {item.incidentPatronFacialHair && (
                                <><br />Facial Hair: {item.incidentPatronFacialHair.name} </>
                            )}
                            {item.incidentPatronEyeColour && (
                                <><br />Eye Colour: {item.incidentPatronEyeColour.name} </>
                            )}
                            {item.incidentPatronComplexion && (
                                <><br />Complexion: {item.incidentPatronComplexion.name} </>
                            )}
                            {item.isUnderAgeLocked && (
                                <><br />Content is locked </>
                            )}
                            {!item.isUnderAgeLocked && (
                                <>
                                    {item.email && (
                                        <><br />Email: {item.email} </>
                                    )}
                                    {item.phone && (
                                        <><br />Phone: {item.phone} </>
                                    )}
                                    {item.dob && (
                                        <><br />Date of Birth: {item.dob} </>
                                    )}
                                    {item.address && (
                                        <><br />Address: {item.address} </>
                                    )}
                                    {item.suburb && (
                                        <><br />Suburb: {item.suburb} </>
                                    )}
                                    {item.postcode && (
                                        <><br />Postcode: {item.postcode} </>
                                    )}
                                    {item.state && (
                                        <><br />State: {item.state.displayName} </>
                                    )}
                                    {item.country && (
                                        <><br />Country: {item.country.name} </>
                                    )}
                                </>
                            )}
                            {item.notes && (
                                <><br />Notes: {item.notes} </>
                            )}
                            {item.incidentPatronPhotos.filter(photo => !photo.obsolete).length > 0 && (
                                <br />
                            )}
                            <Box display="flex" flexDirection="row" justifyContent="left" flexWrap="wrap">
                                {item.incidentPatronPhotos.filter(photo => !photo.obsolete).map((photo, photoIndex) => {
                                    return (
                                        <Box p={0} key={"file-box-" + photo.originalFileName + "_" + photoIndex.toString()}>
                                            <Thumbnail
                                                key={"file-" + photo.originalFileName + "_" + photoIndex.toString()}
                                                previewPath={photo.filePreviewPath}
                                                displayPath={photo.fileDisplayPath}
                                                isImage={photo.isImage}
                                                isBlocked={item.isUnderAgeLocked}
                                                text={photo.filePreviewText}
                                                fullText={photo.originalFileName}
                                                onPreview={(path, text) => this.onSelectFilePath(path, text)}
                                                onDownload={path => this.onDownload(path)}
                                            />
                                        </Box>
                                    );
                                })}
                            </Box>
                        </TextDisplay>
                    </div>);
            });
        }
        if (!group && !people) {
            return (<></>);
        }
        return (
            <ControlGroup text="Involved People">
                {group}
                {(group && people) && (
                    <br />
                )}
                {people}
                <ImagePreview filePath={this.state.selectedFilePath} fileName={this.state.selectedFileName} onDismissed={() => this.onSelectFilePath("", "")} />
            </ControlGroup>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IncidentViewPeopleAndGroups);