import React from 'react';
import * as AusComplyDTOs from "../../common/dto/AusComply.dtos";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import FullPageDialog from '../layout/FullPageDialog';
import SignOnOffCard from '../signon/SignOnOffCard';
import SignOnOffRow from '../signon/SignOnOffRow';
import SignOnOffHeader from '../signon/SignOnOffHeader';
import PageMessage from '../common/PageMessage';
import PagedList from '../common/PagedList';
import PrimaryButton from '../controls/PrimaryButton';
import TableContainer from '../layout/TableContainer';
import SignOnRegisterFilter from './SignOnRegisterFilter';
import SignOnRegisterFilterSummary from './SignOnRegisterFilterSummary';
import SignOnOffRegisterPrintContainer from '../../containers/SignOnOffRegisterPrintContainer';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';
import { KeyName } from '../../common/dto/common';
import ResponsiveList from '../common/ResponsiveList';
import TickIcon from '../../resources/TickIcon';
import SignOnRegisterBreakContainer from '../../containers/SignOnRegisterBreakContainer';


export interface ISignOnRegisterProps {
    isLoading: boolean;
    items: AusComplyDTOs.UserSignOnStatus[];
    paging: AusComplyDTOs.Paging;
    hasOpenReportingPeriod: boolean;
    canStartReportingPeriod: boolean;
    isSecurityRole: boolean;
    onRefresh: Function;
    canViewSignOnRegister: boolean;
    filter: AusComplyDTOs.SignOnRegisterFilter;
    onLoadFilter: Function;
    onPrint: Function;
    onBreak: Function;
    canSignOn: boolean;
    showEvents: boolean;
    history: any;
    classes: any;
    theme: any;
}

interface ISignOnOffRegisterState {
    filterExpanded: boolean;
    showAcknowledged: boolean;
    showSupervisor: boolean;
    showAssociatedSecurityFirms: boolean;
    showCurrent: boolean;
    showActuals: boolean;
    filter: AusComplyDTOs.SignOnRegisterFilter;
}

class SignOnOffRegister extends React.Component<ISignOnRegisterProps, ISignOnOffRegisterState> {

    constructor(props: ISignOnRegisterProps) {
        super(props);

        this.handleSignOnClick = this.handleSignOnClick.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.showActualsChanged = this.showActualsChanged.bind(this);
        this.onOptionChanged = this.onOptionChanged.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.onItemCommand = this.onItemCommand.bind(this);
        this.getItemCommands = this.getItemCommands.bind(this);

        this.state = {
            showAcknowledged: false,
            showSupervisor: false,
            showAssociatedSecurityFirms: true,
            showActuals: true,
            showCurrent: true,
            filterExpanded: false,
            filter: new AusComplyDTOs.SignOnRegisterFilter()
        };

    }

    componentDidMount() {
        if (!this.props.isLoading) {
            if (!this.props.filter.options) {
                this.props.onLoadFilter(false);
            } else {
                this.setState({ filter: this.props.filter }, () => {
                    this.props.onRefresh(this.state.filter);
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && this.props.filter != prevProps.filter) {
            this.setState({ filter: this.props.filter });
        }
    }

    onRefresh() {
        this.props.onRefresh(this.state.filter);
    }

    onPrint() {
        this.props.onPrint();
    }

    handleSignOnClick() {
        this.props.history.push('/signonregister');
    }

    showActualsChanged(value) {
        let filter = { ...this.state.filter };
        filter.showActuals = value;
        this.setState({ showActuals: value, filter });
    }

    onOptionChanged(value) {
        let showAssociatedSecurityFirms = false;
        let showCurrent = false;
        if (value.value == "1") {
            showAssociatedSecurityFirms = true;
            showCurrent = true;
        } else if (value.value == "2") {
            showAssociatedSecurityFirms = false;
            showCurrent = true;
        } else {
            showAssociatedSecurityFirms = true;
            showCurrent = false;
        }
        this.setState({
            showAssociatedSecurityFirms: showAssociatedSecurityFirms,
            showCurrent: showCurrent
        }, () => {
            this.props.onRefresh(this.state.filter);
        });
    }

    handlePageSelected(page) {
        let filter = { ... this.state.filter };
        filter.paging.page = page;
        this.setState({ filter }, () => {
            this.props.onRefresh(filter);
        });
    }

    handlePageSizeSelected(pageSize, page) {
        let filter = { ... this.state.filter };
        filter.paging.pageSize = pageSize;
        filter.paging.page = page;
        this.setState({ filter }, () => {
            this.props.onRefresh(filter);
        });
    }

    onSearch() {
        let filter = { ...this.state.filter };
        if (filter.paging) {
            filter.paging.page = 1;
        }
        this.props.onRefresh(filter);
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            this.props.onLoadFilter(true);
        })
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.onSearch();
        }
        this.setState({
            filterExpanded: value
        })
    }

    onUpdated(filter) {
        if (filter) {
            if (filter.paging) {
                filter.paging.page = 1;
            }
            filter.showActuals = this.state.showActuals;
            this.setState({ filter });
        }
    }

    getItemCommands(item: AusComplyDTOs.UserSignOnStatus): KeyName[] {
        let moreCommands: KeyName[] = [];
        if (item.isSignedOn) {
            if (item.isOnBreak) {
                moreCommands.push({ key: "endbreak", name: "End Break" });
            } else {
                moreCommands.push({ key: "startbreak", name: "Start Break" });
            }
            moreCommands.push({ key: "extend", name: "Extend" });
            moreCommands.push({ key: "signOff", name: "Sign off" });

        }
        return moreCommands;
    }

    onItemCommand(command, item: AusComplyDTOs.UserSignOnStatus) {
        switch (command) {
            case "extend":
                this.props.history.push('/signonextend/' + item.signOnRegisterId);
                break;
            case "signOff":
                this.props.history.push('/signoffregister/' + item.signOnRegisterId);
                break;
            case "startbreak":
                this.props.onBreak(item.signOnRegisterId);
                break;
            case "endbreak":
                this.props.onBreak(item.signOnRegisterId);
                break;
        }
    }


    getCommands(): KeyName[] {
        let moreCommands: KeyName[] = [];
        moreCommands.push({ key: "print", name: "Print" });
        return moreCommands;
    }

    onCommand(command) {
        switch (command) {
            case "print":
                this.onPrint();
                break;
            case "refresh":
                this.onRefresh();
                break;
        }
    }

    render() {
        let pageMessage = (<div></div>);

        if (!this.props.isLoading && !this.props.hasOpenReportingPeriod && this.props.canStartReportingPeriod) {
            pageMessage = (
                <PageMessage>
                    <p style={{ textAlign: 'center', padding: '5px' }}>There is no open reporting period for the selected venue, <Link to="/venuereportingperiod">Start reporting period.</Link></p>
                </PageMessage>
            );
        } else if (!this.props.isLoading && !this.props.hasOpenReportingPeriod) {
            pageMessage = (
                <PageMessage>
                    <p style={{ textAlign: 'center', padding: '5px' }}>There is no open reporting period for the selected venue.</p>
                </PageMessage>
            );
        }

        let signOnRegsiterFilter = <SignOnRegisterFilter
            open={this.state.filterExpanded}
            filter={this.state.filter}
            showActuals={this.state.showActuals}
            onUpdateFilter={filter => this.onUpdated(filter)}
            onCancel={() => this.setState({ filterExpanded: false })}
            onReset={() => this.onResetSearch()}
            onSearch={() => this.filterExpanded(false)}
            onShowActualsChanged={value => this.showActualsChanged(value)} />;

        let signOnRegsiterFilterSummary = <SignOnRegisterFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />;

        let footerLeftContent;
        if (this.props.hasOpenReportingPeriod) {
            footerLeftContent = <TransparentButton text={"Sign on"} onClick={() => this.handleSignOnClick()} />
        }

        let headers: string[] = [];
        let columns: string[] = [];
        let widths: number[] = [];
        let cardWidths: number[] = [];

        if (this.state.showAssociatedSecurityFirms) {
            headers = [...headers, "Security Firm"];
            columns = [...columns, "securityFirm"];
            widths = [...widths, 0];
            cardWidths = [...cardWidths, 12];
        }

        if (this.props.showEvents) {
            headers = [...headers,
                "Event"
            ];
            columns = [...columns,
                "venueEvent"
            ];
            widths = [...widths, 0];
            cardWidths = [...cardWidths, 12];
        }

        headers = [...headers,
            "First name",
            "Last name",
            "Type",
            "Guard #"
        ];
        columns = [...columns,
            "givenName",
            "surname",
            "selectedSignOnRegisterTypesDisplay",
            "guardNum"
        ];
        widths = [...widths, 0, 0, 130, 80];
        cardWidths = [...cardWidths, 6, 6, 6, 6];

        if (this.state.showSupervisor) {
            headers = [...headers, "Supervisor"];
            columns = [...columns, "supervisorFullname"];
            widths = [...widths, 0];
            cardWidths = [...cardWidths, 0];
        }
        headers = [
            ...headers,
            this.state.showActuals ? "Rostered/Actual start" : "Rostered start",
            "Duration",
            this.state.showActuals ? "Rostered/Actual sign off" : "Rostered sign off",
            "Breaks"
        ];
        columns = [...columns,
            "rosteredStartFormatted",
            "durationFormatted",
            "rosteredFinishFormatted",
            "breakDisplay"
        ];
        widths = [...widths, 0, 80, 160, 0];
        cardWidths = [...cardWidths, 6, 6, 6, 4];

        if (this.state.showAcknowledged) {
            headers = [...headers, "Ack"];
            columns = [...columns, "isAcknowledged"];
            widths = [...widths, 60];
            cardWidths = [...cardWidths, 2];
        }


        let list = <ResponsiveList
            loading={this.props.isLoading}
            data={this.props.items}
            paging={this.props.paging}
            onPageSelected={(page) => this.handlePageSelected(page)}
            onPageSizeSelected={(page, pageSize) => this.handlePageSizeSelected(pageSize, page)}
            headers={headers}
            columns={columns}
            widths={widths}
            cardWidths={cardWidths}
            getCommands={item => this.getItemCommands(item)}
            onCommand={(command, item) => this.onItemCommand(command, item)}
            isObsolete={item => item.obsolete}
            isWarning={item => item.isOnBreak}
            isWarningLabel='On Break'
            isDanger={item => item.isOverdue}
            isDangerLabel='Overdue'
            canEdit={true}
            onFormatValue={(column, value, data, row) => {
                if (column == "notifications") {
                    return row.notifications.length.toString();
                } else if (column == "isAcknowledged") {
                    value = row.isAcknowledged ? <div style={{ width: '16px' }}><TickIcon /></div> : <></>;
                } else if (column == "rosteredStartFormatted" && this.state.showActuals) {
                    return <span>{value}<br /><b>{row.signOnDateFormated}</b></span>;
                } else if (column == "rosteredFinishFormatted" && row.isOverdue) {
                    return <span>Overdue</span>;
                } else if (column == "rosteredFinishFormatted" && this.state.showActuals && row.signOffDateFormated) {
                    return <span>{value}<br /><b>{row.signOffDateFormated}</b></span>;
                } else if (column == "durationFormatted" && this.state.showActuals) {
                    return <span>{value}<br /><b>{row.actualDurationFormatted}</b></span>;
                } else if (column == "securityFirm" && this.state.showAssociatedSecurityFirms && row.securityFirmAddress != "") {
                    return <span>{value}<br /><i>{row.securityFirmAddress}</i></span>;
                }
                return value;
            }}
        />

        let moreCommands: KeyName[] = this.getCommands();
        return (
            <PageLayout
                headerText={"Sign On Register"}
                footerLeftContent={footerLeftContent}
                moreCommands={moreCommands}
                loading={this.props.isLoading}
                onCommand={this.onCommand}
            >
                {signOnRegsiterFilterSummary}
                {pageMessage}
                {list}
                {signOnRegsiterFilter}
                <SignOnOffRegisterPrintContainer />
                <SignOnRegisterBreakContainer onClose={() => this.onRefresh()} />
            </PageLayout>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SignOnOffRegister);
