import { AusComplyJsonServiceClient } from "./AusComplyJsonServiceClient";
import { from } from 'rxjs';
import "rxjs/add/observable/from";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";

export const printApi = {
    getPrintOptions: (venueId: number, securityFirmId: number) => {
        var client = new AusComplyJsonServiceClient();
        var parameters = new AusComplyDtos.GetPrintOptions();
        parameters.venueId = venueId;
        parameters.securityFirmId = securityFirmId;
        const request = client.get(parameters);
        return from(request);
    },
    printIncidentPdf: (venueId: number, securityFirmId: number, filter: AusComplyDtos.IncidentHistoryFilter, reportType: string) => {
        switch (reportType) {
            case "historydetailedreport":
                var clientDetail = new AusComplyJsonServiceClient();
                var parametersDetail = new AusComplyDtos.GetIncidentHistoryWithDetailsReport();
                parametersDetail.venueId = venueId;
                parametersDetail.securityFirmId = securityFirmId;
                parametersDetail.option = "DOWNLOAD";
                parametersDetail.filter = filter;
                parametersDetail.runInBackground = true;
                const requestDetail = clientDetail.post(parametersDetail);
                return from(requestDetail);
                break;
            case "historydetailedversionsreport":
                var clientDetailsVersions = new AusComplyJsonServiceClient();
                var parametersDetailsVersions = new AusComplyDtos.GetIncidentHistoryWithDetailVersionsReport();
                parametersDetailsVersions.venueId = venueId;
                parametersDetailsVersions.securityFirmId = securityFirmId;
                parametersDetailsVersions.option = "DOWNLOAD";
                parametersDetailsVersions.filter = filter;
                parametersDetailsVersions.runInBackground = true;
                const requestDetailsVersions = clientDetailsVersions.post(parametersDetailsVersions);
                return from(requestDetailsVersions);
                break;
            default:
                var client = new AusComplyJsonServiceClient();
                var parameters = new AusComplyDtos.AnyIncidentHistoryFilterReport();
                parameters.venueId = venueId;
                parameters.securityFirmId = securityFirmId;
                parameters.option = "DOWNLOAD";
                parameters.filter = filter;
                parameters.runInBackground = true;
                const request = client.post(parameters);
                return from(request);
        }

    },
    printIncidentCsv: (venueId: number, securityFirmId: number, filter: AusComplyDtos.IncidentHistoryFilter) => {

        var client = new AusComplyJsonServiceClient();
        var parameters = new AusComplyDtos.AnyIncidentHistoryFilterReport();
        parameters.venueId = venueId;
        parameters.securityFirmId = securityFirmId;
        parameters.option = "EXPORT";
        parameters.filter = filter;
        parameters.runInBackground = true;
        const request = client.post(parameters);
        return from(request);
    },
    getActive: (venueId: number, securityFirmId: number) => {
        var client = new AusComplyJsonServiceClient();
        var parameters = new AusComplyDtos.GetReportGenerationActive();
        parameters.venueId = venueId;
        parameters.securityFirmId = securityFirmId;
        const request = client.get(parameters);
        return from(request);
    },
    downloaded: (venueId: number, securityFirmId: number, reportGenerationId: number) => {
        var client = new AusComplyJsonServiceClient();
        var parameters = new AusComplyDtos.PostReportGenerationDownloaded();
        parameters.venueId = venueId;
        parameters.securityFirmId = securityFirmId;
        parameters.reportGenerationId = reportGenerationId;
        const request = client.post(parameters);
        return from(request);
    },
    dismissed: (venueId: number, securityFirmId: number, reportGenerationId: number) => {
        var client = new AusComplyJsonServiceClient();
        var parameters = new AusComplyDtos.PostReportGenerationDismissed();
        parameters.venueId = venueId;
        parameters.securityFirmId = securityFirmId;
        parameters.reportGenerationId = reportGenerationId;
        const request = client.post(parameters);
        return from(request);
    },
    dismissAll: (venueId: number, securityFirmId: number) => {
        var client = new AusComplyJsonServiceClient();
        var parameters = new AusComplyDtos.PostReportGenerationDismissAll();
        parameters.venueId = venueId;
        parameters.securityFirmId = securityFirmId;
        const request = client.post(parameters);
        return from(request);
    },
    emailIncidentHistoryReport: (venueId: number, securityFirmId: number, filter: AusComplyDtos.IncidentHistoryFilter, to: string[], cc: string[], bcc: string[]) => {
        var client = new AusComplyJsonServiceClient();
        var parameters = new AusComplyDtos.AnyIncidentHistoryFilterReport();
        parameters.venueId = venueId;
        parameters.securityFirmId = securityFirmId;
        parameters.filter = filter;
        parameters.option = "EMAIL";
        parameters.emailRecipients = to;
        parameters.emailCcRecipients = cc;
        parameters.emailBccRecipients = bcc;
        const request = client.post(parameters);
        return from(request);
    },
    incidentReport: (
        venueId: number,
        securityFirmId: number,
        option: string, // DOWNLOAD, EMAIL, EXPORT
        id: number,
        includeVersions: boolean, 
        to: string[], 
        cc: string[], 
        bcc: string[]
        ) => {
        var client = new AusComplyJsonServiceClient();
        var parameters = new AusComplyDtos.GetIncidentReport();
        parameters.venueId = venueId;
        parameters.securityFirmId = securityFirmId;
        parameters.id = id;
        parameters.includeVersions = includeVersions;
        parameters.option = option;
        parameters.emailRecipients = to;
        parameters.emailCcRecipients = cc;
        parameters.emailBccRecipients = bcc;
        parameters.runInBackground = true;
        const request = client.post(parameters);
        return from(request);
    },
    checklistReport: (
        venueId: number,
        securityFirmId: number,
        option: string, // DOWNLOAD, EMAIL, EXPORT
        filter: AusComplyDtos.ChecklistFilter,
        to: string[], 
        cc: string[], 
        bcc: string[]
        ) => {
        var client = new AusComplyJsonServiceClient();
        var parameters = new AusComplyDtos.PostChecklistReport();
        parameters.venueId = venueId;
        parameters.securityFirmId = securityFirmId;
        parameters.filter = filter;
        parameters.option = option;
        parameters.emailRecipients = to;
        parameters.emailCcRecipients = cc;
        parameters.emailBccRecipients = bcc;
        const request = client.post(parameters);
        return from(request);
    },
    signOnOffRegistersReport: (
        venueId: number,
        securityFirmId: number,
        option: string, // DOWNLOAD, EMAIL, EXPORT
        filter: AusComplyDtos.SignOnRegisterFilter,
        to: string[], 
        cc: string[], 
        bcc: string[]
        ) => {
        var client = new AusComplyJsonServiceClient();
        var parameters = new AusComplyDtos.PostSignOnRegistersReport();
        parameters.venueId = venueId;
        parameters.securityFirmId = securityFirmId;
        parameters.filter = filter;
        parameters.option = option;
        parameters.emailRecipients = to;
        parameters.emailCcRecipients = cc;
        parameters.emailBccRecipients = bcc;
        const request = client.post(parameters);
        return from(request);
    },
    incidentCategoryTypeDocument: (
        venueId: number,
        securityFirmId: number,
        option: string, // DOWNLOAD, EMAIL, EXPORT
        id: number,
        incidentCategoryTypeDocumentId: number, 
        to: string[], 
        cc: string[], 
        bcc: string[]
        ) => {
        var client = new AusComplyJsonServiceClient();
        var parameters = new AusComplyDtos.GetIncidentCategoryTypeDocument();
        parameters.venueId = venueId;
        parameters.securityFirmId = securityFirmId;
        parameters.id = id;
        parameters.incidentCategoryTypeDocumentId = incidentCategoryTypeDocumentId;
        parameters.option = option;
        parameters.emailRecipients = to;
        parameters.emailCcRecipients = cc;
        parameters.emailBccRecipients = bcc;
        parameters.runInBackground = true;
        const request = client.post(parameters);
        return from(request);
    },
};
