import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import SectionDivider from '../../common/SectionDivider';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';
import Loading from '../../common/Loading';
import Saving from '../../common/Saving';
import Spacer from '../../common/Spacer';
import View from '../../common/View';
import { MulitSelectOption } from '../../../common/dto/common';
import VerticalCollapsedSpacer from '../../common/VerticalCollapsedSpacer';
import MultiSelectControl from '../../controls/MutiSelectControl';
import ExternalApiConnectionContainer from '../../../containers/ExternalApiConnectionContainer';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import SubTitle from '../../common/SubTitle';
import Box from '@material-ui/core/Box';
import PrimaryButton from '../../controls/PrimaryButton';

export interface IRollerProps {
    venueId: number;
    externalApiConnection: AusComplyDtos.ExternalApiConnection;
    isReady: boolean;
    isLoading: boolean;
    isSaving: boolean;
    rollerConfiguration: AusComplyDtos.RollerConfiguration;
    onLoad: Function;
    onSave: Function;
    onSet: Function;
}

interface IRollerState {
}

export default class Roster extends Component<IRollerProps, IRollerState> {
    constructor(props: IRollerProps) {
        super(props)

        this.state = {
        };
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onBookingValueChanged = this.onBookingValueChanged.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<IRollerProps>, prevState: Readonly<IRollerState>, snapshot?: any): void {
        if (!prevProps.isReady && this.props.isReady) {
            this.props.onLoad();
        }
    }

    getBookingEvents() {
        let current: number[] = [];
        let rollerConfiguration = this.props.rollerConfiguration;
        if (rollerConfiguration && rollerConfiguration.webHooks && rollerConfiguration.webHooks.booking && rollerConfiguration.webHooks.booking.events) {
            current = rollerConfiguration.webHooks.booking.events;
        }
        let options: MulitSelectOption[] = [
            {
                id: 1,
                name: "New booking created",
                isCommon: false,
                selected: current.indexOf(1) > -1,
                info: ""
            },
            {
                id: 2,
                name: "Booking updated",
                isCommon: false,
                selected: current.indexOf(2) > -1,
                info: ""
            }
        ];

        return options;
    }

    getBookingChannelsEvents() {
        let current: number[] = [];
        let rollerConfiguration = this.props.rollerConfiguration;
        if (rollerConfiguration && rollerConfiguration.webHooks && rollerConfiguration.webHooks.booking && rollerConfiguration.webHooks.booking.filter &&
            rollerConfiguration.webHooks.booking.filter.channels
        ) {
            current = rollerConfiguration.webHooks.booking.filter.channels;
        }
        let options: MulitSelectOption[] = [
            {
                id: 0,
                name: "Venue Manager",
                isCommon: false,
                selected: current.indexOf(0) > -1,
                info: ""
            },
            {
                id: 1,
                name: "POS",
                isCommon: false,
                selected: current.indexOf(1) > -1,
                info: ""
            },
            {
                id: 2,
                name: "Online Checkout",
                isCommon: false,
                selected: current.indexOf(2) > -1,
                info: ""
            },
            {
                id: 3,
                name: "Forms",
                isCommon: false,
                selected: current.indexOf(3) > -1,
                info: ""
            },
            {
                id: 4,
                name: "Self Serve Kiosk",
                isCommon: false,
                selected: current.indexOf(4) > -1,
                info: ""
            },
            {
                id: 5,
                name: "Data Import",
                isCommon: false,
                selected: current.indexOf(5) > -1,
                info: ""
            },
            {
                id: 7,
                name: "API",
                isCommon: false,
                selected: current.indexOf(7) > -1,
                info: ""
            }
        ];

        return options;
    }

    getBookingProductTypesEvents() {
        let current: number[] = [];
        let rollerConfiguration = this.props.rollerConfiguration;
        if (rollerConfiguration && rollerConfiguration.webHooks && rollerConfiguration.webHooks.booking && rollerConfiguration.webHooks.booking.filter &&
            rollerConfiguration.webHooks.booking.filter.productTypes
        ) {
            current = rollerConfiguration.webHooks.booking.filter.productTypes;
        }
        let options: MulitSelectOption[] = [
            {
                id: 1,
                name: "Pass",
                isCommon: false,
                selected: current.indexOf(1) > -1,
                info: ""
            },
            {
                id: 2,
                name: "Session Pass",
                isCommon: false,
                selected: current.indexOf(2) > -1,
                info: ""
            },
            {
                id: 3,
                name: "Add On",
                isCommon: false,
                selected: current.indexOf(3) > -1,
                info: ""
            },
            {
                id: 4,
                name: "Stock",
                isCommon: false,
                selected: current.indexOf(4) > -1,
                info: ""
            },
            {
                id: 5,
                name: "Package",
                isCommon: false,
                selected: current.indexOf(5) > -1,
                info: ""
            },
            {
                id: 6,
                name: "Gift Card",
                isCommon: false,
                selected: current.indexOf(6) > -1,
                info: ""
            },
            {
                id: 7,
                name: "Wallet",
                isCommon: false,
                selected: current.indexOf(7) > -1,
                info: ""
            },
            {
                id: 8,
                name: "Membership",
                isCommon: false,
                selected: current.indexOf(8) > -1,
                info: ""
            }
        ];

        return options;
    }


    onValueChanged(fieldName, value) {
        let rollerConfiguration = { ...this.props.rollerConfiguration };
        rollerConfiguration[fieldName] = value;
        this.props.onSet(rollerConfiguration);
    }

    onBookingValueChanged(fieldName, value) {
        let rollerConfiguration = { ...this.props.rollerConfiguration };
        rollerConfiguration.webHooks.booking[fieldName] = value;
        this.props.onSet(rollerConfiguration);
    }

    onBookingFilterValueChanged(fieldName, value) {
        let rollerConfiguration = { ...this.props.rollerConfiguration };
        rollerConfiguration.webHooks.booking.filter[fieldName] = value;
        this.props.onSet(rollerConfiguration);
    }

    render() {
        let self = this;

        let content;
        let booking;
        if (this.props.isReady &&
            this.props.rollerConfiguration &&
            this.props.externalApiConnection &&
            this.props.externalApiConnection.isConnected) {

            if (this.props.rollerConfiguration.enabled) {
                booking = <>
                    <SubTitle text='Booking' />
                    <MultiSelectControl
                        cardTitle={"Events"}
                        wrapControlInCard={true}
                        wrapInControlGroup={false}
                        items={self.getBookingEvents()}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={0}
                        onSelectItemsChanged={items => self.onBookingValueChanged("events", items.map(item => item.id))}
                        canShowAll={true} />
                    <MultiSelectControl
                        cardTitle={"Channels"}
                        wrapControlInCard={true}
                        wrapInControlGroup={false}
                        items={self.getBookingChannelsEvents()}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={0}
                        onSelectItemsChanged={items => self.onBookingFilterValueChanged("channels", items.map(item => item.id))}
                        canShowAll={true} />
                    <MultiSelectControl
                        cardTitle={"Product Types"}
                        wrapControlInCard={true}
                        wrapInControlGroup={false}
                        items={self.getBookingProductTypesEvents()}
                        searchText="Type at least three letters..."
                        minimumCharactersRequired={0}
                        onSelectItemsChanged={items => self.onBookingFilterValueChanged("productTypes", items.map(item => item.id))}
                        canShowAll={true} />
                    <TextControlGroup
                        text={"Specific Product Ids"}
                        defaultValue={self.props.rollerConfiguration.webHooks.booking.filter.productIds.join(",")}
                        onBlur={value => this.onBookingFilterValueChanged("productIds", value.split(',').map((item, index) => Number(item)))}
                    />
                    <TextControlGroup
                        text={"Product Category Match"}
                        defaultValue={self.props.rollerConfiguration.webHooks.booking.filter.productCategory}
                        onBlur={value => this.onBookingFilterValueChanged("productCategory", value)}
                    />
                </>
            }

            content = <CardContainer
                title={"Configuration"}
                isSaving={this.props.isSaving}
                isLoading={this.props.isLoading}
                style={{ paddingTop: 0 }}>
                <CardContent>
                    <CheckboxControlGroup
                        text='Webhooks enabled'
                        defaultValue={this.props.rollerConfiguration.enabled}
                        onChanged={value => this.onValueChanged("enabled", value)}
                    />
                    {booking}
                    <Box display="flex" flexDirection="row" justifyContent="left" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <Box flex={1}>
                        </Box>
                        <Box>
                            <View style={{ paddingRight: '10px' }}>
                                <PrimaryButton text='Save' onClick={() => self.props.onSave(this.props.rollerConfiguration)} />
                            </View>
                        </Box>
                    </Box>
                </CardContent>
            </CardContainer>
        }

        return <PageLayout
            headerText={"Roller Integration"}
            footerLeftContent={<TransparentButton text={"Refresh"} onClick={() => { }} />}
        >
            <ExternalApiConnectionContainer
                entityType={AusComplyDtos.ngtEntityType.Roller}
                venueId={this.props.venueId} />
            <Spacer />
            {content}
        </PageLayout>
    }
}