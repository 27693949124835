import { connect } from 'react-redux' ;
import IncidentPrint from '../components/incidents/IncidentPrint';
import { printOptionsRequest, printHide, printIncidentRequest } from '../common/actions/print';
import { notificationShow } from '../common/actions/notification';

const mapStateToProps = (state) => {
    return {
        venueId: state.user.details.userSession.user.venueId,
        securityFirmId: state.user.details.userSession.user.securityFirmId,
        incidentId: state.editIncident.incident.incidentId,
        isLoading: state.print.isLoading,
        show: state.print.show,
        printOptions: state.print.printOptions,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLoadPrintOptions:() => {
            dispatch(printOptionsRequest())
        },        
        onHide:() => {
            dispatch(printHide())
        },
        onPrint:(option, id, includeVersions, to, cc, bcc) => {
            dispatch(printIncidentRequest(option, id, includeVersions, to, cc, bcc))
        },
        onErrorNotification: (message) => {
            dispatch(notificationShow(message))
        }
    }
}

const IncidentPrintContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentPrint)

export default IncidentPrintContainer