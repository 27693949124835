import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardRow from '../../common/CardRow';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';
import { incidentCategoryTypeLogic } from '../../../common/logic/incidentCategoryTypeLogic';

export interface IIncidentCategoryTypeCardProps {
    incidentCategoryType: AusComplyDtos.IncidentCategoryType;
    canEditNotifications?: boolean;
    onCommand?: Function;
}

export default class IncidentCategoryTypeCard extends React.Component<IIncidentCategoryTypeCardProps, any> {
    constructor(props: IIncidentCategoryTypeCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.incidentCategoryType);
        }
    }

    render() {
        if (!this.props.incidentCategoryType) {
            return (<></>);
        }

        var commands = incidentCategoryTypeLogic.getCommands(this.props.incidentCategoryType, this.props.canEditNotifications);

        let legislatedTick;
        if (this.props.incidentCategoryType.legislated) {
            legislatedTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        } else {
            legislatedTick = (
                <View style={{ width: '16px', height: '16px' }}>
                   
                </View>
            );
        }

        let narrativeTick;
        if (this.props.incidentCategoryType.narrativeRequired) {
            narrativeTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        } else {
            narrativeTick = (
                <View style={{ width: '16px', height: '16px' }}>
                   
                </View>
            );
        }

        let approvalTick;
        if (this.props.incidentCategoryType.allowBulkApproval) {
            approvalTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        } else {
            approvalTick = (
                <View style={{ width: '16px', height: '16px' }}>
                   
                </View>
            );
        }

        let autoApproveTick;
        if (this.props.incidentCategoryType.autoApprove) {
            autoApproveTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        } else {
            autoApproveTick = (
                <View style={{ width: '16px', height: '16px' }}>
                   
                </View>
            );
        }

        let regulatedWalkthrough;
        if (this.props.incidentCategoryType.regulatedWalkthrough) {
            regulatedWalkthrough = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        } else {
            regulatedWalkthrough = (
                <View style={{ width: '16px', height: '16px' }}>
                   
                </View>
            );
        }

        let poiTick;
        if (this.props.incidentCategoryType.requiresPersonDescription) {
            poiTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        } else {
            poiTick = (
                <View style={{ width: '16px', height: '16px' }}>
                   
                </View>
            );
        }

        let contactTracingTick;
        if (this.props.incidentCategoryType.contactTracing) {
            contactTracingTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        } else {
            contactTracingTick = (
                <View style={{ width: '16px', height: '16px' }}>
                   
                </View>
            );
        }

        let notificationTick;
        if (this.props.incidentCategoryType.hasIncidentCategoryTypeNotification) {
            notificationTick = (
                <View style={{ width: '16px', height: '16px' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        } else {
            notificationTick = (
                <View style={{ width: '16px', height: '16px' }}>
                   
                </View>
            );
        }

        return (
            <CardRow
                isDanger={this.props.incidentCategoryType.obsolete}
                isWarning={this.props.incidentCategoryType.hidden}
                isInfo={this.props.incidentCategoryType.isSpecificForVenueOrSecurityFirm}
                commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={9}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography>{this.props.incidentCategoryType.name}</CardTypography>
                    </Grid>
                    <Grid item xs={3}>
                        <CardTitle>Rank</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', paddingRight: '10px' }}>{this.props.incidentCategoryType.rank}</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={6}>
                        <CardTitle>Code</CardTitle>
                        <CardTypography>{this.props.incidentCategoryType.code}</CardTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <CardTitle>State</CardTitle>
                        <CardTypography>{this.props.incidentCategoryType.stateName}</CardTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={6}>
                        <CardTitle>Narrative</CardTitle>
                        {narrativeTick}
                    </Grid>
                    <Grid item xs={6}>
                        <CardTitle>Legislated</CardTitle>
                        {legislatedTick}
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={6}>
                        <CardTitle>Bulk Approval</CardTitle>
                        {approvalTick}
                    </Grid>
                    <Grid item xs={6}>
                        <CardTitle>Auto Approve</CardTitle>
                        {autoApproveTick}
                    </Grid>
                </Grid>
                <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                    <Grid item xs={6}>
                        <CardTitle>POI Required</CardTitle>
                        {poiTick}
                    </Grid>
                    <Grid item xs={6}>
                        <CardTitle>Contact Tracing</CardTitle>
                        {contactTracingTick}
                    </Grid>
                </Grid>
                {(this.props.canEditNotifications && this.props.incidentCategoryType.hasIncidentCategoryTypeNotification) && (
                    <Grid container spacing={1} onClick={() => this.onCommand("edit")}>
                        <Grid item xs={6}>
                            <CardTitle>Notify</CardTitle>
                            {notificationTick}
                        </Grid>
                    <Grid item xs={6}>
                        <CardTitle>Snapshot</CardTitle>
                        {regulatedWalkthrough}
                    </Grid>
                    </Grid>
                )}
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CardTitle>Incident Types</CardTitle>
                        <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{this.props.incidentCategoryType.incidentTypesDisplay}</CardTypography>
                    </Grid>
                </Grid>
                {this.props.incidentCategoryType.industryCategoriesDisplay && (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <CardTitle>Industries</CardTitle>
                            <CardTypography style={{ fontStyle: 'italic', color: '#aaa', whiteSpace: 'pre-wrap' }}>{this.props.incidentCategoryType.industryCategoriesDisplay}</CardTypography>
                        </Grid>
                    </Grid>
                )}
            </CardRow>
        );
    }
}