import React, { Component } from 'react';
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';
import View from '../common/View';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import PageTitle from '../common/PageTitle';
import PagedList from '../common/PagedList';
import TableContainer from '../layout/TableContainer';
import UserRoleCard from './UserRoleCard';
import UserRoleHeader from './UserRoleHeader';
import UserRoleRow from './UserRoleRow';
import UserRolesFilterSummary from './UserRolesFilterSummary';
import UserRolesFilter from './UserRolesFilter';
import FullPageDialog from '../layout/FullPageDialog';
import EditUserRoleContainer from '../../containers/EditUserRoleContainer';
import RemoveUserRoleContainer from '../../containers/RemoveUserRoleContainer';
import UserViewContainer from '../../containers/UserViewContainer';
import VenueQuickActionsContainer from '../../containers/VenueQuickActionsContainer';
import SecondaryFab from '../common/SecondaryFab';
import DefaultFab from '../common/DefaultFab';
import Box from '@material-ui/core/Box';
import PageLayout from '../layout/PageLayout';
import TransparentButton from '../controls/TransparentButton';
import { KeyName } from '../../common/dto/common';


export interface IUserRolesProps {
    groupId?: number;
    venueId?: number;
    securityFirmId?: number;
    isLoading: boolean;
    userRoles: AusComplyDtos.UserRoleSummary[];
    userRoleTypes: AusComplyDtos.UserRoleType[];
    canAdminEdit?: boolean;
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.UserRoleFilter;
    onLoadReferenceData: Function;
    onRefresh: Function;
    onUserRoleEnableRequest: Function;
    onExport: Function;
    onFilterUpdate: Function;
    history: any;
    classes: any;
    theme: any;
}

interface IUserRolesState {
    viewUserId: number;
    editUserRoleId: number;
    removeUserRoleId: number;
    filterExpanded: boolean;
}

class UserRoles extends Component<IUserRolesProps, IUserRolesState> {
    constructor(props: IUserRolesProps) {
        super(props)

        this.state = {
            viewUserId: 0,
            editUserRoleId: 0,
            removeUserRoleId: 0,
            filterExpanded: false,
        };
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.onCommand = this.onCommand.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdateFilter = this.onUpdateFilter.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.prepareFilter = this.prepareFilter.bind(this);
        this.onExport = this.onExport.bind(this);
        this.onCreate = this.onCreate.bind(this);
    }

    componentDidMount() {

        let filter = { ...this.props.filter };
        let paging = { ...this.props.paging };
        if (this.props.groupId) {
            if (filter.forGroupId != this.props.groupId) {
                filter = new AusComplyDtos.UserRoleFilter();
                filter.forGroupId = this.props.groupId;
                paging.page = 1;
                this.props.onFilterUpdate(filter);
            }
        } else if (this.props.venueId) {
            if (filter.forVenueId != this.props.venueId) {
                filter = new AusComplyDtos.UserRoleFilter();
                filter.forVenueId = this.props.venueId;
                paging.page = 1;
                this.props.onFilterUpdate(filter);
            }
        } else if (this.props.securityFirmId) {
            if (filter.forSecurityFirmId != this.props.securityFirmId) {
                filter = new AusComplyDtos.UserRoleFilter();
                filter.forSecurityFirmId = this.props.securityFirmId;
                paging.page = 1;
                this.props.onFilterUpdate(filter);
            }
        } else {
            if (filter.forVenueId || filter.forSecurityFirmId) {
                filter = new AusComplyDtos.UserRoleFilter();
                paging.page = 1;
                this.props.onFilterUpdate(filter);
            }
        }

        this.props.onLoadReferenceData(filter.forVenueId, filter.forSecurityFirmId);
        this.props.onRefresh(filter, paging.page);
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading) {
            let filter = { ...this.props.filter };
            let paging = { ...this.props.paging };
            if (this.props.groupId) {
                if (filter.forGroupId != this.props.groupId) {
                    filter = new AusComplyDtos.UserRoleFilter();
                    filter.forGroupId = this.props.groupId;
                    paging.page = 1;
                    this.props.onFilterUpdate(filter);
                    this.props.onRefresh(filter, paging.page);
                }
            } else if (this.props.venueId) {
                if (filter.forVenueId != this.props.venueId) {
                    filter = new AusComplyDtos.UserRoleFilter();
                    filter.forVenueId = this.props.venueId;
                    paging.page = 1;
                    this.props.onFilterUpdate(filter);
                    this.props.onRefresh(filter, paging.page);
                }
            } else if (this.props.securityFirmId) {
                if (filter.forSecurityFirmId != this.props.securityFirmId) {
                    filter = new AusComplyDtos.UserRoleFilter();
                    filter.forSecurityFirmId = this.props.securityFirmId;
                    paging.page = 1;
                    this.props.onFilterUpdate(filter);
                    this.props.onRefresh(filter, paging.page);
                }
            } else {
                if (filter.forVenueId || filter.forSecurityFirmId) {
                    filter = new AusComplyDtos.UserRoleFilter();
                    paging.page = 1;
                    this.props.onFilterUpdate(filter);
                    this.props.onRefresh(filter, paging.page);
                }
            }
        }
    }

    prepareFilter(filter: AusComplyDtos.UserRoleFilter) {
        let preparedFilter = { ...filter };
        if (this.props.groupId) {
            preparedFilter.forGroupId = this.props.groupId;
        } else if (this.props.venueId) {
            preparedFilter.forVenueId = this.props.venueId;
        } else if (this.props.securityFirmId) {
            preparedFilter.forSecurityFirmId = this.props.securityFirmId;
        }
        return preparedFilter;
    }

    handlePageSelected(page) {
        this.props.onRefresh(this.props.filter, page, this.props.paging.pageSize);
    }

    getCommands(): KeyName[] {
        let moreCommands: KeyName[] = [];
        moreCommands.push({ key: "export", name: "Export" });
        return moreCommands;
    }

    onCommand(command: string, userRole: AusComplyDtos.UserRoleSummary) {
        switch (command) {
            case "enable":
                this.props.onUserRoleEnableRequest(userRole.userRoleId);
                break;
            case "edit":
                this.setState({ viewUserId: 0, editUserRoleId: userRole.userRoleId, removeUserRoleId: 0 });
                break;
            case "delete":
                this.setState({ viewUserId: 0, editUserRoleId: 0, removeUserRoleId: userRole.userRoleId });
                break;
            case "editUser":
                if (this.props.groupId) {
                    this.props.history.push('/groups/' + this.props.groupId + '/user/' + userRole.userId);
                } else if (this.props.venueId) {
                    this.props.history.push('/venue/user/' + userRole.userId);
                } else if (this.props.securityFirmId) {
                    this.props.history.push('/securityfirm/user/' + userRole.userId);
                } else {
                    this.props.history.push('/user/' + userRole.userId);
                }
                break;
            case "admin":
                this.props.history.push('/admin/user/' + userRole.userId);
                break;
            case "view":
                this.setState({ viewUserId: userRole.userId, editUserRoleId: 0, removeUserRoleId: 0 });
                break;
            case "close":
                this.setState({ viewUserId: 0, editUserRoleId: 0, removeUserRoleId: 0 });
                break;
            case "refresh":
                this.setState({ viewUserId: 0, editUserRoleId: 0, removeUserRoleId: 0 });
                this.props.onRefresh(this.props.filter, this.props.paging.page);
                break;
            case "export":
                this.onExport();
                break;
            default:
                break;
        }

    }

    onCreate() {
        this.props.history.push('/register/user');
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.props.onRefresh(this.props.filter, 1, this.props.paging.pageSize);
        }
        this.setState({
            filterExpanded: value
        })
    }

    onUpdateFilter(userRoleFilter: AusComplyDtos.UserRoleFilter) {
        this.props.onFilterUpdate(this.prepareFilter(userRoleFilter));
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            let userRoleFilter = new AusComplyDtos.UserRoleFilter();
            this.props.onFilterUpdate(this.prepareFilter(userRoleFilter));
            this.props.onRefresh(this.prepareFilter(userRoleFilter), 1, this.props.paging.pageSize);
        });
    }

    onExport() {
        if (this.props.onExport) {
            this.props.onExport();
        }
    }

    render() {
        let cardList;
        let rowList;
        let rows;
        const { classes } = this.props;

        if (this.props.userRoles && this.props.userRoles.length > 0) {
            cardList = this.props.userRoles.map((item, index) => {
                return (
                    <UserRoleCard
                        onCommand={this.onCommand}
                        canAdminEdit={this.props.canAdminEdit}
                        key={"card-" + item.userRoleId.toString()}
                        userRole={item}
                    />
                );
            });

            rows = this.props.userRoles.map((item, index) => {
                return (
                    <UserRoleRow
                        onCommand={this.onCommand}
                        canAdminEdit={this.props.canAdminEdit}
                        key={"row-" + item.userRoleId.toString()}
                        userRole={item} />
                );
            });
        }


        rowList = (
            <TableContainer
                header={<UserRoleHeader />}
                rows={rows}
            />
        );

        let filter;
        let filterSummary;
        if (this.state.filterExpanded) {
            filter = <UserRolesFilter
                filter={this.props.filter}
                userRoleTypes={this.props.userRoleTypes}
                onUpdateFilter={filter => this.onUpdateFilter(filter)}
                onSearch={() => this.filterExpanded(false)}
                onReset={() => this.onResetSearch()} />;
        } else if (this.props.isLoading) {
            let userFilter = new AusComplyDtos.UserRoleFilter();
            userFilter.display = "Searching...";
            filterSummary = <View style={{ marginBottom: 10 }}>
                <UserRolesFilterSummary filter={userFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else {
            filterSummary = <View style={{ marginBottom: 10 }}>
                <UserRolesFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        let title = "User Roles";
        if (this.props.groupId) {
            title = "Group " + title;
            title = "";
        } else if (this.props.venueId) {
            title = "Venue " + title;
        } else if (this.props.securityFirmId) {
            title = "Security Firm " + title;
        }

        let secondaryFab;
        if (this.props.groupId ||this.props.venueId || this.props.securityFirmId) {
            secondaryFab = (
                <SecondaryFab isExtended={true} onClick={() => this.onExport()}>
                    Export All User Roles
                </SecondaryFab>
            );
        }


        let create = (
            <DefaultFab isExtended={true} onClick={() => this.onCreate()}>
                Register User
            </DefaultFab>
        );

        return (
            <PageLayout
                headerText={title}
                loading={this.props.isLoading}
                moreCommands={this.getCommands()}
                onCommand={this.onCommand}
                footerLeftContent={<TransparentButton text={"Register User"} onClick={this.onCreate} />}
            >
                {filterSummary}
                <PagedList
                    loading={this.props.isLoading}
                    paging={this.props.paging}
                    cards={cardList}
                    rows={rowList}
                    onPageSelected={i => this.handlePageSelected(i)}
                    footer={
                        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentPending}></div>
                                <div className={classes.colorSwatchText}>Active</div>
                            </Box>
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentDeleted}></div>
                                <div className={classes.colorSwatchText}>Removed</div>
                            </Box>
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentCurrent}></div>
                                <div className={classes.colorSwatchText}>Logged in</div>
                            </Box>
                        </Box>
                    }
                />
                <FullPageDialog
                    open={this.state.filterExpanded}
                    notFullWidth={true} title="Search" onDismissed={() => this.filterExpanded(false)}>
                    {filter}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.editUserRoleId != 0}
                    title={"Edit User Role"}
                    notFullWidth={true}
                    onDismissed={() => this.onCommand("close", new AusComplyDtos.UserRoleSummary())}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.editUserRoleId != 0) && (
                        <EditUserRoleContainer
                            userRoleId={this.state.editUserRoleId}
                            onSuccess={() => this.onCommand("refresh", new AusComplyDtos.UserRoleSummary())} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.removeUserRoleId != 0}
                    title={"Remove User Role"}
                    notFullWidth={true}
                    onDismissed={() => this.onCommand("close", new AusComplyDtos.UserRoleSummary())}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.removeUserRoleId != 0) && (
                        <RemoveUserRoleContainer
                            userRoleId={this.state.removeUserRoleId}
                            onSuccess={() => this.onCommand("refresh", new AusComplyDtos.UserRoleSummary())} />
                    )}
                </FullPageDialog>
                <FullPageDialog
                    open={this.state.viewUserId != 0}
                    title={"View User"}
                    notFullWidth={true}
                    onDismissed={() => this.onCommand("close", new AusComplyDtos.UserRoleSummary())}
                    style={{ paddingTop: '0', paddingBottom: '80px' }}>
                    {(this.state.viewUserId != 0) && (
                        <UserViewContainer
                            userId={this.state.viewUserId}
                            onSuccess={() => this.onCommand("close", new AusComplyDtos.UserRoleSummary())} />
                    )}
                </FullPageDialog>
            </PageLayout>
        );
    }
}

export default withStyles(styles, { withTheme: true })(UserRoles);