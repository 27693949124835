import React, { Component } from 'react';
import { styles } from '../../../../styles';
import { withStyles } from '@material-ui/core/styles';
import { IncidentPatron as IncidentPatronDTO, IncidentOptions as IncidentOptionsDTO } from "../../../../common/dto/AusComply.dtos";
import TextControlGroup from '../../../controls/TextControlGroup';
import TextAreaControlGroup from '../../../controls/TextAreaControlGroup';
import Grid from '@material-ui/core/Grid';
import LockIcon from '@material-ui/icons/Lock';
import IncidentPatronAttachments from "./IncidentPatronAttachments"
import FormWarningMessage from '../../../alerts/FormWarningMessage';
import RadioChipControlGroup from '../../../controls/RadioChipControlGroup';
import SimpleDateControlGroup from '../../../controls/SimpleDateControlGroup';
import Box from '@material-ui/core/Box';
import BadgeWrapper from '../../../common/BadgeWrapper';
import IncidentPatronRequiredChipSelection from './IncidentPatronRequiredChipSelection';

export interface IIncidentPatronGeneralNotesProps {
    incidentPatron: IncidentPatronDTO;
    incidentOptions: IncidentOptionsDTO;
    isUploading: boolean;
    onUpdated: Function;
    onUploadPatronFiles: Function;
    contactTracing: boolean;
    theme: any;
    classes: any;
}

class IncidentPatronGeneralNotes extends Component<IIncidentPatronGeneralNotesProps, any> {
    constructor(props: IIncidentPatronGeneralNotesProps) {
        super(props)
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onAttachmentsUpdated = this.onAttachmentsUpdated.bind(this);
    }

    onSelectionChanged(fieldName: string, value: any) {
        let patron = { ...this.props.incidentPatron };
        patron[fieldName] = value;
        this.props.onUpdated(patron);
    }

    onAttachmentsUpdated(patron: IncidentPatronDTO) {
        this.props.onUpdated(patron);
    }

    render() {
        const { classes } = this.props;
        let patron = this.props.incidentPatron;
        let underAgeLockedWarning;
        if (patron.isUnderAgeLocked) {
            underAgeLockedWarning = (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormWarningMessage><LockIcon style={{ fontSize: '1em' }} />&nbsp;Some content is locked as this patron is underage</FormWarningMessage>
                    </Grid>
                </Grid >
            );
        }
        //let phone;
        //if(this.props.contactTracing) {
        //    phone = ();
        //}
        let patronGenderWidthStyle = (!patron.genderId || patron.genderId == 0) ? { width: '100%', marginBottom: '6px' } : { width: 'auto' };
        let patronAgeWidthStyle = (!patron.incidentPatronAgeRangeId || patron.incidentPatronAgeRangeId == 0) ? { width: '100%', marginBottom: '6px' } : { width: 'auto' };
        let patronAppearanceWidthStyle = (!patron.incidentPatronRacialAppearanceId || patron.incidentPatronRacialAppearanceId == 0) ? { width: '100%', marginBottom: '6px' } : { width: 'auto' };
        let patronInvolvementWidthStyle = (!patron.incidentPatronInvolvementId || patron.incidentPatronInvolvementId == 0) ? { width: '100%', marginBottom: '6px' } : { width: 'auto' };
        return (
            <>
                <TextControlGroup text="Name"
                    defaultValue={patron.name}
                    onBlur={(value) => this.onSelectionChanged("name", value)}
                    labelAbove={true} />
                <TextControlGroup text="Phone"
                    defaultValue={patron.phone}
                    onChange={(value) => this.onSelectionChanged("phone", value)}
                    labelAbove={true} />
                <TextControlGroup text="Email"
                    defaultValue={patron.email}
                    onChange={(value) => this.onSelectionChanged("email", value)}
                    labelAbove={true} />
                <SimpleDateControlGroup text="Date of Birth"
                            date={patron.dobDate}
                            readonly={patron.isUnderAgeLocked}
                            onChange={(value) => this.onSelectionChanged("dobDate", value)}
                            labelAbove={true} />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <label style={{ color: 'rgb(138, 138, 138)', textTransform: 'uppercase', marginTop: '10px', display: 'block' }}>Description</label>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box display="flex" flexDirection="row" flexWrap="wrap" p={0} m={0} style={{ marginTop: '10px' }}>
                    <IncidentPatronRequiredChipSelection 
                        title={'The patron involved is'} 
                        name={'gender'}
                        selected={patron.genderId} 
                        onChanged={(value) => this.onSelectionChanged('genderId', value)} 
                        list={this.props.incidentOptions.incidentPatronGenders} 
                        required={true} 
                        labelAbove={true} 
                        theme={this.props.theme} />
                    <IncidentPatronRequiredChipSelection 
                        title={'aged'} 
                        name={'age'}
                        selected={patron.incidentPatronAgeRangeId} 
                        onChanged={(value) => this.onSelectionChanged('incidentPatronAgeRangeId', value)} 
                        list={this.props.incidentOptions.incidentPatronAgeRanges} 
                        required={true} 
                        labelAbove={true} 
                        theme={this.props.theme} />
                    <IncidentPatronRequiredChipSelection 
                        title={'appearing'} 
                        name={'racial'}
                        selected={patron.incidentPatronRacialAppearanceId} 
                        onChanged={(value) => this.onSelectionChanged('incidentPatronRacialAppearanceId', value)} 
                        list={this.props.incidentOptions.incidentPatronRacialAppearances} 
                        required={true} 
                        labelAbove={true} 
                        theme={this.props.theme} />
                    <Box style={patronInvolvementWidthStyle}><p style={{ margin: '6px 6px 0 0 ' }}>was involved as a</p></Box>
                    <Box style={patronInvolvementWidthStyle} p={0}>
                        <RadioChipControlGroup
                            chipWidth={"120px"}
                            name="involvement"
                            selected={patron.incidentPatronInvolvementId}
                            onChanged={(value) => this.onSelectionChanged("incidentPatronInvolvementId", value)}
                            list={this.props.incidentOptions.incidentPatronInvolvements}
                            labelAbove={true} />

                    </Box>
                </Box>
                <TextAreaControlGroup text="Notes"
                    defaultValue={patron.notes}
                    onBlur={value => this.onSelectionChanged("notes", value)}
                    labelAbove={true}
                    defaultRows={2} />

                <IncidentPatronAttachments incidentPatron={patron} onUpdate={this.onAttachmentsUpdated}
                    isUploading={this.props.isUploading}
                 onUploadPatronFiles={this.props.onUploadPatronFiles} />
            </>);
    }
}

export default withStyles(styles, { withTheme: true })(IncidentPatronGeneralNotes);