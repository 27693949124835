import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { CheckListDisplayItem } from '../../../common/dto/AusComply.dtos';
import SubTitle from '../../common/SubTitle';
import View from '../../common/View';
import CheckListItemGroup from './checklistitems/CheckListItemGroup';
import CheckListItemInformation from './checklistitems/CheckListItemInformation';
import CheckListItemTextField from './checklistitems/CheckListItemTextField';
import CheckListItemCheckbox from './checklistitems/CheckListItemCheckbox';
import CheckListItemDate from './checklistitems/CheckListItemDate';
import CheckListItemQuestion from './checklistitems/CheckListItemQuestion';
import CheckListItemMultipleChoice from './checklistitems/CheckListItemMultipleChoice';
import CheckListItemSelection from './checklistitems/CheckListItemSelection';
import CheckListItemStaffSingleSelection from './checklistitems/CheckListItemStaffSingleSelection';
import CheckListItemStaffMultipleSelection from './checklistitems/CheckListItemStaffMultipleSelection';

export interface ICheckListItemProps {
    checkListDisplayItem: CheckListDisplayItem;
    checklistUserSelections: AusComplyDtos.ChecklistUserSelection[];
    onUploadAttachment: Function;
    isUploading: boolean;
    isReadonly?: boolean;
    onUpdate?: Function;
}

interface ICheckListItemState {
}

export default class CheckListItem extends Component<ICheckListItemProps, ICheckListItemState> {
    constructor(props: ICheckListItemProps) {
        super(props)

        this.state = {
        }
        this.onUpdate = this.onUpdate.bind(this);
    }

    onUpdate(checkListItem: AusComplyDtos.ChecklistItem) {
        if (this.props.onUpdate) {
            try {
                if (checkListItem.value) {
                    checkListItem.value = JSON.stringify(checkListItem.value);
                }
            } catch (error) {
                //console. error(checkListItem.value)
            }
            this.props.onUpdate({ ...checkListItem });
        }
    }

    render() {
        // prepare the item
        let checkListDisplayItem = { ...this.props.checkListDisplayItem };
        try {
            if (checkListDisplayItem.item.value) {
                checkListDisplayItem.item.value = JSON.parse(checkListDisplayItem.item.value);
            }
        } catch (error) {
        }

        if (!checkListDisplayItem.show) return <>{checkListDisplayItem.show}</>;

        let style = { marginLeft: (checkListDisplayItem.offset * 25).toString() + 'px' }

        switch (checkListDisplayItem.template.checklistItemType) {
            
            case AusComplyDtos.ChecklistItemType.Group:
                return <View style={style} key={"view_" + checkListDisplayItem.template.checklistTemplateItemId.toString()}>
                    <CheckListItemGroup key={checkListDisplayItem.template.checklistTemplateItemId.toString()} checkListDisplayItem={checkListDisplayItem} /></View>;
            case AusComplyDtos.ChecklistItemType.Information:
                return <View style={style} key={"view_" + checkListDisplayItem.template.checklistTemplateItemId.toString()}>
                    <CheckListItemInformation key={checkListDisplayItem.template.checklistTemplateItemId.toString()} checkListDisplayItem={checkListDisplayItem}/></View>;
            case AusComplyDtos.ChecklistItemType.TextBox:
                return <View style={style} key={"view_" + checkListDisplayItem.template.checklistTemplateItemId.toString()}>
                    <CheckListItemTextField key={checkListDisplayItem.template.checklistTemplateItemId.toString()} checkListDisplayItem={checkListDisplayItem} onUpdate={this.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} isReadonly={this.props.isReadonly} /></View>;
            case AusComplyDtos.ChecklistItemType.Checkbox:
                return <View style={style} key={"view_" + checkListDisplayItem.template.checklistTemplateItemId.toString()}>
                    <CheckListItemCheckbox key={checkListDisplayItem.template.checklistTemplateItemId.toString()} checkListDisplayItem={checkListDisplayItem} onUpdate={this.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} isReadonly={this.props.isReadonly} /></View>;
            case AusComplyDtos.ChecklistItemType.DateTime:
                return <View style={style} key={"view_" + checkListDisplayItem.template.checklistTemplateItemId.toString()}>
                    <CheckListItemDate key={checkListDisplayItem.template.checklistTemplateItemId.toString()} checkListDisplayItem={checkListDisplayItem} onUpdate={this.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} isReadonly={this.props.isReadonly} /></View>;
            case AusComplyDtos.ChecklistItemType.Question:
                return <View style={style} key={"view_" + checkListDisplayItem.template.checklistTemplateItemId.toString()}>
                    <CheckListItemQuestion key={checkListDisplayItem.template.checklistTemplateItemId.toString()} checkListDisplayItem={checkListDisplayItem} onUpdate={this.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} isReadonly={this.props.isReadonly} /></View>;
            case AusComplyDtos.ChecklistItemType.Selection:
                return <View style={style} key={"view_" + checkListDisplayItem.template.checklistTemplateItemId.toString()}>
                    <CheckListItemSelection key={checkListDisplayItem.template.checklistTemplateItemId.toString()} checkListDisplayItem={checkListDisplayItem} onUpdate={this.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} isReadonly={this.props.isReadonly} /></View>;
            case AusComplyDtos.ChecklistItemType.MultipleChoice:
                return <View style={style} key={"view_" + checkListDisplayItem.template.checklistTemplateItemId.toString()}>
                    <CheckListItemMultipleChoice key={checkListDisplayItem.template.checklistTemplateItemId.toString()} checkListDisplayItem={checkListDisplayItem} onUpdate={this.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} isReadonly={this.props.isReadonly} /></View>;           
            case AusComplyDtos.ChecklistItemType.StaffSingleSelection:
                return <View style={style} key={"view_" + checkListDisplayItem.template.checklistTemplateItemId.toString()}>
                    <CheckListItemStaffSingleSelection key={checkListDisplayItem.template.checklistTemplateItemId.toString()} checkListDisplayItem={checkListDisplayItem} checklistUserSelections={this.props.checklistUserSelections} onUpdate={this.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} isReadonly={this.props.isReadonly} /></View>;
            case AusComplyDtos.ChecklistItemType.StaffMultipleSelection:
                return <View style={style} key={"view_" + checkListDisplayItem.template.checklistTemplateItemId.toString()}>
                    <CheckListItemStaffMultipleSelection key={checkListDisplayItem.template.checklistTemplateItemId.toString()} checkListDisplayItem={checkListDisplayItem} checklistUserSelections={this.props.checklistUserSelections} onUpdate={this.onUpdate} onUploadAttachment={this.props.onUploadAttachment} isUploading={this.props.isUploading} isReadonly={this.props.isReadonly} /></View>;
            default:
                return <View style={style} key={"view_" + checkListDisplayItem.template.checklistTemplateItemId.toString()}>
                    <SubTitle key={checkListDisplayItem.template.checklistTemplateItemId.toString()} text={this.props.checkListDisplayItem.template.name} /></View>;
        }

    }
}