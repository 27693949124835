import update from "immutability-helper";
import { LocalStorage } from '../../utilities/storage';
import * as userActions from "../actions/userLogin";

import {
    ADMIN_USER_IMPERSONATE_SUCCESS
} from '../actions/admin';

import {
    PROFILE_UPDATE_AVATAR_REQUEST_SUCCESS
} from '../actions/profile';

const initialState = {
    token: "",
    isLoading: false,
    isLoggedIn: false,
    details: {
        userSession: {
            user: {
                email: "",
                hasAcceptedTerms: true
            }
        },
        securityFirmIsSelected: false,
        venueIsSelected: false,
        isAndHasNothingSelected: false,
        userSignOnSummary: {

        },
        avatar: {
            isImage: false
        }
    },
    resetPasswordIsValid: false,
    resetPasswordValidating: false,
    resetPasswordSaving: false,
    resetPasswordError: "",
    isConfirming: false,
    confirmationError: "",
    lastActionDate: new Date(),
    lastActionEnabled: false,
    isLoadingLoggedInUsers: false,
    loggedInUsers: [],
    resetMobile: "",
    resetCode: 0,
    resetLoading: false,
    isCodeValid: false,
    clearedVenueMessageId: "",
    isBeta: false
};

function clearVenueMessageId(state) {
    let newState = update(state, {
        clearedVenueMessageId: { $set: state.details.userSession.user.showVenueMessageId }
    });
    return newState;
}

function loadingUser(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setResetCode(state, code) {
    let isCodeValid = state.isCodeValid || false;
    if (state.resetCode !== code) {
        isCodeValid = false;
    }
    let newState = update(state, {
        resetCode: { $set: code },
        isCodeValid: { $set: isCodeValid }
    });
    return newState;
}

function setResetMobile(state, mobile) {
    let newState = update(state, {
        resetMobile: { $set: mobile }
    });
    return newState;
}

function setResetLoading(state, loading) {
    let newState = update(state, {
        resetLoading: { $set: loading }
    });
    return newState;
}

function setIsCodeValid(state, isCodeValid) {
    let newState = update(state, {
        isCodeValid: { $set: isCodeValid }
    });
    return newState;
}

function loadUserDetails(state, details) {
    if (!details) {
        details = {
            userSession: {
                user: {
                    email: state.details.userSession.user.email,
                    userID: state.details.userSession.user.userID,
                    hasAcceptedTerms: true
                }
            },
            userSignOnSummary: {}
        };
    }
    let newState = update(state, {
        details: { $set: details }
    });
    return loadingUser(newState, false);
}

function login(state, bearerToken, meta, refreshToken) {
    let newState = update(state, {
        isLoggedIn: { $set: true },
        bearerToken: { $set: bearerToken },
        meta: { $set: meta },
        refreshToken: { $set: refreshToken },
        lastActionDate: { $set: new Date() }
    });
    LocalStorage.set("bearerToken", bearerToken);
    LocalStorage.set("refreshToken", refreshToken);
    LocalStorage.set("meta", JSON.stringify(meta));
    LocalStorage.set("betaMode", state.isBeta ? "TRUE" : "FALSE");
    return loadingUser(newState, false);
}

function logout(state) {
    LocalStorage.clear("bearerToken");
    LocalStorage.clear("bearerToken");
    let newState = update(state, {
        isLoggedIn: { $set: false },
        bearerToken: { $set: "" },
        meta: { $set: {} },
        lastActionDate: { $set: new Date() }
    });
    newState = update(newState, {
        details: {
            $set: {
                userSession: {
                    user: {
                        email: state.details.userSession.user.email,
                        userID: state.details.userSession.user.userID,
                        hasAcceptedTerms: true
                    }
                },
                userSignOnSummary: {}
            }
        }
    });
    LocalStorage.clear("bearerToken");
    LocalStorage.clear("refreshToken");
    LocalStorage.clear("meta");
    return newState;
}

function isAndHasNothingSelected(state, value) {
    var newValue = value;
    if (value) {
        if(!state.details.userSession.venueId || !state.details.userSession.securityFirmId)
        {
            newValue = true;
        } else {
            newValue = false;
        }
    }
    let newState = update(state, {
        details: { isAndHasNothingSelected: { $set: newValue } }
    });
    return newState;
}

function venueIsSelected(state, value) {
    var newValue = value;
    if (value) {
        if(state.details.userSession.venueId && state.details.userSession.venueId > 0)
        {
            newValue = true;
        } else {
            newValue = false;
        }
    }
    let newState = update(state, {
        details: { venueIsSelected: { $set: newValue } }
    });
    return newState;
}

function securityFirmIsSelected(state, value) {
    var newValue = value;
    if (value) {
        if(state.details.userSession.securityFirmId && state.details.userSession.securityFirmId > 0)
        {
            newValue = true;
        } else {
            newValue = false;
        }
    }
    let newState = update(state, {
        details: { 
            securityFirmIsSelected: { $set: newValue }
        }
    });
    return newState;
}

function setPasswordError(state, error) {
    let newState = update(state, {
        resetPasswordError: { $set: error }
    });
    return newState;
}

function resetPasswordSaving(state, saving) {
    let newState = update(state, {
        resetPasswordSaving: { $set: saving }
    });
    return newState;
}

function resetPassword(state, valid, validating, saving, error) {
    let newState = update(state, {
        resetPasswordIsValid: { $set: valid },
        resetPasswordValidating: { $set: validating },
        resetPasswordSaving: { $set: saving },
        resetPasswordError: { $set: error }
    });
    return newState;
}

function confirmEmail(state, isConfirming, confirmationError) {
    let newState = update(state, {
        isConfirming: { $set: isConfirming },
        confirmationError: { $set: confirmationError }
    });
    return newState;
}

function setAvatar(state, avatar) {
    let newState = update(state, {
        details: {
            avatar: { $set: avatar }
        }
    });
    return newState;
}

function loadingLoggedInUsers(state, isLoadingLoggedInUsers, loggedInUsers) {
    let newState = update(state, {
        isLoadingLoggedInUsers: { $set: isLoadingLoggedInUsers },
        loggedInUsers: { $set: loggedInUsers }
    });
    return newState;
}

function setBetaMode(state, isBeta) {
    let newState = update(state, {
        isBeta: { $set: isBeta }
    });
    LocalStorage.set("betaMode", isBeta ? "TRUE" : "FALSE");
    return newState;
}

export default function userSession(state = initialState, action) {
    switch (action.type) {
        case userActions.USER_RESET:
            return loadingUser(logout(state), false);
        case userActions.USER_LOGOUT_REQUEST:
            return loadingUser(setResetMobile(state, ""), true);
        case userActions.USER_LOGOUT_REQUEST_SUCCESS:
            return loadingUser(state, false);
        case userActions.USER_LOGIN_REQUEST:
            return loadingUser(setResetMobile(state, ""), true);
        case userActions.USER_LOGIN_REQUEST_SUCCESS:
            return login(state, action.bearerToken, action.meta, action.refreshToken);
        case userActions.USER_LOGIN_REQUEST_FAILURE:
            return loadingUser(state, false);
        case userActions.USER_DETAILS_REFRESH:
            return loadingUser(state, true);
        case userActions.USER_DETAILS_REQUEST:
            return loadingUser(state, true);
        case userActions.USER_DETAILS_REQUEST_SUCCESS:
            return isAndHasNothingSelected(loadUserDetails(state, action.details), action.details.userSession.user.groups.length > 0);
        case ADMIN_USER_IMPERSONATE_SUCCESS:
            return loadUserDetails(state, action.details);
        case userActions.USER_DETAILS_REQUEST_FAILURE:
            return loadingUser(state, false);
        case userActions.USER_DETAILS_VENUE_SECURITYFIRM_CHANGE:
            return isAndHasNothingSelected(securityFirmIsSelected(venueIsSelected(state, false),false), false);
        case userActions.USER_DETAILS_VENUE_SECURITYFIRM_CANCEL_CHANGE:
            return isAndHasNothingSelected(securityFirmIsSelected(venueIsSelected(state, true),true), true);
        case userActions.USER_LOG_INTO_VENUE_REQUEST:
            return loadingUser(state, true);
        case userActions.USER_LOG_INTO_VENUE_REQUEST_SUCCESS:
            return loadingUser(state, false);
        case userActions.USER_LOG_INTO_VENUE_REQUEST_FAILURE:
            return loadingUser(state, false);
        case userActions.USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST:
            return resetPassword(state, false, true, false, "");
        case userActions.USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST_FAILURE:
            return resetPassword(state, false, false, false, action.error);
        case userActions.USER_LOGIN_FORGOT_PASSWORD_VALIDATE_REQUEST_SUCCESS:
            return resetPassword(state, action.isValid, false, false, "");
        case userActions.USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST:
            return resetPassword(state, state.resetPasswordIsValid, false, true, "");
        case userActions.USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST_FAILURE:
            return resetPassword(state, state.resetPasswordIsValid, false, false, action.error);
        case userActions.USER_LOGIN_FORGOT_PASSWORD_RESET_REQUEST_SUCCESS:
            return resetPassword(state, state.resetPasswordIsValid, false, false, "");
        case userActions.USER_EMAIL_CONFIRM_REQUEST:
            return confirmEmail(state, true, "");
        case userActions.USER_EMAIL_CONFIRM_REQUEST_SUCCESS:
            return confirmEmail(state, false, "");
        case userActions.USER_EMAIL_CONFIRM_REQUEST_FAILURE:
            return confirmEmail(state, false, action.error);
        case PROFILE_UPDATE_AVATAR_REQUEST_SUCCESS:
            return setAvatar(state, action.file);
        case userActions.USER_ACTIVE:
            return update(state, {
                lastActionDate: { $set: new Date() }
            });
        case userActions.USER_ACTIVE_ENABLED:
            return update(state, {
                lastActionEnabled: { $set: action.enable }
            });
        case userActions.USERS_LOGGED_IN_REQUEST:
            return loadingLoggedInUsers(state, true, []);
        case userActions.USERS_LOGGED_IN_REQUEST_SUCCESS:
            return loadingLoggedInUsers(state, false, action.loggedInUsers);
        case userActions.USERS_LOGGED_IN_REQUEST_FAILURE:
            return loadingLoggedInUsers(state, false, []);

        case userActions.USER_LOGIN_RESET_CODE:
            return setResetCode(setIsCodeValid(state, false), null);
        case userActions.USER_LOGIN_SET_MOBILE:
            return setResetMobile(state, action.mobile);
        case userActions.USER_LOGIN_SET_CODE:
            return setResetCode(state, action.code);

        case userActions.USER_LOGIN_PASSWORD_RESET_CODE_REQUEST:
            return setResetLoading(setResetMobile(setResetCode(state, null), action.mobile), true);
        case userActions.USER_LOGIN_PASSWORD_RESET_CODE_REQUEST_SUCCESS:
            return setResetLoading(state, false);
        case userActions.USER_LOGIN_PASSWORD_RESET_CODE_REQUEST_FAILURE:
            return setResetLoading(state, false);

        case userActions.USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST:
            return setResetLoading(setResetMobile(setResetCode(setIsCodeValid(state, false), action.code), action.mobile), true);
        case userActions.USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST_SUCCESS:
            return setResetLoading(setIsCodeValid(setPasswordError(state,""), action.isValid), false);
        case userActions.USER_LOGIN_VALIDATE_PASSWORD_RESET_CODE_REQUEST_FAILURE:
            return setResetLoading(state, false);

        case userActions.USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST:
            return resetPasswordSaving(setPasswordError(state,""), true);
        case userActions.USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST_SUCCESS:
            return resetPasswordSaving(setIsCodeValid(setResetCode(setResetMobile(state,""), null), false), false);
        case userActions.USER_LOGIN_RESET_PASSWORD_WITH_CODE_REQUEST_FAILURE:
            return resetPasswordSaving(setPasswordError(state, action.error), false);

        case userActions.USER_CLEAR_VENUE_MESSAGE:
            return clearVenueMessageId(state);
        case userActions.BETA_MODE:
            return setBetaMode(state, action.isBeta);

    }

    return state;
}
