import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { MulitSelectOption } from '../../../common/dto/common';
import PrimaryButton from '../../controls/PrimaryButton';
import DefaultButton from '../../controls/DefaultButton';
import LayoutForm from '../../layout/LayoutForm';
import CheckboxControlGroup from '../../controls/CheckboxControlGroup';
import TextControlGroup from '../../controls/TextControlGroup';
import NumericUpDownControlGroup from '../../controls/NumericUpDownControlGroup';
import SelectControlGroup from '../../controls/SelectControlGroup';
import MultiSelectControl from '../../controls/MutiSelectControl';
import CardContainer from '../../common/CardContainer';
import CardContent from '@material-ui/core/CardContent';

export interface IIncidentCategoryTypeProps {
    incidentCategoryType: AusComplyDtos.IncidentCategoryType;
    states: AusComplyDtos.State[];
    isSaving: boolean;
    error: string;
    onCancel: Function;
    onSave: Function;
}

interface IIncidentCategoryTypeState {
    incidentCategoryType: AusComplyDtos.IncidentCategoryType;
    industryCategories: MulitSelectOption[];
    incidentTypes: MulitSelectOption[];
    error: string;
}

export default class IncidentCategoryType extends Component<IIncidentCategoryTypeProps, IIncidentCategoryTypeState> {
    constructor(props: IIncidentCategoryTypeProps) {
        super(props)
        let incidentCategoryType = new AusComplyDtos.IncidentCategoryType();
        incidentCategoryType.industryCategorySelections = [];
        let error = "";
        this.state = {
            incidentCategoryType,
            industryCategories: [],
            incidentTypes: [],
            error
        }
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onIndustryCategoriesChanged = this.onIndustryCategoriesChanged.bind(this);
        this.onIncidentTypesChanged = this.onIncidentTypesChanged.bind(this);
    }

    componentDidMount() {
        let incidentCategoryType = { ...this.props.incidentCategoryType };
        let industryCategories: MulitSelectOption[] = [];
        if (this.props.incidentCategoryType.industryCategorySelections) {
            this.props.incidentCategoryType.industryCategorySelections.forEach((item, index) => {
                let industryCategory = new MulitSelectOption();
                industryCategory.id = item.industryCategoryId;
                industryCategory.name = item.name;
                industryCategory.selected = item.selected;
                industryCategory.isCommon = false;
                industryCategories.push(industryCategory);
            });
        }

        let incidentTypes: MulitSelectOption[] = [];
        if (this.props.incidentCategoryType.incidentTypeSelections) {
            this.props.incidentCategoryType.incidentTypeSelections.forEach((item, index) => {
                let incidentType = new MulitSelectOption();
                incidentType.id = item.incidentTypeId;
                incidentType.name = item.name;
                incidentType.selected = item.selected;
                incidentType.isCommon = false;
                incidentTypes.push(incidentType);
            });
        }

        this.setState({
            incidentCategoryType,
            industryCategories,
            incidentTypes,
            error: ""
        });
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isSaving && prevProps.isSaving) {
            if (this.props.error) {
                this.setState({ error: this.props.error });
            } else {
                this.props.onCancel();
            }
        }
    }

    onSave() {
        let incidentCategoryType = { ...this.state.incidentCategoryType };
        if (incidentCategoryType.industryCategorySelections) {
            incidentCategoryType.industryCategorySelections.forEach((item, index) => {
                item.selected = this.state.industryCategories.filter(s => s.id === item.industryCategoryId && s.selected).length > 0;
            });
        }
        if (incidentCategoryType.incidentTypeSelections) {
            incidentCategoryType.incidentTypeSelections.forEach((item, index) => {
                item.selected = this.state.incidentTypes.filter(s => s.id === item.incidentTypeId && s.selected).length > 0;
            });
        }
        this.props.onSave(incidentCategoryType);
    }

    onCancel() {
        this.props.onCancel();
    }

    onValueChanged(fieldName: string, value: any) {
        let incidentCategoryType = { ...this.state.incidentCategoryType };
        incidentCategoryType[fieldName] = value;
        this.setState({ incidentCategoryType });
    }

    onIndustryCategoriesChanged(value) {
        let industryCategories = [...this.state.industryCategories];
        industryCategories.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            industryCategories
        });
    }

    onIncidentTypesChanged(value) {
        let incidentTypes = [...this.state.incidentTypes];
        incidentTypes.forEach((item, index) => {
            item.selected = value.filter(s => s.id === item.id).length > 0;
        });

        this.setState({
            incidentTypes
        });
    }


    render() {
        let rank = "0";
        if (this.state.incidentCategoryType.rank) {
            rank = this.state.incidentCategoryType.rank.toString();
        }

        return (
            <LayoutForm
                loading={false}
                saving={this.props.isSaving}
                error={this.props.error}
            >
                <form>
                    <TextControlGroup
                        text={"Name"}
                        readonly={this.state.incidentCategoryType.isInUse}
                        defaultValue={this.state.incidentCategoryType.name}
                        onChange={value => this.onValueChanged("name", value)} />
                    <TextControlGroup
                        text={"Code"}
                        maxlength={8}
                        defaultValue={this.state.incidentCategoryType.code}
                        onChange={value => this.onValueChanged("code", value)} />
                    <CardContainer
                        style={{ marginTop: '10px' }}
                        title={"Incident Types"}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <div style={{ marginTop: '-4px' }}>
                                <MultiSelectControl
                                    items={this.state.incidentTypes}
                                    minimumCharactersRequired={3}
                                    canShowAll={true}
                                    isReadonly={false} //lthis.state.incidentCategoryType.isInUse}
                                    searchText={"Type at least three letters..."}
                                    onSelectItemsChanged={this.onIncidentTypesChanged} />
                            </div>
                        </CardContent>
                    </CardContainer>
                    <CardContainer
                        title={"Industries"}>
                        <CardContent style={{ paddingTop: 0 }}>
                            <div style={{ marginTop: '-4px' }}>
                                <MultiSelectControl
                                    items={this.state.industryCategories}
                                    minimumCharactersRequired={3}
                                    canShowAll={true}
                                    searchText={"Type at least three letters..."}
                                    onSelectItemsChanged={this.onIndustryCategoriesChanged} />
                            </div>
                        </CardContent>
                    </CardContainer>
                    <SelectControlGroup
                        text="State"
                        onChange={(value) => this.onValueChanged("stateId", value)}
                        defaultValue={this.state.incidentCategoryType.stateId}>
                        <option value={0} />
                        {this.props.states.map((item, index) => (
                            <option key={item.stateId} value={item.stateId}>{item.displayName}</option>
                        ))}
                    </SelectControlGroup>
                    <CheckboxControlGroup
                        text={"Legislated"}
                        defaultValue={this.state.incidentCategoryType.legislated}
                        onChanged={(value) => this.onValueChanged("legislated", value)} />
                    <CheckboxControlGroup
                        text={"Narrative Required"}
                        defaultValue={this.state.incidentCategoryType.narrativeRequired}
                        onChanged={(value) => this.onValueChanged("narrativeRequired", value)} />
                    <CheckboxControlGroup
                        text={"Allow Bulk Approval"}
                        defaultValue={this.state.incidentCategoryType.allowBulkApproval}
                        onChanged={(value) => this.onValueChanged("allowBulkApproval", value)} />
                    <CheckboxControlGroup
                        text={"Person Description Required"}
                        defaultValue={this.state.incidentCategoryType.requiresPersonDescription}
                        onChanged={(value) => this.onValueChanged("requiresPersonDescription", value)} />
                    <CheckboxControlGroup
                        text={"Add Snapshot"}
                        defaultValue={this.state.incidentCategoryType.regulatedWalkthrough}
                        onChanged={(value) => this.onValueChanged("regulatedWalkthrough", value)} />
                    <CheckboxControlGroup
                        text={"Contact Tracing"}
                        defaultValue={this.state.incidentCategoryType.contactTracing}
                        onChanged={(value) => this.onValueChanged("contactTracing", value)} />
                    <CheckboxControlGroup
                        text={"Auto Approve"}
                        defaultValue={this.props.incidentCategoryType.autoApprove}
                        onChanged={(value) => this.onValueChanged("autoApprove", value)} />
                    <CheckboxControlGroup
                        text={"Date of Birth Required"}
                        defaultValue={this.props.incidentCategoryType.requiresPersonDob}
                        onChanged={(value) => this.onValueChanged("requiresPersonDob", value)} />
                    <TextControlGroup
                        text={"Rank"}
                        numericOnly={true}
                        defaultValue={rank}
                        onChange={value => this.onValueChanged("rank", value)} />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <PrimaryButton text={"Save"} onClick={this.onSave}></PrimaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultButton text={"Cancel"} onClick={this.onCancel}></DefaultButton>
                        </Grid>
                    </Grid>
                </form>
            </LayoutForm >
        );
    }
}