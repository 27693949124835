import { Box, CardContent, Grid, withStyles } from '@material-ui/core';
import React from 'react';
import * as AusComplyDtos from "../../../../common/dto/AusComply.dtos";
import CardContainer from '../../../common/CardContainer';
import CardList from '../../../common/CardList';
import CardRow from '../../../common/CardRow';
import Loading from '../../../common/Loading';
import NoRowsMessage from '../../../common/NoRowsMessage';
import CheckboxControl from '../../../controls/CheckboxControl';
import { styles } from '../../../../styles';
import FacialRecognitionEventsListDrop from './FacialRecognitionEventsListDrop';
import CardTitle from '../../../common/CardTitle';
import CardTypography from '../../../common/CardTypography';
import FacialRecognitionEventsListCardDrag from './FacialRecognitionEventsListCardDrag';
import CardPrint from '../../../common/CardPrint';

export interface IFacialRecognitionEventsListProps {
    loading?: boolean;
    data: AusComplyDtos.FacialRecognitionEvent[];
    isDanger?: Function;
    isDangerLabel: string;
    toggleDanger?: boolean;
    isWarning?: Function;
    isWarningLabel?: string;
    isInfo?: Function;
    isInfoLabel?: string;
    isSuccess?: Function;
    isSuccessLabel?: string;
    isDefaultLabel?: string;
    getCommands: Function;
    onCommand: Function;
    footer?: any;
    classes: any;
    onMoveItem: Function;
}

export interface IFacialRecognitionEventsListState {
    showDanger: boolean;
    data: any[];
}

class FacialRecognitionEventsList extends React.Component<IFacialRecognitionEventsListProps, IFacialRecognitionEventsListState> {
    constructor(props: IFacialRecognitionEventsListProps) {
        super(props);
        this.state = {
            showDanger: false,
            data: []
        }
        this.filter = this.filter.bind(this);
    }

    componentDidMount() {
        this.filter();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data != this.props.data) {
            this.filter()
        }
    }

    orderEvents(data) {
        if (data) {
            return data.sort(function (a, b) {
                return a.processRank - b.processRank;
            });
        }
    }

    filter() {
        if (!this.props.data) {
            this.setState({ data: [] });
            return;
        }
        const data = this.props.data.filter(d =>
            (!this.props.toggleDanger || this.state.showDanger || !this.props.isDanger || !this.props.isDanger(d))
        );
        this.setState({ data });
    }

    render() {
        const { classes } = this.props;
        let content = (<></>);

        if (this.props.loading) {
            content = (<Loading />);
        } else if (this.props.data) {
            let hasSuccess = false;
            let hasDanger = false;
            let hasWarning = false;
            let hasInfo = false;
            const dangerLabel = this.props.isDangerLabel || "Removed";
            let count = 0;

            let filter;
            if (this.props.toggleDanger) {
                filter = (<CardContainer title={"filter"}>
                    <CardContent>
                        <CheckboxControl
                            text={"Show " + dangerLabel.toLocaleLowerCase()}
                            defaultValue={this.state.showDanger}
                            onChanged={value => this.setState({ showDanger: value }, () => { this.filter(); })} />
                    </CardContent>
                </CardContainer>);
            }

            let data = this.state.data;
            count = data.length;

            data = this.orderEvents(data);

            let cards = data.map((item, index) => {
                let isDanger = this.props.isDanger != null ? this.props.isDanger(item) : false;
                let isWarning = this.props.isWarning != null ? this.props.isWarning(item) : false;
                let isInfo = this.props.isInfo != null ? this.props.isInfo(item) : false;
                let isSuccess = this.props.isSuccess != null ? this.props.isSuccess(item) : false;

                if (isDanger) {
                    hasDanger = true;
                }
                if (isWarning) {
                    hasWarning = true;
                }
                if (isInfo) {
                    hasInfo = true;
                }
                if (isSuccess) {
                    hasSuccess = true;
                }

                let commands = this.props.getCommands ? this.props.getCommands(item) : null;

                let cardContent = (<>
                    <FacialRecognitionEventsListCardDrag
                        facialRecognitionEvent={item}
                        index={index}
                        onMove={this.props.onMoveItem}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                {item.facialRecognitionEventType == AusComplyDtos.ngtFacialRecognitionEventType.Watchlist && (<>
                                    <CardTitle>Watchlist</CardTitle>
                                    <CardTypography>{item.watchlistDisplay}</CardTypography>
                                </>)}
                                {item.facialRecognitionEventType == AusComplyDtos.ngtFacialRecognitionEventType.Age && (<>
                                    <CardTitle>Age Range</CardTitle>
                                    <CardTypography>{item.ageFrom}<span>&nbsp;to&nbsp;</span>{item.ageTo}</CardTypography>
                                </>)}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CardTitle>For Camera(s)</CardTitle>
                                <CardTypography>{item.cameraDisplay}</CardTypography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            {item.incidentTypeDisplay !== "" && (
                                <Grid item xs={12} sm={6}>
                                    <CardTitle>Creates an incident type</CardTitle>
                                    <CardTypography>{item.incidentTypeDisplay}</CardTypography>
                                </Grid>
                            )}
                            {item.incidentCategoryTypesDisplay !== "" && (
                                <Grid item xs={12} sm={6}>
                                    <CardTitle>With incident categories</CardTitle>
                                    <CardTypography>{item.incidentCategoryTypesDisplay}</CardTypography>
                                </Grid>
                            )}
                            {item.incidentFlagTypesDisplay !== "" && (
                                <Grid item xs={12} sm={6}>
                                    <CardTitle>With incident flags</CardTitle>
                                    <CardTypography>{item.incidentFlagTypesDisplay}</CardTypography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={1}>
                            {(item.eventNotificationUserRoleTypesDisplay !== undefined && item.eventNotificationUserRoleTypesDisplay !== "") && (
                                <Grid item xs={12} sm={6}>
                                    <CardTitle>Creates notification for role types</CardTitle>
                                    <CardPrint>{item.eventNotificationUserRoleTypesDisplay}</CardPrint>
                                </Grid>
                            )}
                            {(item.notificationTrigger.escalate && item.notificationTrigger.escalateAfterMinutes > 0) && (
                                <Grid item xs={12} sm={6}>
                                    <CardTitle>Escalate after minutes</CardTitle>
                                    <CardTypography>{item.notificationTrigger.escalateAfterMinutes}</CardTypography>
                                </Grid>
                            )}
                        </Grid>
                    </FacialRecognitionEventsListCardDrag>
                </>);

                return (<div key={index}>
                    <FacialRecognitionEventsListDrop position={index} />
                    <CardRow isDanger={isDanger} isWarning={isWarning} isInfo={isInfo} isSuccess={isSuccess}
                        commands={commands} onCommand={(command) => this.props.onCommand(command, item)}>
                        {cardContent}
                    </CardRow>
                </div>);

            });

            let footer = this.props.footer;
            if (!footer && (hasDanger || hasWarning || hasInfo || hasSuccess)) {
                footer = (
                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                        {hasInfo && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentCurrent}></div>
                                <div className={classes.colorSwatchText}>{this.props.isInfoLabel || "Global"}</div>
                            </Box>
                        )}
                        {hasSuccess && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentApproved}></div>
                                <div className={classes.colorSwatchText}>{this.props.isSuccessLabel || "Success"}</div>
                            </Box>
                        )}
                        {hasWarning && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentDraft}></div>
                                <div className={classes.colorSwatchText}>{this.props.isWarningLabel || "Warning"}</div>
                            </Box>
                        )}
                        {hasDanger && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentDeleted}></div>
                                <div className={classes.colorSwatchText}>{dangerLabel}</div>
                            </Box>
                        )}
                        {this.props.isDefaultLabel && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentPending}></div>
                                <div className={classes.colorSwatchText}>{this.props.isDefaultLabel}</div>
                            </Box>
                        )}
                    </Box>);
            }

            if (count === 0) {
                content = (<>
                    {filter}
                    <NoRowsMessage></NoRowsMessage>
                </>);
            } else {
                content = (<>
                    {filter}
                    <div className="paged-list">
                        <div>{cards}</div>
                        {this.props.footer}
                    </div>
                    <FacialRecognitionEventsListDrop position={count} />
                </>);
            }
        }
        return content;
    }
}

export default withStyles(styles, { withTheme: true })(FacialRecognitionEventsList);