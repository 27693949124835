import React from 'react';
import { styles } from '../../styles';
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { withStyles } from '@material-ui/core/styles';
import Paging from './Paging';
import Media from "react-media";
import Loading from '../common/Loading';
import NoRowsMessage from '../common/NoRowsMessage';
import List from './List';
import TableContainer from '../layout/TableContainer';
import TableRow from '../common/TableRow';
import Grid, { GridSize } from '@material-ui/core/Grid';
import CardRow from '../common/CardRow';
import CardTypography from '../common/CardTypography';
import CardTitle from '../common/CardTitle';
import CardContent from '@material-ui/core/CardContent';
import CardContainer from '../common/CardContainer';
import TouchableOpacity from '../common/TouchableOpacity';
import CheckboxControl from "../controls/CheckboxControl";
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import ImagePreview from '../common/ImagePreview';
import Thumbnail from '../common/Thumbnail';

export interface IResponsiveListProps {
    loading?: boolean;
    data: any[];
    headers: string[];
    columns: string[];
    minWidths?: number[];
    alignments?: string[];
    styles?: any[];
    widths?: number[];
    cardWidths?: any[];
    getCommands: Function;
    onCommand: Function;
    onGetLink?: Function;
    onFormatValue?: Function;
    isSuccess?: Function;
    isSuccessLabel?: string;
    isInfo?: Function;
    isInfoLabel?: string;
    isWarning?: Function;
    filter?: any;
    aboveListContent?: any;
    isWarningLabel?: string;
    isDanger?: Function;
    isDangerLabel?: string;
    isDefaultLabel?: string;
    toggleDanger?: boolean;
    toggleSuccess?: boolean;
    isObsolete?: Function;
    toggleObsolete?: boolean;
    isObsoleteLabel?: string;
    noRowsMessage?: string;
    legendExtra?: any;
    canEdit?: boolean;
    pageSize?: number;
    paging?: AusComplyDtos.Paging;
    onPageSelected?: Function;
    onPageSizeSelected?: Function;
    cardsOnly?: boolean;
    footer?: any;
    classes: any;
}

interface IResponsiveListState {
    showSuccess: boolean;
    showDanger: boolean;
    showObsolete: boolean;
    data: any[];
    filePath: string;
}

class ResponsiveList extends React.Component<IResponsiveListProps, IResponsiveListState> {
    constructor(props: IResponsiveListProps) {
        super(props)
        this.state = {
            showSuccess: false,
            showDanger: false,
            showObsolete: false,
            data: [],
            filePath: "",
        }
        this.filter = this.filter.bind(this);
        this.getValue = this.getValue.bind(this);
        this.onSelectFilePath = this.onSelectFilePath.bind(this);
        this.onDownload = this.onDownload.bind(this);
    }

    componentDidMount() {
        this.filter();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data != this.props.data) {
            this.filter();
        }
    }

    onSelectFilePath(filePath) {
        this.setState({
            filePath: filePath
        });
    }

    onDownload(filePath) {
        // fake server request, getting the file url as response
        setTimeout(() => {
            const response = {
                file: filePath,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file);
            // you could also do:
            // window.location.href = response.file;
        }, 100);
    }

    getValue(data: any[], index: number, column: string) {
        if (column.indexOf('.') > -1) {
            const fields = column.split('.');
            let value = data[index];
            if (value != undefined) {
                fields.forEach(field => {
                    if (value != undefined) {
                        value = value[field];
                    }
                });
            }
            // console.log(column, value);
            if (this.props.onFormatValue) {
                value = this.props.onFormatValue(column, value, data, data[index]);
            }
            return value;
        }
        if (this.props.onFormatValue) {
            return this.props.onFormatValue(column, data[index][column], data, data[index]);
        }
        return data[index][column];
    }

    getStyle(index: number) {
        if (this.props.styles) {
            return { whiteSpace: 'normal', ...this.props.styles[index] }
        }
        return { whiteSpace: 'normal' };
    }

    getRowCellStyle(index: number) {
        if (this.props.styles) {
            return { ...this.props.styles[index] }
        }
        return {};
    }

    getCardWidth(index: number) {
        if (this.props.cardWidths) {
            return this.props.cardWidths[index];
        }
        return 12;
    }

    getRow(data: any[], index: number) {
        return data[index];
    }

    filter() {
        if (!this.props.data || !Array.isArray(this.props.data)) {
            this.setState({ data: [] });
            return;
        }
        const data = this.props.data.filter(d =>
            (!this.props.toggleDanger || this.state.showDanger || !this.props.isDanger || !this.props.isDanger(d)) &&
            (!this.props.toggleSuccess || this.state.showSuccess || !this.props.isSuccess || !this.props.isSuccess(d)) &&
            (!this.props.toggleObsolete || this.state.showObsolete || !this.props.isObsolete || !this.props.isObsolete(d))
        );
        this.setState({ data });
    }

    render() {
        const { classes } = this.props;
        let self = this;
        let content = (<></>);
        if (this.props.loading) {
            content = (<Loading />);
        } else if (this.props.data) {
            let cards;
            let rows;
            let hasSuccess = false;
            let hasObsolete = false;
            let hasDanger = false;
            let hasWarning = false;
            let hasInfo = false;
            const dangerLabel = this.props.isDangerLabel || "Removed"
            const successLabel = this.props.isSuccessLabel || "Success"
            const obsoleteLabel = this.props.isObsoleteLabel || "Non-active"

            let filter;
            if (this.props.toggleObsolete || this.props.toggleDanger || this.props.toggleSuccess || this.props.filter) {
                filter = (<CardContainer title={"Filter"}>
                    <CardContent>
                        {this.props.filter}
                        {this.props.toggleSuccess && (<CheckboxControl
                            text={"Show " + successLabel.toLocaleLowerCase()}
                            defaultValue={this.state.showSuccess}
                            onChanged={value => self.setState({ showSuccess: value }, () => { self.filter(); })} />)}
                        {this.props.toggleDanger && (<CheckboxControl
                            text={"Show " + dangerLabel.toLocaleLowerCase()}
                            defaultValue={this.state.showDanger}
                            onChanged={value => self.setState({ showDanger: value }, () => { self.filter(); })} />)}
                        {this.props.toggleObsolete && (<CheckboxControl
                            text={"Show " + obsoleteLabel.toLocaleLowerCase()}
                            defaultValue={this.state.showObsolete}
                            onChanged={value => self.setState({ showObsolete: value }, () => { self.filter(); })} />)}
                    </CardContent>
                </CardContainer>);
            }

            const data = this.state.data;
            const count = data.length;
            const firstColumnIsPreview: boolean = this.props.columns && this.props.columns.length > 0 && this.props.columns[0] === "filePreviewPath";

            cards = data.map((item, index) => {
                let isObsolete = this.props.isObsolete != null ? this.props.isObsolete(item) : false;
                let isDanger = this.props.isDanger != null ? this.props.isDanger(item) : false;
                let isWarning = this.props.isWarning != null ? this.props.isWarning(item) : false;
                let isInfo = this.props.isInfo != null ? this.props.isInfo(item) : false;
                let isSuccess = this.props.isSuccess != null ? this.props.isSuccess(item) : false;
                if (isObsolete) {
                    hasObsolete = true;
                }
                if (isDanger) {
                    hasDanger = true;
                }
                if (isWarning) {
                    hasWarning = true;
                }
                if (isSuccess) {
                    hasSuccess = true;
                }
                if (isInfo) {
                    hasInfo = true;
                }
                let commands = this.props.getCommands ? this.props.getCommands(item) : null;

                if (firstColumnIsPreview) {
                    return (
                        <CardRow
                            style={{ marginLeft: 0 }}
                            isObsolete={isObsolete} isDanger={isDanger} isInfo={isInfo} isWarning={isWarning} isSuccess={isSuccess}
                            commands={commands} onCommand={(command) => this.props.onCommand(command, item, index)}>
                            <Box display="flex" flexDirection="row" justifyContent="left">
                                <Box p={0}>
                                    <Thumbnail
                                        key={"file-" + data[index]["originalFileName"] + "_" + index.toString()}
                                        previewPath={data[index]["filePreviewPath"]}
                                        displayPath={data[index]["fileDisplayPath"]}
                                        isImage={data[index]["isImage"]}
                                        text={""}
                                        hideDownload={true}
                                        small={true}
                                        tiny={false}
                                        onPreview={path => this.onSelectFilePath(path)}
                                    />
                                </Box>
                                <Box flex={1}>
                                    <Grid container spacing={1}
                                        key={"card-" + index.toString()}>
                                        {this.props.columns.filter(f => f !== "filePreviewPath").map((column, columnIndex) => {
                                            let link = "";
                                            let cardWidth = this.getCardWidth(columnIndex) as GridSize;
                                            if (this.props.onGetLink) {
                                                link = this.props.onGetLink(column, this.getRow(data, index));
                                            }
                                            if (link != "") {
                                                return <Grid item xs={cardWidth} key={"row-" + index + "col-" + columnIndex}>
                                                    <CardTitle>{this.props.headers[columnIndex + 1]}</CardTitle>
                                                    <Link className={classes.tableCellLink} to={link}>{this.getValue(data, index, column)}</Link>
                                                </Grid>
                                            }
                                            let value = this.getValue(data, index, column);
                                            let style = this.getStyle(columnIndex);
                                            if (value != undefined && value != "") {
                                                return <Grid item xs={cardWidth} key={"row-" + index + "col-" + columnIndex}>
                                                    <CardTitle isObsolete={isObsolete}>{this.props.headers[columnIndex + 1]}</CardTitle>
                                                    <CardTypography isObsolete={isObsolete} style={style}>{this.getValue(data, index, column)}</CardTypography>
                                                </Grid>
                                            } else if (cardWidth != 12) {
                                                // custom widths supplied, provide a blank to keep formatting
                                                return <Grid item xs={cardWidth} key={"row-" + index + "col-" + columnIndex}>
                                                </Grid>
                                            }
                                        })}
                                    </Grid>
                                </Box>
                            </Box>
                        </CardRow>
                    );
                }

                let hasEdit = false;
                if (commands) {
                    hasEdit = commands.find(c => c.key == "edit") != null;
                }

                return (
                    <CardRow isObsolete={isObsolete} isDanger={isDanger} isInfo={isInfo} isWarning={isWarning} isSuccess={isSuccess}
                        commands={commands} onCommand={(command) => this.props.onCommand(command, item, index)}>
                        <Grid container spacing={1}
                            key={"card-" + index.toString()}>
                            {this.props.columns.map((column, columnIndex) => {
                                let link = "";
                                let cardWidth = this.getCardWidth(columnIndex) as GridSize;
                                if (this.props.onGetLink) {
                                    link = this.props.onGetLink(column, this.getRow(data, index));
                                }
                                if (link != "") {
                                    return <Grid item xs={cardWidth} key={"row-" + index + "col-" + columnIndex}>
                                        {this.props.headers[columnIndex] !== "" ? <CardTitle>{this.props.headers[columnIndex]}</CardTitle> : <></>}
                                        <Link className={classes.tableCellLink} to={link}>{this.getValue(data, index, column)}</Link>
                                    </Grid>
                                }
                                if (column === "filePreviewPath") {
                                    return <Grid item xs={cardWidth} key={"row-" + index + "col-" + columnIndex}>
                                        <Thumbnail
                                            key={"file-" + data[index]["originalFileName"] + "_" + index.toString()}
                                            previewPath={data[index]["filePreviewPath"]}
                                            displayPath={data[index]["fileDisplayPath"]}
                                            isImage={data[index]["isImage"]}
                                            text={""}
                                            hideDownload={true}
                                            small={true}
                                            tiny={true}
                                            onPreview={path => this.onSelectFilePath(path)}
                                        />
                                    </Grid>
                                }
                                let value = this.getValue(data, index, column);
                                let style = this.getStyle(columnIndex);
                                if (hasEdit) {
                                    if (value != undefined && value != "") {
                                        return <Grid item xs={cardWidth} key={"row-" + index + "col-" + columnIndex}>
                                            <TouchableOpacity onPress={() => self.props.onCommand("edit", item, index)} style={{display: 'block', textAlign: 'left', width: '100%'}}>
                                                {this.props.headers[columnIndex] !== "" ? <CardTitle>{this.props.headers[columnIndex]}</CardTitle> : <></>}
                                                <CardTypography isObsolete={isObsolete} style={style}>{this.getValue(data, index, column)}</CardTypography>
                                            </TouchableOpacity>
                                        </Grid>
                                    } else if (cardWidth != 12) {
                                        // custom widths supplied, provide a blank to keep formatting
                                        return <Grid item xs={cardWidth} key={"row-" + index + "col-" + columnIndex}>
                                            <TouchableOpacity onPress={() => self.props.onCommand("edit", item, index)} style={{display: 'block', textAlign: 'left', width: '100%'}}>
                                                <span>&nbsp;</span>
                                            </TouchableOpacity>
                                        </Grid>
                                    }
                                }
                                if (value != undefined && value != "") {
                                    return <Grid item xs={cardWidth} key={"row-" + index + "col-" + columnIndex}>
                                            {this.props.headers[columnIndex] !== "" ? <CardTitle>{this.props.headers[columnIndex]}</CardTitle> : <></>}
                                            <CardTypography isObsolete={isObsolete} style={style}>{this.getValue(data, index, column)}</CardTypography>
                                    </Grid>
                                } else if (cardWidth != 12) {
                                    // custom widths supplied, provide a blank to keep formatting
                                    return <Grid item xs={cardWidth} key={"row-" + index + "col-" + columnIndex}>
                                    </Grid>
                                }
                            })}
                        </Grid>
                    </CardRow>
                );
            });
            rows = data.map((item, index) => {
                let isObsolete = this.props.isObsolete != null ? this.props.isObsolete(item) : false;
                let isDanger = this.props.isDanger != null ? this.props.isDanger(item) : false;
                let isWarning = this.props.isWarning != null ? this.props.isWarning(item) : false;
                let isInfo = this.props.isInfo != null ? this.props.isInfo(item) : false;
                let isSuccess = this.props.isSuccess != null ? this.props.isSuccess(item) : false;
                let commands = this.props.getCommands ? this.props.getCommands(item) : null;
                let hasEdit = false;
                if (commands) {
                    hasEdit = commands.find(c => c.key == "edit") != null;
                }
                return (
                    <TableRow
                        key={"row-" + index.toString()}
                        isObsolete={isObsolete} isDanger={isDanger} isInfo={isInfo} isWarning={isWarning} isSuccess={isSuccess}
                        commands={commands} onCommand={(command) => this.props.onCommand(command, item, index)}>
                        {this.props.columns.map((column, columnIndex) => {
                            let link = "";
                            if (this.props.onGetLink) {
                                link = this.props.onGetLink(column, this.getRow(data, index));
                            }
                            if (column === "filePreviewPath") {
                                return <td key={"row-" + index + "col-" + columnIndex}>
                                    <Thumbnail
                                        key={"file-" + data[index]["originalFileName"] + "_" + index.toString()}
                                        previewPath={data[index]["filePreviewPath"]}
                                        displayPath={data[index]["fileDisplayPath"]}
                                        isImage={data[index]["isImage"]}
                                        text={""}
                                        hideDownload={true}
                                        small={true}
                                        tiny={true}
                                        onDownload={path => this.onDownload(path)}
                                        onPreview={path => this.onSelectFilePath(path)}
                                    />
                                </td>
                            } else if (link != "") {
                                return <td key={"row-" + index + "col-" + columnIndex} className={classes.tableCellLink}>
                                    <p>
                                        <Link className={classes.tableCellLink} to={link}>{this.getValue(data, index, column)}</Link>
                                    </p>
                                </td>
                            } else if (hasEdit) {
                                return <td key={"row-" + index + "col-" + columnIndex} onClick={() => self.props.onCommand("edit", item, index)}>
                                    <p>
                                        {this.getValue(data, index, column)}
                                    </p>
                                </td>
                            }
                            let style = this.getRowCellStyle(columnIndex);
                            return <td key={"row-" + index + "col-" + columnIndex}>
                                <p style={style}>
                                    {this.getValue(data, index, column)}
                                </p>
                            </td>
                        })}
                    </TableRow>
                );
            });
            let recordCount;
            let paging;
            let footerPaging;
            // paging
            if (this.props.paging) {
                if (this.props.paging.pages == 1) {
                    if (this.props.paging.total) {
                        recordCount = <p style={{ display: 'block', fontStyle: 'italic', color: '#999', textAlign: 'center' }}><b>{this.props.paging.total}</b> record{this.props.paging.total > 1 ? "s" : ""}</p>
                        if (this.props.paging.unFilteredTotal && this.props.paging.unFilteredTotal > 0 && this.props.paging.unFilteredTotal != this.props.paging.total) {
                            recordCount = <p style={{ display: 'block', fontStyle: 'italic', color: '#999', textAlign: 'center' }}><b>{this.props.paging.total}</b> record{this.props.paging.total > 1 ? "s" : ""} from a total of {this.props.paging.unFilteredTotal} </p>
                        }
                    }
                } else {
                    paging = <Paging paging={this.props.paging} onPageSelected={this.props.onPageSelected} />

                    if (this.props.paging.total && this.props.paging.page && this.props.paging.pageSize) {
                        let from = (1 + ((this.props.paging.page - 1) * this.props.paging.pageSize));
                        let to = from + this.props.paging.pageSize - 1;
                        if (to > this.props.paging.total) {
                            to = this.props.paging.total;
                        }
                        recordCount = <p style={{ display: 'block', fontStyle: 'italic', color: '#999', textAlign: 'center' }}><b>{from}</b> to <b>{to}</b> of <b>{this.props.paging.total}</b> record{this.props.paging.total > 1 ? "s" : ""}</p>
                        if (this.props.paging.unFilteredTotal && this.props.paging.unFilteredTotal > 0 && this.props.paging.unFilteredTotal != this.props.paging.total) {
                            recordCount = <p style={{ display: 'block', fontStyle: 'italic', color: '#999', textAlign: 'center' }}><b>{from}</b> to <b>{to}</b> of <b>{this.props.paging.total}</b> record{this.props.paging.total > 1 ? "s" : ""} from a total of {this.props.paging.unFilteredTotal} </p>
                        }
                    }
                }
                footerPaging = <Paging paging={this.props.paging} onPageSelected={this.props.onPageSelected} onPageSizeSelected={this.props.onPageSizeSelected} />
            }

            // ~ paging


            let footer = this.props.footer;
            if (!footer && (hasObsolete || hasSuccess || hasDanger || hasWarning || hasInfo || this.props.legendExtra)) {
                footer = (
                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="left" >
                        {hasInfo && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentCurrent}></div>
                                <div className={classes.colorSwatchText}>{this.props.isInfoLabel || "Global"}</div>
                            </Box>
                        )}
                        {hasSuccess && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentApproved}></div>
                                <div className={classes.colorSwatchText}>{this.props.isSuccessLabel || "Success"}</div>
                            </Box>
                        )}
                        {hasWarning && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentDraft}></div>
                                <div className={classes.colorSwatchText}>{this.props.isWarningLabel || "Warning"}</div>
                            </Box>
                        )}
                        {hasDanger && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentDeleted}></div>
                                <div className={classes.colorSwatchText}>{dangerLabel}</div>
                            </Box>
                        )}
                        {this.props.isDefaultLabel && (
                            <Box p={1} >
                                <div className={classes.colorSwatchIncidentPending}></div>
                                <div className={classes.colorSwatchText}>{this.props.isDefaultLabel}</div>
                            </Box>
                        )}
                        {hasObsolete && (
                            <Box p={1} >
                                <div className={classes.colorSwatchObsolete}></div>
                                <div className={classes.colorSwatchText}>{this.props.isObsoleteLabel || "Non-active"}</div>
                            </Box>
                        )}
                        {this.props.legendExtra}
                    </Box>
                );
            }
            if (!footer) {
                footer = <>
                    {footerPaging}
                    {recordCount}
                </>
             } else if (this.props.onPageSelected) {
                footer = <>
                    {footer}
                    {footerPaging}
                    {recordCount}
                </>
            } else {
                footer = <>
                    {footer}
                </>
            }
            content = (
                <>
                    {filter}
                    {this.props.aboveListContent}
                    {paging}
                    <List
                        cards={cards}
                        rows={this.props.cardsOnly ? cards : <TableContainer
                            header={<tr>
                                <th className={classes.tableHrIndicator}></th>
                                {this.props.headers.map((item, index) => {
                                    let minWidth: number = this.props.minWidths != undefined ? this.props.minWidths[index] : 0;
                                    let style: any = { minWidth: minWidth.toString() + 'px' };
                                    if (this.props.widths && this.props.widths[index] > 0) {
                                        style = { ...style, width: this.props.widths[index] + 'px' };
                                    }
                                    if (this.props.alignments && this.props.alignments[index] !== '') {
                                        style = { ...style, textAlign: this.props.alignments[index] };
                                    }
                                    return <th key={"column-header" + index.toString()} style={style} className={classes.tableHr}>{item}</th>
                                })}
                                <th className={classes.tableHrCommands}></th>
                            </tr>}
                            rows={rows}
                        />}
                        count={count}

                        footer={footer}
                        noRowsMessage={this.props.noRowsMessage}
                    />
                    <ImagePreview filePath={this.state.filePath} onDismissed={() => this.onSelectFilePath("")} />
                </>);
        }
        return content;
    }
}
export default withStyles(styles, { withTheme: true })(ResponsiveList);
