import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardTypography from '../../common/CardTypography';
import CardTitle from '../../common/CardTitle';
import CardRow from '../../common/CardRow';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import { userRoleLogic } from '../../../common/logic/userRoleLogic';

export interface IUserRoleCardProps {
    userRole: AusComplyDtos.UserRoleSummary;
    onCommand: Function;
}

class UserRoleCard extends React.Component<IUserRoleCardProps, any> {
    constructor(props: IUserRoleCardProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command: string) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.userRole);
        }
    }

    render() {
        var commands = userRoleLogic.getCommands(this.props.userRole);
        return (
            <CardRow isDanger={this.props.userRole.userRoleObsolete || this.props.userRole.userObsolete} commands={commands} onCommand={this.onCommand}>
                <Grid container spacing={1}>
                    {this.props.userRole.group && (
                        <Grid item xs={6} sm={6}>
                            <CardTitle>Venue</CardTitle>
                            <CardTypography>{this.props.userRole.group}</CardTypography>
                        </Grid>
                    )}
                    {this.props.userRole.venue && (
                        <Grid item xs={6} sm={6}>
                            <CardTitle>Venue</CardTitle>
                            <CardTypography>{this.props.userRole.venue}</CardTypography>
                        </Grid>
                    )}
                    {this.props.userRole.securityFirm && (
                        <Grid item xs={6} sm={6}>
                            <CardTitle>Security Firm</CardTitle>
                            <CardTypography>{this.props.userRole.securityFirm}</CardTypography>
                        </Grid>

                    )}
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Name</CardTitle>
                        <CardTypography><b>{this.props.userRole.givenName} {this.props.userRole.surname}</b></CardTypography>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <CardTitle>Access</CardTitle>
                        <CardTypography>{this.props.userRole.userRoleType}  / {this.props.userRole.accessLevel}</CardTypography>
                    </Grid>
                </Grid>
            </CardRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserRoleCard);