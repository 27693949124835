
import * as AusComplyDtos from "../dto/AusComply.dtos";
import { KeyName } from '../../common/dto/common';

export const incidentActionTemplateLogic = {

    getCommands(incidentActionTemplate: AusComplyDtos.IncidentActionTemplate, canEdit: boolean) {
        var commands: KeyName[] = [];
        if (canEdit && incidentActionTemplate.canEdit) {
            commands.push({ key: "edit", name: "Edit" });
            if (!incidentActionTemplate.obsolete)  {
                commands.push({ key: "delete", name: "Delete" });
            } else if (incidentActionTemplate.obsolete) {
                commands.push({ key: "undodelete", name: "Undo Delete" });
            }
            commands.push({ key: "preview", name: "Preview" });
        } else {
            commands.push({ key: "preview", name: "Preview" });
        }
        return commands;
    }
}