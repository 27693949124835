import update from "immutability-helper";
import * as incidentActions from "../actions/incidents";
import * as AusComplyDtos from "../../common/dto/AusComply.dtos";
import { filter } from "rxjs/operators";

const initialState = {
    filter: {
        reportingPeriodId: 0,
        canFilterReportingPeriod: false,
        canFilterAdvanced: false,
        canViewIDChecks: false,
        canView: false,
        canEdit: false,
        canApprove: false,
        canPrint: false,
        canPrintSummaryAndDetails: false,
        canPrintSummaryAndDetailedVersion: false
    },
    initialFilterLoaded: false,
    paging: {},
    items: [],
    isLoading: false,
    isApproving: false,
    error: "",
    snapshotSummary: {
        snapshotSummary: {},
        isLoading: false
    }
};

function setSnapshotSummaryLoading(state, isLoading) {
    let newState = update(state, {
        snapshotSummary: {
            isLoading: { $set: isLoading }
        }
    });
    return newState;
}

function setSnapshotSummary(state, snapshotSummary) {
    let newState = update(state, {
        snapshotSummary: {
            snapshotSummary: { $set: snapshotSummary }
        }
    });
    return newState;
}

function setLoading(state, isLoading) {
    let newState = update(state, {
        isLoading: { $set: isLoading }
    });
    return newState;
}

function setApproving(state, isApproving) {
    let newState = update(state, {
        isApproving: { $set: isApproving }
    });
    return newState;
}

function setInitialFilter(state, reset) {
    if (reset) {
        let newState = update(state, {
            initialFilterLoaded: { $set: false }
        });
        return newState;
    }
    return state;
}

function setFilter(state, filter, preserveExistingForSameReportingPeriod) {
    if (state.initialFilterLoaded && preserveExistingForSameReportingPeriod) {
        // If the reporting period, and permissions are the same, keep the existing filters
        const oldFilter = state.filter;
        if (oldFilter.reportingPeriodId > 0 && filter.reportingPeriodId > 0 && oldFilter.reportingPeriodId == filter.reportingPeriodId) {
            // the reporing period is the same
            if (oldFilter.canFilterReportingPeriod == filter.canFilterReportingPeriod &&
                oldFilter.canFilterAdvanced == filter.canFilterAdvanced &&
                oldFilter.canViewIDChecks == filter.canViewIDChecks &&
                oldFilter.canView == filter.canView &&
                oldFilter.canEdit == filter.canEdit &&
                oldFilter.canApprove == filter.canApprove &&
                oldFilter.canPrint == filter.canPrint &&
                oldFilter.canPrintSummaryAndDetails == filter.canPrintSummaryAndDetails &&
                oldFilter.canPrintSummaryAndDetailedVersion == filter.canPrintSummaryAndDetailedVersion &&
                oldFilter.forEntryPage == filter.forEntryPage
            ) {
                // all the permissions are the same so keep the existing filter
                return state;
            }
        }
    }
    let newState = update(state, {
        filter: { $set: filter },
        initialFilterLoaded: { $set: true }
    });
    return newState;
}

function setIncidentItems(state, items) {
    let newState = update(state, {
        items: { $set: items },
        isApproving: { $set: false }
    });
    return newState;
}

function setIncidents(state, items, paging) {
    let newState = update(state, {
        items: { $set: items },
        paging: { $set: paging },
        isApproving: { $set: false }
    });
    return newState;
}

function selectIncidentForApproval(state, incidentId, isSelected) {
    let items = [...state.items];
    items.forEach(incident => {
        if (incident.incidentId === incidentId && incident.completed && !incident.approved) {
            incident.selected = isSelected && incident.allowBulkApproval;
        }
    });
    let newState = update(state, {
        items: { $set: items }
    });
    return newState;
}

function selectAllIncidentsForApproval(state, isSelected) {
    let items = [...state.items];
    items.forEach(incident => {
        if (incident.completed && !incident.approved) {
            incident.selected = isSelected && incident.allowBulkApproval;
        }
    });
    let newState = update(state, {
        items: { $set: items }
    });
    return newState;
}

export default function incidents(state = initialState, action) {
    switch (action.type) {
        case incidentActions.INCIDENTS_RESET:
            return { ...initialState };
        case incidentActions.INCIDENTS_REQUEST:
            return setLoading(setIncidentItems(state, []), true);
        case incidentActions.INCIDENTS_REQUEST_FAILURE:
            return setLoading(state, false);
        case incidentActions.INCIDENTS_REQUEST_SUCCESS:
            return setLoading(setIncidents(setFilter(state, action.incidentFilter, false), action.incidents, action.paging), false);
        case incidentActions.INCIDENTS_REFRESH_REQUEST:
            return setLoading(state, true);
        case incidentActions.INCIDENTS_FILTER_REQUEST:
            return setLoading(setInitialFilter(state, action.reset), true);
        case incidentActions.INCIDENTS_FILTER_REQUEST_FAILURE:
            return setLoading(state, false);
        case incidentActions.INCIDENTS_FILTER_REQUEST_SUCCESS:
            return setLoading(setFilter(state, action.incidentFilter, true), false);
        case incidentActions.INCIDENTS_SELECT_FOR_APPROVAL:
            return selectIncidentForApproval(state, action.incidentId, action.isSelected);
        case incidentActions.INCIDENTS_SELECT_ALL_FOR_APPROVAL:
            return selectAllIncidentsForApproval(state, action.isSelected);
        case incidentActions.INCIDENTS_APPROVE_SELECTED_REQUEST:
            return setApproving(state, true);
        case incidentActions.INCIDENTS_APPROVE_SELECTED_REQUEST_SUCCESS:
            return setApproving(state, false);
        case incidentActions.INCIDENTS_APPROVE_SELECTED_REQUEST_FAILURE:
            return setApproving(state, false);
        case incidentActions.INCIDENTS_REQUIRING_APPROVAL:
            return setLoading(setInitialFilter(state, true), true);
        case incidentActions.INCIDENTS_SNAPSHOT_SUMMARY_REQUEST:
            return setSnapshotSummaryLoading(state, true);
        case incidentActions.INCIDENTS_SNAPSHOT_SUMMARY_REQUEST_SUCCESS:
            return setSnapshotSummaryLoading(setSnapshotSummary(state, action.snapshotSummary), false);
        case incidentActions.INCIDENTS_SNAPSHOT_SUMMARY_REQUEST_FAILURE:
            return setSnapshotSummaryLoading(state, false);
    }
    return state;
}
