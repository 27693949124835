import { connect } from 'react-redux'
import { withRouter } from "react-router";
import { permissionsLogic } from '../common/logic/permissionsLogic';
import IncidentActionTemplates from '../components/incidentActions/incidentActionTemplates/IncidentActionTemplates'
import {  
    incidentActionTemplateSet,
    incidentActionTemplatesSetFilter,
    incidentActionTemplateUpsertRequest,
    incidentActionTemplatesReset,
    incidentActionTemplateRequest
 } from '../common/actions/incidentActionTemplates';

const mapStateToProps = (state, props) => {
    return {
        venueId:  state.user.details.userSession.user.venueId,
        groupId: undefined,
        filter: state.incidentActionTemplates.incidentActionTemplates.filter,
        isLoading: state.incidentActionTemplates.incidentActionTemplates.isLoading,
        incidentActionTemplates: state.incidentActionTemplates.incidentActionTemplates.incidentActionTemplates,
        canEdit: permissionsLogic.hasPermissionForState(state, "ManageIncidentActions"),
        incidentActionTemplate: state.incidentActionTemplates.incidentActionTemplate.incidentActionTemplate,
        isLoadingItem: state.incidentActionTemplates.incidentActionTemplate.isLoading,
        showFunctionalAreas: permissionsLogic.hasPermissionForState(state, "FunctionalAreas"),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetFilter: (filter) => {
            dispatch(incidentActionTemplatesSetFilter(filter))
        },
        onSet: (incidentActionTemplate) => {
            dispatch(incidentActionTemplateSet(incidentActionTemplate))
        },
        onUpdate: () => {
            dispatch(incidentActionTemplateUpsertRequest())
        },
        onReset: () => {
            dispatch(incidentActionTemplatesReset())
        },
        onLoadItem: (checklistTemplateId) => {
            dispatch(incidentActionTemplateRequest(checklistTemplateId))
        }
    }
}

const IncidentActionTemplatesContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(IncidentActionTemplates))

export default IncidentActionTemplatesContainer