import React, { Component } from 'react';
import Badge from '@material-ui/core/Badge';
import { withRouter } from "react-router";
import { styles } from '../../styles';
import { withStyles } from '@material-ui/core/styles';

export interface IBadgeWrapperProps {
    count: number;
    bottomRight?: boolean;
    theme: any;
    classes: any;
}

class BadgeWrapper extends Component<IBadgeWrapperProps, any> {

    render() {
        const { classes } = this.props;
        if (this.props.count === 0) {
            return (<>
                {this.props.children}
            </>)
        }

        let badgeStyle = {
            margin: '0',
            width: '100%'
        };

        let display = this.props.count.toString();
        if (this.props.count > 99) {
            display = "99+";
        }

        if (this.props.bottomRight) {
            return (
                <Badge color="error" badgeContent={display} style={badgeStyle} classes={{ badge: classes.badgeBottomRightInside }}>
                    {this.props.children}
                </Badge>
            )
        }

        return (
            <Badge color="error" badgeContent={display} style={badgeStyle} classes={{ badge: classes.badge }}>
                {this.props.children}
            </Badge>
        )
    }
}
export default withStyles(styles, { withTheme: true })(withRouter(BadgeWrapper))