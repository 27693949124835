import { of, timer } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom, debounce, filter } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { incidentActionTemplateServiceApi } from '../services/incidentActionTemplate';

import {
    INCIDENT_ACTION_TEMPLATES_REQUEST,
    incidentActionTemplatesRequest,
    incidentActionTemplatesRequestSuccess,
    incidentActionTemplatesRequestFailure,
    INCIDENT_ACTION_TEMPLATE_REQUEST,
    incidentActionTemplateRequestSuccess,
    incidentActionTemplateRequestFailure,
    INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST,
    incidentActionTemplateCreateRequestSuccess,
    incidentActionTemplateCreateRequestFailure,
    INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST,
    incidentActionTemplateUpsertRequestSuccess,
    incidentActionTemplateUpsertRequestFailure,
    INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_SUCCESS,
    INCIDENT_ACTION_TEMPLATES_SET_FILTER,
    // INCIDENT_ACTION_TEMPLATES_REFRESH,
    // incidentActionTemplatesRefresh
} from '../actions/incidentActionTemplates';
import {
    notifyError
} from './common';

const incidentActionTemplatesRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_ACTION_TEMPLATES_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentActionTemplateServiceApi.find(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.incidentActionTemplates.incidentActionTemplates.filter)
                .pipe(
                    map(response => incidentActionTemplatesRequestSuccess(response.filter, response.incidentActionTemplates)),
                    catchError(error => notifyError(error, "incidentActionTemplatesRequestEpic.find", incidentActionTemplatesRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "incidentActionTemplatesRequestEpic", incidentActionTemplatesRequestFailure))
    );

const incidentActionTemplateRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_ACTION_TEMPLATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentActionTemplateServiceApi.get(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['incidentActionTemplateId'])
                .pipe(
                    map(response => incidentActionTemplateRequestSuccess(response.incidentActionTemplate, response.incidentCategoryTypes)),
                    catchError(error => notifyError(error, "incidentActionTemplateRequestEpic.get", incidentActionTemplateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "incidentActionTemplateRequestEpic", incidentActionTemplateRequestFailure))
    );

const incidentActionTemplateCreateEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_ACTION_TEMPLATE_CREATE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentActionTemplateServiceApi.create(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action['venueId'],
                action['groupId'])
                .pipe(
                    map(response => incidentActionTemplateCreateRequestSuccess(response.incidentActionTemplate, response.incidentCategoryTypes)),
                    catchError(error => notifyError(error, "incidentActionTemplateCreateEpic.create", incidentActionTemplateCreateRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "incidentActionTemplateCreateEpic", incidentActionTemplateCreateRequestFailure))
    );

const incidentActionTemplateUpsertEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            incidentActionTemplateServiceApi.upsert(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                state.incidentActionTemplates.incidentActionTemplate.incidentActionTemplate)
                .pipe(
                    map(response => incidentActionTemplateUpsertRequestSuccess(response.incidentActionTemplate)),
                    catchError(error => notifyError(error, "incidentActionTemplateUpsertEpic.upsert", incidentActionTemplateUpsertRequestFailure))
                )
        ),
        catchError(error => notifyError(error, "incidentActionTemplateUpsertEpic", incidentActionTemplateUpsertRequestFailure))
    );

const incidentActionTemplateUpsertSuccessRefreshEpic = (action$, state$) =>
    action$.pipe(
        ofType(INCIDENT_ACTION_TEMPLATE_UPSERT_REQUEST_SUCCESS),
        withLatestFrom(state$),
        filter(([action, state]) => action.incidentActionTemplate.groupId == undefined || action.incidentActionTemplate.groupId == 0),
        map((action: any) => incidentActionTemplatesRequest())
    );

const incidentActionTemplateSetFilter = (action$, state$) => 
    action$.pipe(
        ofType(INCIDENT_ACTION_TEMPLATES_SET_FILTER),
        debounce(() => timer(100)),
        withLatestFrom(state$),
        map((action: any) => incidentActionTemplatesRequest())
    );

export const incidentActionTemplateEpics = combineEpics(
    incidentActionTemplatesRequestEpic,
    incidentActionTemplateRequestEpic,
    incidentActionTemplateCreateEpic,
    incidentActionTemplateUpsertEpic,
    incidentActionTemplateUpsertSuccessRefreshEpic,
    incidentActionTemplateSetFilter
);