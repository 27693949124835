import React from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TableRow from '../../common/TableRow';
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';
import { incidentCategoryTypeLogic } from '../../../common/logic/incidentCategoryTypeLogic';

export interface IIncidentCategoryTypeRowProps {
    incidentCategoryType: AusComplyDtos.IncidentCategoryType;
    canEditNotifications?: boolean;
    onCommand?: Function;
}

export default class IncidentCategoryTypeRow extends React.Component<IIncidentCategoryTypeRowProps, any> {
    constructor(props: IIncidentCategoryTypeRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand) {
            this.props.onCommand(command, this.props.incidentCategoryType);
        }
    }

    render() {
        if (!this.props.incidentCategoryType) {
            return (<></>);
        }

        var commands = incidentCategoryTypeLogic.getCommands(this.props.incidentCategoryType, this.props.canEditNotifications);

        let legislatedTick;
        if (this.props.incidentCategoryType.legislated) {
            legislatedTick = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let narrativeTick;
        if (this.props.incidentCategoryType.narrativeRequired) {
            narrativeTick = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let approvalTick;
        if (this.props.incidentCategoryType.allowBulkApproval) {
            approvalTick = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let autoApproveTick;
        if (this.props.incidentCategoryType.autoApprove) {
            autoApproveTick = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let regulatedWalkthrough;
        if (this.props.incidentCategoryType.regulatedWalkthrough) {
            regulatedWalkthrough = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let poiTick;
        if (this.props.incidentCategoryType.requiresPersonDescription) {
            poiTick = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let contactTracingTick;
        if (this.props.incidentCategoryType.contactTracing) {
            contactTracingTick = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        let notificationTick;
        if (this.props.incidentCategoryType.hasIncidentCategoryTypeNotification) {
            notificationTick = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }

        return (
            <TableRow
                isDanger={this.props.incidentCategoryType.obsolete}
                isWarning={this.props.incidentCategoryType.hidden}
                isInfo={this.props.incidentCategoryType.isSpecificForVenueOrSecurityFirm}
                commands={commands} onCommand={this.onCommand}>
                <td>
                    <p>
                        {this.props.incidentCategoryType.name}
                    </p>
                </td>
                <td>
                    <p style={{ fontStyle: 'italic', color: '#aaa' }}>
                        {this.props.incidentCategoryType.incidentTypesDisplay}
                    </p>
                </td>
                <td>
                    <p>
                        {this.props.incidentCategoryType.code}
                    </p>
                </td>
                <td>
                    <p style={{ fontStyle: 'italic', color: '#aaa' }}>
                        {this.props.incidentCategoryType.industryCategoriesDisplay}
                    </p>
                </td>
                <td>
                    <p>
                        {this.props.incidentCategoryType.stateName}
                    </p>
                </td>
                <td>
                    {narrativeTick}
                </td>
                <td>
                    {legislatedTick}
                </td>
                <td>
                    {approvalTick}
                </td>
                <td>
                    {autoApproveTick}
                </td>
                <td>
                    {regulatedWalkthrough}
                </td>
                <td>
                    {poiTick}
                </td>
                <td>
                    {contactTracingTick}
                </td>
                {this.props.canEditNotifications && (
                    <td>
                        {notificationTick}
                    </td>
                )}
                <td>
                    <p>
                        {this.props.incidentCategoryType.rank}
                    </p>
                </td>
            </TableRow>
        );
    }
}