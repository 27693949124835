
import { of } from 'rxjs';
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { map, catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { combineEpics, ofType } from "redux-observable";
import { printApi } from '../services/printService';
import * as printActions from "../actions/print";
import { USER_CHANGED, USER_LOGIN_REQUEST_SUCCESS, USER_DETAILS_REQUEST_SUCCESS } from '../actions/userLogin';
import { 
    notifyError, notifyErrorMessage, noNotifyError, notifyErrorNoTrack
  } from './common'; 
import { notificationShow, notificationSuccessShow } from "../actions/notification";

const printUserChangedEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_CHANGED),
        map(action => printActions.printReset())
    );

const printUserLoginChangedEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_LOGIN_REQUEST_SUCCESS),
        map(action => printActions.printReset())
    );

const printUserLoginPrintQueueEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_LOGIN_REQUEST_SUCCESS),
        map(action => printActions.printQueueRequest())
    );

const printUserDetailsRequestSuccessPrintQueueEpic = (action$, state$) =>
    action$.pipe(
        ofType(USER_DETAILS_REQUEST_SUCCESS),
        map(action => printActions.printQueueRequest())
    );



const printOptionsEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_OPTIONS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.getPrintOptions(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => printActions.printOptionsRequestSuccess(response.printOptions)),
                    catchError(error => notifyError(error, "printOptionsEpic.getPrintOptions", printActions.printOptionsRequestFailed))
                )
        ),
        catchError(error => notifyError(error, "printOptionsEpic", printActions.printOptionsRequestFailed))
    );

const printIncidentPdfEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_INCIDENTS_PDF_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.printIncidentPdf(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action["filter"],
                action["reportType"],
            )
                .pipe(
                    map(response => printActions.printIncidentsPdfRequestSuccess()),
                    catchError(error => notifyError(error, "printIncidentPdfEpic.printIncidentPdf", printActions.printIncidentsPdfRequestFailed))
                )
        ),
        catchError(error => notifyError(error, "printIncidentPdfEpic", printActions.printIncidentsPdfRequestFailed))
    );

const printIncidentPdfSuccessEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_INCIDENTS_PDF_REQUEST_SUCCESS),
        map(action => printActions.printQueueRequest())
    );

const printQueueRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_QUEUE_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.getActive(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => printActions.printQueueRequestSuccess(response.items)),
                    catchError(error => notifyErrorNoTrack(error, "printQueueRequestEpic.getActive", printActions.printQueueRequestFailed))
                )
        ),
        catchError(error => noNotifyError(error, "printQueueRequestEpic", printActions.printQueueRequestFailed))
    );


const printQueueDownloadedRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_QUEUE_DOWNLOADED_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.downloaded(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action["reportGenerationId"])
                .pipe(
                    map(response => printActions.printQueueDownloadSuccess()),
                    catchError(error => notifyErrorNoTrack(error, "printQueueDownloadedRequestEpic.downloaded", printActions.printQueueDownloadFailed))
                )
        ),
        catchError(error => notifyError(error, "printQueueDownloadedRequestEpic", printActions.printQueueDownloadFailed))
    );

const printQueueDownloadSuccessRequestEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_QUEUE_DOWNLOADED_REQUEST_SUCCESS),
        map(action => printActions.printQueueRequest())
    );


const printQueueDismissRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_QUEUE_DISMISS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.dismissed(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action["reportGenerationId"])
                .pipe(
                    map(response => printActions.printQueueDismissSuccess()),
                    catchError(error => notifyError(error, "printQueueDismissRequestEpic.dismissed", printActions.printQueueDismissFailed))
                )
        ),
        catchError(error => notifyError(error, "printQueueDismissRequestEpic", printActions.printQueueDismissFailed))
    );

const printQueueDismissSuccessRequestEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_QUEUE_DISMISS_REQUEST_SUCCESS),
        map(action => printActions.printQueueRequest())
    );

const printQueueDismissAllRequestEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_QUEUE_DISMISS_ALL_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.dismissAll(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId)
                .pipe(
                    map(response => printActions.printQueueDismissAllSuccess()),
                    catchError(error => notifyError(error, "printQueueDismissAllRequestEpic.dismissAll", printActions.printQueueDismissAllFailed))
                )
        ),
        catchError(error => notifyError(error, "printQueueDismissAllRequestEpic", printActions.printQueueDismissAllFailed))
    );

const printQueueDismissAllSuccessRequestEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_QUEUE_DISMISS_ALL_REQUEST_SUCCESS),
        map(action => printActions.printQueueRequest())
    );


const printIncidentCsvEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_INCIDENTS_CSV_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.printIncidentCsv(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action["filter"],
            )
                .pipe(
                    map(response => printActions.printIncidentsCsvRequestSuccess()),
                    catchError(error => notifyError(error, "printIncidentCsvEpic.printIncidentCsv", printActions.printIncidentsCsvRequestFailed))
                )
        ),
        catchError(error => notifyError(error, "printIncidentCsvEpic", printActions.printIncidentsCsvRequestFailed))
    );

const printIncidentCsvSuccessEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_INCIDENTS_CSV_REQUEST_SUCCESS),
        map(action => printActions.printQueueRequest())
    );


const printIncidentEmailEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_INCIDENTS_EMAIL_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.emailIncidentHistoryReport(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action["filter"],
                action["send"].recipients,
                action["send"].ccRecipients,
                action["send"].bccRecipients
            )
                .pipe(
                    map(response => printActions.printIncidentsEmailRequestSuccess()),
                    catchError(error => notifyError(error, "printIncidentEmailEpic.emailIncidentHistoryReport", printActions.printIncidentsEmailRequestFailed))
                )
        ),
        catchError(error => notifyError(error, "printIncidentEmailEpic", printActions.printIncidentsEmailRequestFailed))
    );

const printIncidentEmailSuccessEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_INCIDENTS_EMAIL_REQUEST_SUCCESS),
        map(action => notificationSuccessShow("Report was sent via email"))
    );



const printIncidentEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_INCIDENT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.incidentReport(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action["option"],
                action["id"],
                action["includeVersions"],
                action["to"],
                action["cc"],
                action["bcc"]
            )
                .pipe(
                    map(response => printActions.printIncidentRequestSuccess(response.message)),
                    catchError(error => notifyError(error, "printIncidentEpic.incidentReport", printActions.printIncidentRequestFailed))
                )
        ),
        catchError(error => notifyError(error, "printIncidentEpic", printActions.printIncidentRequestFailed))
    );

const printIncidentSuccessEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_INCIDENT_REQUEST_SUCCESS),
        map(action => printActions.printQueueRequest())
    );


const printIncidentSuccessEmailEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_INCIDENT_REQUEST_SUCCESS),
        map((action:any) => notificationSuccessShow(action["message"]))
    );

const printChecklistEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_CHECKLIST_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.checklistReport(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action["option"],
                action["filter"],
                action["to"],
                action["cc"],
                action["bcc"]
            )
                .pipe(
                    map(response => printActions.printChecklistRequestSuccess(response.message)),
                    catchError(error => notifyError(error, "printChecklistEpic.checklistReport", printActions.printChecklistRequestFailed))
                )
        ),
        catchError(error => notifyError(error, "printChecklistEpic", printActions.printChecklistRequestFailed))
    );

const printChecklistSuccessEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_CHECKLIST_REQUEST_SUCCESS),
        map(action => printActions.printQueueRequest())
    );


const printChecklistSuccessEmailEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_CHECKLIST_REQUEST_SUCCESS),
        map((action:any) => notificationSuccessShow(action["message"]))
    );

const printSignOnOffRegistersEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_SIGNONOFFREGISTERS_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.signOnOffRegistersReport(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action["option"],
                action["filter"],
                action["to"],
                action["cc"],
                action["bcc"]
            )
                .pipe(
                    map(response => printActions.printSignOnOffRegistersRequestSuccess(response.message)),
                    catchError(error => notifyError(error, "printSignOnOffRegistersEpic.signOnOffRegistersReport", printActions.printSignOnOffRegistersRequestFailed))
                )
        ),
        catchError(error => notifyError(error, "printSignOnOffRegistersEpic", printActions.printSignOnOffRegistersRequestFailed))
    );

const printSignOnOffRegistersSuccessEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_SIGNONOFFREGISTERS_REQUEST_SUCCESS),
        map(action => printActions.printQueueRequest())
    );


const printSignOnOffRegistersSuccessEmailEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_SIGNONOFFREGISTERS_REQUEST_SUCCESS),
        map((action:any) => notificationSuccessShow(action["message"]))
    );

const printIncidentCategoryTypeDocumentEpic = (action$, state$) =>
    action$.pipe(
        ofType(printActions.PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST),
        withLatestFrom(state$),
        mergeMap(([action, state]) =>
            printApi.incidentCategoryTypeDocument(
                state.user.details.userSession.user.venueId,
                state.user.details.userSession.user.securityFirmId,
                action["option"],
                action["id"],
                action["incidentCategoryTypeDocument"],
                action["to"],
                action["cc"],
                action["bcc"]
            )
                .pipe(
                    map(response => printActions.printIncidentRequestSuccess(response.message)),
                    catchError(error => notifyError(error, "printIncidentCategoryTypeDocumentEpic.incidentCategoryTypeDocument", printActions.printIncidentRequestFailed))
                )
        ),
        catchError(error => notifyError(error, "printIncidentCategoryTypeDocumentEpic", printActions.printIncidentRequestFailed))
    );

const printIncidentCategoryTypeDocumentSuccessEpic = action$ =>
    action$.pipe(
        ofType(printActions.PRINT_INCIDNETCATEGORYTYPEDOCUMENT_REQUEST_SUCCESS),
        map(action => printActions.printQueueRequest())
);

export const printEpics = combineEpics(
    printOptionsEpic,
    printIncidentPdfEpic,
    printIncidentPdfSuccessEpic,
    printUserChangedEpic,
    printUserLoginChangedEpic,
    printQueueRequestEpic,
    printUserLoginPrintQueueEpic,
    printQueueDownloadedRequestEpic,
    printQueueDownloadSuccessRequestEpic,
    printQueueDismissRequestEpic,
    printQueueDismissSuccessRequestEpic,
    printQueueDismissAllRequestEpic,
    printQueueDismissAllSuccessRequestEpic,
    printIncidentCsvEpic,
    printIncidentCsvSuccessEpic,
    printIncidentEmailEpic,
    printIncidentEmailSuccessEpic,
    printIncidentEpic,
    printIncidentSuccessEpic,
    printUserDetailsRequestSuccessPrintQueueEpic,
    printIncidentSuccessEmailEpic,
    printChecklistEpic,
    printChecklistSuccessEpic,
    printChecklistSuccessEmailEpic,
    printSignOnOffRegistersEpic,
    printSignOnOffRegistersSuccessEpic,
    printSignOnOffRegistersSuccessEmailEpic,
    printIncidentCategoryTypeDocumentEpic,
    printIncidentCategoryTypeDocumentSuccessEpic
);