import React from 'react';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import TickIcon from '../../../resources/TickIcon';
import View from '../../common/View';
import TableRow from '../../common/TableRow';
import { userRoleLogic } from '../../../common/logic/userRoleLogic';

export interface IUserRoleRowProps {
    onCommand: Function;
    userRole: AusComplyDtos.UserRoleSummary;
}

class UserRoleRow extends React.Component<IUserRoleRowProps, any> {
    constructor(props: IUserRoleRowProps) {
        super(props)
        this.onCommand = this.onCommand.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    onCommand(command) {
        if (this.props.onCommand){
            this.props.onCommand(command, this.props.userRole);
        }
    }

    onEdit() {
        if(userRoleLogic.canEditOrView(this.props.userRole)) {
            this.props.onCommand("view", this.props.userRole);
        }
    }

    render() {
        let item = this.props.userRole;
        var commands = userRoleLogic.getCommands(this.props.userRole);
        let isTemporary;
        if (item.isTemporary) {
            isTemporary = (
                <View style={{ width: '16px', height: '16px', margin: '0 50%' }}>
                    <TickIcon style={{ width: '16px', height: '16px' }} fill={"#fff"} />
                </View>
            );
        }
        return (
            <TableRow isDanger={this.props.userRole.userRoleObsolete} commands={commands} onCommand={this.onCommand}>
            <td onClick={() => this.onEdit()}>
               <p>
                   {item.group}
               </p>
           </td>
                 <td onClick={() => this.onEdit()}>
                    <p>
                        {item.venue}
                    </p>
                </td>
                <td onClick={() => this.onEdit()}>
                    <p>
                        {item.securityFirm}
                    </p>
                </td>
                <td onClick={() => this.onEdit()}>
                    <p>
                        {item.givenName}
                    </p>
                </td>
                <td onClick={() => this.onEdit()}>
                    <p>
                        {item.surname}
                    </p>
                </td>
                <td onClick={() => this.onEdit()}>
                    <p>
                        {item.nickname}
                    </p>
                </td>
                <td onClick={() => this.onEdit()}>
                    <p>
                        {item.mobileNo}
                    </p>
                </td>
                <td onClick={() => this.onEdit()}>
                    <p>
                        {item.userRoleType}
                    </p>
                </td>
                <td onClick={() => this.onEdit()}>
                    <p>
                        {item.accessLevel}
                    </p>
                </td>
                <td onClick={() => this.onEdit()}>
                    <p>
                        {item.userRoleStatus}
                    </p>
                </td>
                <td>
                    {isTemporary}
                </td>
            </TableRow>
        );
    }
}
export default withStyles(styles, { withTheme: true })(UserRoleRow);