import React, { Component } from 'react';
import * as AusComplyDtos from "../../../common/dto/AusComply.dtos";
import View from '../../common/View';
import { KeyName } from '../../../common/dto/common';
import IncidentLegend from '../IncidentLegend';
import { styles } from '../../../styles';
import { withStyles } from '@material-ui/core/styles';
import SecondaryFab from '../../common/SecondaryFab';
import IncidentsPrintContainer from '../../../containers/IncidentsPrintContainer';
import DefaultFab from '../../common/DefaultFab';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FullPageDialog from '../../layout/FullPageDialog';
import IncidentsFilter from './../IncidentsFilter';
import IncidentsFilterSummary from './../IncidentsFilterSummary';
import PageLayout from '../../layout/PageLayout';
import TransparentButton from '../../controls/TransparentButton';
import PrimaryButton from '../../controls/PrimaryButton';
import ResponsiveList from '../../common/ResponsiveList';
import CheckboxControl from '../../controls/CheckboxControl';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ContainedParagraph from '../../common/ContainedParagraph';

export interface IPlayTraxIncidentsProps {
    isLoading: boolean;
    isApproving: boolean;
    items: AusComplyDtos.Incident[];
    paging: AusComplyDtos.Paging;
    filter: AusComplyDtos.IncidentHistoryFilter;
    initialFilterLoaded: boolean;
    onRefresh: Function;
    onResetFilter: Function;
    onIncidentsSelectForApproval: Function;
    onIncidentsSelectAllForApproval: Function;
    onIncidentsApproveSelectedRequest: Function;
    onIncidentClear: Function;
    onErrorNotification: Function;
    onPrint: Function;
    history: any;
    classes: any;
    theme: any;
}

interface IPlayTraxIncidentsState {
    filter: AusComplyDtos.IncidentHistoryFilter;
    filterExpanded: boolean;
    showPrint: boolean;
    selectedCount: number;
}

class PlayTraxIncidents extends Component<IPlayTraxIncidentsProps, IPlayTraxIncidentsState> {
    constructor(props: IPlayTraxIncidentsProps) {
        super(props)

        this.state = {
            filter: new AusComplyDtos.IncidentHistoryFilter(),
            selectedCount: 0,
            filterExpanded: false,
            showPrint: true
        };

        this.onCommand = this.onCommand.bind(this);
        this.handlePageSelected = this.handlePageSelected.bind(this);
        this.handlePageSizeSelected = this.handlePageSizeSelected.bind(this);
        this.handleSelectedClicked = this.handleSelectedClicked.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onResetSearch = this.onResetSearch.bind(this);
        this.handlePrintDismissed = this.handlePrintDismissed.bind(this);
        this.onError = this.onError.bind(this);
        this.filterExpanded = this.filterExpanded.bind(this);
        this.onUpdated = this.onUpdated.bind(this);
    }

    componentDidMount() {
        if (!this.props.isLoading) {
            if (!this.props.initialFilterLoaded || !this.props.filter || this.props.filter.forEntryPage !== "PlayTraxEdit") {
                this.props.onResetFilter("PlayTraxEdit");
            } else {
                this.setState({ filter: this.props.filter });
                this.props.onRefresh(this.props.filter);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.isLoading && this.props.filter !== prevProps.filter) {
            this.setState({ filter: this.props.filter });
        }
        // update the selected count
        if (!this.props.isLoading && this.props.items) {
            let selectedCount = 0;
            this.props.items.forEach(incident => {
                if (incident.selected) {
                    selectedCount++;
                }
            });
            if (selectedCount !== this.state.selectedCount) {
                this.setState({
                    selectedCount
                });
            }
        }
    }

    onCommand(command, incident: AusComplyDtos.Incident) {
        switch (command) {
            case "refresh":
                this.onSearch();
                break;
            case "clearselection":
                this.props.onIncidentsSelectAllForApproval(false);
                break;
            case "selectall":
                this.props.onIncidentsSelectAllForApproval(true);
                break;
            case "approve":
                this.props.onIncidentsApproveSelectedRequest();
                break;
            case "edit":
                this.props.onIncidentClear();
                this.props.history.push('/playtrax/' + incident.incidentId);
                break;
            case "print":
                this.props.onPrint();
                break;

        }
    }

    handlePageSelected(page) {
        let filter = { ...this.state.filter };
        if (filter.paging) {
            filter.paging.page = page;
        }
        this.props.onRefresh(filter);
    }

    handlePageSizeSelected(page, pageSize) {
        let filter = { ...this.state.filter };
        if (filter.paging) {
            filter.paging.page = page;
            filter.paging.pageSize = pageSize;
        }
        this.props.onRefresh(filter);
    }

    handleSelectedClicked(incidentId: number, selected: boolean) {
        if (this.props.onIncidentsSelectForApproval) {
            this.props.onIncidentsSelectForApproval(incidentId, selected);
        }
    }

    handlePrintDismissed() {
        this.setState({ showPrint: false });
    }

    onSearch() {
        let filter = { ...this.state.filter };
        if (filter.paging) {
            filter.paging.page = 1;
        }
        this.props.onRefresh(filter);
    }

    onResetSearch() {
        this.setState({
            filterExpanded: false
        }, () => {
            this.props.onResetFilter("PlayTraxEdit");
        });
    }


    onError(error) {
        this.props.onErrorNotification(error);
    }

    onUpdated(filter) {
        if (filter) {
            if (filter.paging) {
                filter.paging.page = 1;
            }
            this.setState({ filter });
        }
    }

    filterExpanded(value: boolean) {
        if (this.state.filterExpanded && !value) {
            this.onSearch();
        }
        this.setState({
            filterExpanded: value
        })
    }

    getCommands(incident: AusComplyDtos.Incident) {
        var commands: KeyName[] = [];
        commands.push({ key: "edit", name: "Edit" });
        return commands;
    }

    render() {
        let paging = this.props.paging;
        
        let canApprove = false;
        let canEdit = false;
        let canView = false;
        let canPrint = false;
        let filter = this.props.filter != null ? this.state.filter : null;

        if (filter != null) {
            paging = this.props.paging;
            canApprove = filter.canApprove;
            canEdit = filter.canEdit;
            canView = filter.canView;
            canPrint = filter.canPrint;
        }

        let moreCommands: KeyName[] = [];

        if (!this.props.isLoading && !this.props.isApproving) {
            moreCommands.push({ key: "refresh", name: "Refresh" });
            if (canApprove) {
                if (this.state.selectedCount > 0) {
                    moreCommands.push({ key: "clearselection", name: "Clear selections" });
                }
                else {
                    moreCommands.push({ key: "selectall", name: "Select all for approval" });
                }
            }
            if (canPrint) {
                moreCommands.push({ key: "print", name: "Print" });
            }
        }

        let incidentsFilter;
        let incidentsFilterSummary;
        if (this.state.filterExpanded) {
            incidentsFilter = <IncidentsFilter
                filter={this.state.filter}
                onUpdateFilter={filter => this.onUpdated(filter)}
                onSearch={() => this.filterExpanded(false)}
                onReset={() => this.onResetSearch()} />;

        } else if (this.props.isLoading) {
            let loadingFilter = new AusComplyDtos.IncidentHistoryFilter();
            loadingFilter.display = "Searching...";
            incidentsFilterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentsFilterSummary filter={loadingFilter} onClick={() => this.filterExpanded(true)} />
            </View>;
        } else if (this.state.filter.canFilterReportingPeriod || this.state.filter.canFilterAdvanced) {
            incidentsFilterSummary = <View style={{ marginBottom: 10 }}>
                <IncidentsFilterSummary filter={this.props.filter} onClick={() => this.filterExpanded(true)} />
            </View>;
        }

        let fabs;
        if (!this.props.isLoading && !this.props.isApproving) {
            fabs = (
                <>
                    {(this.state.selectedCount > 0 && !this.props.isApproving && !this.props.isLoading) && (
                        <SecondaryFab isExtended={true} onClick={() => this.props.onIncidentsApproveSelectedRequest()}>
                            Approve Selected
                        </SecondaryFab>
                    )}
                    <DefaultFab actions={moreCommands} onClick={this.onCommand}><MoreVertIcon style={{ marginTop: '6px' }} /></DefaultFab>
                </>
            );
        }
        let leftButton;
        if (this.state.selectedCount > 0 && !this.props.isApproving && !this.props.isLoading) {
            leftButton = <TransparentButton text={"Approve Selected"} onClick={() => this.props.onIncidentsApproveSelectedRequest()} />
        }

        return <PageLayout
            headerText={"PlayTrax"}
            footerLeftContent={leftButton}
            moreCommands={moreCommands}
            onCommand={this.onCommand}
        >
            {incidentsFilterSummary}
            <ResponsiveList
                loading={this.props.isLoading || this.props.isApproving}
                data={this.props.items}
                paging={this.props.paging}
                onPageSelected={(page) => this.handlePageSelected( page)}
                onPageSizeSelected={(page, pageSize) => this.handlePageSizeSelected(page, pageSize)}
                headers={["Time", "Categories", "Narrative", "Locations", "Approve", ""]}
                columns={["incidentDateStringTz", "categories", "summary", "locations", "approve", "attachment"]}
                widths={[160, 0, 0, 0, 90, 40]}
                alignments={['', '', '', '', 'center', 'center']}
                getCommands={item => this.getCommands(item)}
                onCommand={(command, item) => this.onCommand(command, item)}
                footer={<IncidentLegend />}
                isWarning={item => !item.completed}
                isSuccess={item => item.approved && !item.hasRequiredActions}
                isDanger={item => item.completed && item.hasRequiredActions}
                onFormatValue={(column, value, data, row) => {
                    if (column == "summary") {
                        return <ContainedParagraph maxLines={6} text={value} />
                    } else if (column == "approve") {
                        value = canApprove ?
                            <div style={{ textAlign: 'center', verticalAlign: 'middle' }} onClick={e => { e.stopPropagation(); }}>
                                {(row.allowBulkApproval && row.completed && !row.approved) && (
                                    <div className="ngt-form-responsive" style={{ minHeight: '10px', marginLeft: '16px' }}>
                                        <CheckboxControl defaultValue={row.selected} onChanged={() => this.handleSelectedClicked(row.incidentId, !row.selected)} />
                                    </div>
                                )}
                            </div>
                            : <></>;
                    } else if (column == "attachment") {
                        value = row.hasAttachment ? <div style={{ paddingTop: '10px' }}><AttachFileIcon /></div> : <></>;
                    }
                    return value;
                }}
            />
            <IncidentsPrintContainer />
            <FullPageDialog
                notFullWidth={true}
                footerRightContent={<PrimaryButton text={"Search"} onClick={() => this.filterExpanded(false)} />}
                footerCenterContent={<TransparentButton text={"Reset"} onClick={() => this.onResetSearch()} />}
                open={this.state.filterExpanded}
                title="Search"
                onDismissed={() => this.filterExpanded(false)}>
                {incidentsFilter}
            </FullPageDialog>
        </PageLayout>
    }
}

export default withStyles(styles, { withTheme: true })(PlayTraxIncidents);