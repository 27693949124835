import { makeActionCreator } from './helpers';

export const
    EXTERNAL_API_CONNECTION_REQUEST = "EXTERNAL_API_CONNECTION_REQUEST",
    EXTERNAL_API_CONNECTION_REQUEST_SUCCESS = "EXTERNAL_API_CONNECTION_REQUEST_SUCCESS",
    EXTERNAL_API_CONNECTION_REQUEST_FAILURE = "EXTERNAL_API_CONNECTION_REQUEST_FAILURE",
    EXTERNAL_API_CONNECTION_REFRESH_REQUEST = "EXTERNAL_API_CONNECTION_REFRESH_REQUEST",
    EXTERNAL_API_CONNECTION_REFRESH_REQUEST_SUCCESS = "EXTERNAL_API_CONNECTION_REFRESH_REQUEST_SUCCESS",
    EXTERNAL_API_CONNECTION_REFRESH_REQUEST_FAILURE = "EXTERNAL_API_CONNECTION_REFRESH_REQUEST_FAILURE",
    EXTERNAL_API_CONNECTION_REMOVE_REQUEST = "EXTERNAL_API_CONNECTION_REMOVE_REQUEST",
    EXTERNAL_API_CONNECTION_REMOVE_REQUEST_SUCCESS = "EXTERNAL_API_CONNECTION_REMOVE_REQUEST_SUCCESS",
    EXTERNAL_API_CONNECTION_REMOVE_REQUEST_FAILURE = "EXTERNAL_API_CONNECTION_REMOVE_REQUEST_FAILURE",
    EXTERNAL_API_CONNECTION_UPDATE_REQUEST = "EXTERNAL_API_CONNECTION_UPDATE_REQUEST",
    EXTERNAL_API_CONNECTION_UPDATE_REQUEST_SUCCESS = "EXTERNAL_API_CONNECTION_UPDATE_REQUEST_SUCCESS",
    EXTERNAL_API_CONNECTION_UPDATE_REQUEST_FAILURE = "EXTERNAL_API_CONNECTION_UPDATE_REQUEST_FAILURE",
    EXTERNAL_API_CONNECTION_SET = "EXTERNAL_API_CONNECTION_SET",

    ROUBLER_EMPLOYEE_MAPPING_REQUEST = "ROUBLER_EMPLOYEE_MAPPING_REQUEST",
    ROUBLER_EMPLOYEE_MAPPING_REQUEST_SUCCESS = "ROUBLER_EMPLOYEE_MAPPING_REQUEST_SUCCESS",
    ROUBLER_EMPLOYEE_MAPPING_REQUEST_FAILURE = "ROUBLER_EMPLOYEE_MAPPING_REQUEST_FAILURE",
    ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST = "ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST",
    ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST_SUCCESS = "ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST_SUCCESS",
    ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST_FAILURE = "ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST_FAILURE",
    ROUBLER_EMPLOYEE_EXPORT_REQUEST = "ROUBLER_EMPLOYEE_EXPORT_REQUEST",
    ROUBLER_EMPLOYEE_EXPORT_REQUEST_SUCCESS = "ROUBLER_EMPLOYEE_EXPORT_REQUEST_SUCCESS",
    ROUBLER_EMPLOYEE_EXPORT_REQUEST_FAILURE = "ROUBLER_EMPLOYEE_EXPORT_REQUEST_FAILURE",
    
    ROLLER_CONFIGURATION_REQUEST = "ROLLER_CONFIGURATION_REQUEST",
    ROLLER_CONFIGURATION_REQUEST_SUCCESS = "ROLLER_CONFIGURATION_REQUEST_SUCCESS",
    ROLLER_CONFIGURATION_REQUEST_FAILURE = "ROLLER_CONFIGURATION_REQUEST_FAILURE",
    ROLLER_CONFIGURATION_SET = "ROLLER_CONFIGURATION_SET",
    ROLLER_CONFIGURATION_UPDATE_REQUEST = "ROLLER_CONFIGURATION_UPDATE_REQUEST",
    ROLLER_CONFIGURATION_UPDATE_REQUEST_SUCCESS = "ROLLER_CONFIGURATION_UPDATE_REQUEST_SUCCESS",
    ROLLER_CONFIGURATION_UPDATE_REQUEST_FAILURE = "ROLLER_CONFIGURATION_UPDATE_REQUEST_FAILURE";

export const externalApiConnectionRequest = makeActionCreator(EXTERNAL_API_CONNECTION_REQUEST, 'entityType', 'forVenueId', 'forSecurityFirmId');
export const externalApiConnectionRequestSuccess = makeActionCreator(EXTERNAL_API_CONNECTION_REQUEST_SUCCESS, 'externalApiConnection');
export const externalApiConnectionRequestFailure = makeActionCreator(EXTERNAL_API_CONNECTION_REQUEST_FAILURE, 'error');
export const externalApiConnectionRefreshRequest = makeActionCreator(EXTERNAL_API_CONNECTION_REFRESH_REQUEST, 'externalApiConnectionId');
export const externalApiConnectionRefreshRequestSuccess = makeActionCreator(EXTERNAL_API_CONNECTION_REFRESH_REQUEST_SUCCESS, 'externalApiConnection');
export const externalApiConnectionRefreshRequestFailure = makeActionCreator(EXTERNAL_API_CONNECTION_REFRESH_REQUEST_FAILURE, 'error');
export const externalApiConnectionRemoveRequest = makeActionCreator(EXTERNAL_API_CONNECTION_REMOVE_REQUEST, 'externalApiConnectionId');
export const externalApiConnectionRemoveRequestSuccess = makeActionCreator(EXTERNAL_API_CONNECTION_REMOVE_REQUEST_SUCCESS, 'externalApiConnection');
export const externalApiConnectionRemoveRequestFailure = makeActionCreator(EXTERNAL_API_CONNECTION_REMOVE_REQUEST_FAILURE, 'error');
export const externalApiConnectionUpdateRequest = makeActionCreator(EXTERNAL_API_CONNECTION_UPDATE_REQUEST, 'externalApiConnection');
export const externalApiConnectionUpdateRequestSuccess = makeActionCreator(EXTERNAL_API_CONNECTION_UPDATE_REQUEST_SUCCESS, 'externalApiConnection');
export const externalApiConnectionUpdateRequestFailure = makeActionCreator(EXTERNAL_API_CONNECTION_UPDATE_REQUEST_FAILURE, 'error');
export const externalApiConnectionSet = makeActionCreator(EXTERNAL_API_CONNECTION_SET, 'externalApiConnection');

export const roublerEmployeeMappingRequest = makeActionCreator(ROUBLER_EMPLOYEE_MAPPING_REQUEST);
export const roublerEmployeeMappingRequestSuccess = makeActionCreator(ROUBLER_EMPLOYEE_MAPPING_REQUEST_SUCCESS, 'roublerEmployeeMapping');
export const roublerEmployeeMappingRequestFailure = makeActionCreator(ROUBLER_EMPLOYEE_MAPPING_REQUEST_FAILURE, 'error');
export const roublerEmployeeMappingUpdateRequest = makeActionCreator(ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST, 'entityMapping');
export const roublerEmployeeMappingUpdateRequestSuccess = makeActionCreator(ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST_SUCCESS, 'roublerEmployeeMapping');
export const roublerEmployeeMappingUpdateRequestFailure = makeActionCreator(ROUBLER_EMPLOYEE_MAPPING_UPDATE_REQUEST_FAILURE, 'error');
export const roublerEmployeeExportRequest = makeActionCreator(ROUBLER_EMPLOYEE_EXPORT_REQUEST, 'userId');
export const roublerEmployeeExportRequestSuccess = makeActionCreator(ROUBLER_EMPLOYEE_EXPORT_REQUEST_SUCCESS);
export const roublerEmployeeExportRequestFailure = makeActionCreator(ROUBLER_EMPLOYEE_EXPORT_REQUEST_FAILURE, 'error');

export const rollerConfigurationRequest = makeActionCreator(ROLLER_CONFIGURATION_REQUEST);
export const rollerConfigurationRequestSuccess = makeActionCreator(ROLLER_CONFIGURATION_REQUEST_SUCCESS, 'rollerConfiguration');
export const rollerConfigurationRequestFailure = makeActionCreator(ROLLER_CONFIGURATION_REQUEST_FAILURE, 'error');
export const rollerConfigurationSet = makeActionCreator(ROLLER_CONFIGURATION_SET, 'rollerConfiguration');
export const rollerConfigurationUpdateRequest = makeActionCreator(ROLLER_CONFIGURATION_UPDATE_REQUEST, 'rollerConfiguration');
export const rollerConfigurationUpdateRequestSuccess = makeActionCreator(ROLLER_CONFIGURATION_UPDATE_REQUEST_SUCCESS, 'rollerConfiguration');
export const rollerConfigurationUpdateRequestFailure = makeActionCreator(ROLLER_CONFIGURATION_UPDATE_REQUEST_FAILURE, 'error');
